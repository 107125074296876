import Vue from 'vue'
import {Category} from "../../utils/models/category";

export default {
  state: {
    categories: null,
    categoriesMain: null,
    categoryById: null,
    products: null,
    productById: null,
    customCategories: null
  },

  mutations: {
    SET_CATEGORIES_MAIN(state, categoryData) {
      const categories = categoryData.map(category => Category.from(category))
      state.categoriesMain = Category.setCategories(categories)
    },

    SET_CATEGORIES(state, categoryData) {
      state.categories = categoryData
    },

    SET_CUSTOM_CATEGORIES(state, payload){
      state.customCategories = payload
    },

    SET_PRODUCTS(state, payload) {
      state.products = payload
    },

    SET_CATEGORY_BY_ID(state, payload) {
      state.categoryById = payload
    },

    SET_PRODUCT_BY_ID(state, payload) {
      state.productById = payload
    }
  },

  actions: {
    fetchCategories({ commit}) {
      Vue.http.get(
        `/api/categories`
      )
      .then(response => {
        //new version of category list
        commit('SET_CATEGORIES_MAIN', response.data)

        let result = response.data.filter( item => {
          return !item.parentId
        })

        commit('SET_CATEGORIES', result)
      })
      .catch(error => {
        if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        console.log( error );
      });
    },

    fetchCategoryById({ commit}, id) {
      Vue.http.get(
        `/api/category/${id}`
      )
      .then(response => {
        //console.log(response)
        commit('SET_CATEGORY_BY_ID', response.data)
      })
      .catch(error => {
        if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        console.log( error );
      });
    },

    fetchProductsByCategory({ commit }, id) {
      Vue.http.get(
        `/api/goods`
      )
      .then(response => {
        //console.log(response)
        let result = response.data.filter((item) => {
          return item.categoryId == id
        })
        commit('SET_PRODUCTS', result)
      })
      .catch(error => {
        if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        console.log( error );
      });
    },


    fetchProductById({ commit }, id) {
      Vue.http.get(
        `/api/goods/${id}`
      )
      .then(response => {
        //console.log(response)
        commit('SET_PRODUCT_BY_ID', response.data)
      })
      .catch(error => {
        if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        console.log( error );
      });
    },

    fetchCustomProductById({ commit }, id) {
      Vue.http.get(
          `/api/customGoods/${id}`
        )
        .then(response => {
          //console.log(response)
          commit('SET_PRODUCT_BY_ID', response.data)
        })
        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
          console.log(error);
        });
    },

    fetchCustomCategories({commit}) {
      Vue.http.get(
        `/api/customCategories`
      )
      .then(response => {
        let result = response.data.filter( item => {
          return !item.parentId
        })
        commit('SET_CUSTOM_CATEGORIES', result)
      })
      .catch(error => {
        if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        console.log( error );
      });
    },

    fetchCustomCategoryById({ commit }, id) {
      Vue.http.get(
          `/api/customCategory/${id}`
        )
        .then(response => {
          commit('SET_CATEGORY_BY_ID', response.data)
        })
        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
          console.log(error);
        });
    }
  },

  getters: {
    getSlicedCategories(state) {
      if(state.categories != undefined) {
        return state.categories.data.slice(0, 10)
      }
    },
  }
}
