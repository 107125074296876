import Vue from 'vue'

export default {
  state: {
    tasks: [],
    task: [],
    userRoles: [],
    organizations: [],
  },

  mutations: { 
    SET_TASKS(state, payload) {
      state.tasks = payload
    },

    SET_TASK(state, payload) {
      state.task = payload
    },

    SET_ROLES(state, payload) {
      state.userRoles = payload
    },

    SET_ORGS(state, payload) {
      state.organizations = payload
    },
  },

  actions: {
    fetchTasks({ commit }) {
      Vue.http.get(
        `/api/tasks`
      )

      .then(response => { 
        commit('SET_TASKS', response.data)
      })

      .catch(error => {
        if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        console.log( error );
      });
    },

    fetchTasksAdmin({ commit }, { brand='', orderColumn='', order='desc'  } = {}) {
      const params = [
        brand ? `name=${brand}` : '',
        orderColumn ? `orderColumn=${orderColumn}`: '',
        `order=${order}`
      ].filter(Boolean).join('&');

      Vue.http_admin.get(
        `/api/tasks?${params}`
      )

      .then(response => {
        commit('SET_TASKS', response.data)
      })

      .catch(error => {
        if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        console.log(error);
      });
    },

    fetchTaskById({commit}, id) {
      Vue.http.get(
        `/api/task/${id}`
      )

      .then(response => { 
        commit('SET_TASK', response.data)
      })

      .catch(error => {
        if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        console.log( error );
      });
    },


    fetchTaskByIdAdmin({ commit }, id) {
      Vue.http_admin.get(
          `/api/task/${id}`
        )

        .then(response => {
          commit('SET_TASK', response.data)
        })

        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
          console.log(error);
        });
    },

    fetchUserRolesAdmin({ commit }) {
      Vue.http_admin.get(
          `/api/userRoles`
        )

        .then(response => {
          commit('SET_ROLES', response.data)
        })

        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
          console.log(error);
        });
    },

    fetchOrganizationsAdmin({ commit }) {
      Vue.http_admin.get(
          `/api/organizations`
        )

        .then(response => {
          commit('SET_ORGS', response.data)
        })

        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
          console.log(error);
        });
    },

    fetchUserRoles({ commit }) {
      Vue.http.get(
        `/api/userRoles`
      )

      .then(response => {
        commit('SET_ROLES', response.data)
      })

      .catch(error => {
        if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        console.log(error);
      });
    },

    fetchOrganizations({ commit }) {
      Vue.http.get(
        `/api/organizations`
      )

      .then(response => {
        commit('SET_ORGS', response.data)
      })

      .catch(error => {
        if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        console.log(error);
      });
    },
  }
}