import Vue from 'vue'

export default {
  state: {
    users: [],
    user: null,
    stats: {},
    usersDataToDownload: null,

    offset: 0,
    limit: 10,
    finished: false,
  },

  mutations: {
    SET_SEARCH_USERS(state, { usersData, reset }) {
      if (reset) {
        state.users = usersData
      } else {
        state.users = state.users.concat(usersData);
      }
    },

    SET_USER_BY_ID(state, payload) {
      state.user = payload
    },

    SET_STATS(state, payload) {
      state.stats = payload
    },

    CLEAR_USER(state) {
      state.user = null
    },

    SET_USERS_DATA_TO_DOWNLOAD(state, payload) {
      state.usersDataToDownload = payload
      setTimeout(() => {
        state.usersDataToDownload = null
      }, 200);
    },

    SET_OFFSET(state, offset) {
      state.offset = offset;
    },
    SET_FINISHED(state, finished) {
      state.finished = finished;
    },
  },

  actions: {
    searchUsers({ commit, state }, { reset = false, name = '', phone = '', status = '' } = {}) {
      const { offset: offst, limit } = state
      const offset = reset ? 0 : offst;
      status = status || parseInt(status) === 0 ? status : '';
      const phoneParam = isNaN(phone) ? '' : phone
      const params = [
        `offset=${offset}`,
        `limit=${limit}`,
        `full_name='${name ?? ''}'`,
        `phone='${phoneParam}'`,
        status ? `verified=${status}` : 'varified=',
      ].filter(Boolean).join('&');

      return Vue.http_admin.get(`api/users?${params}`)
        .then(response => {
          const usersData = response.data;
          if (reset) {
            commit('SET_FINISHED', false);
            commit('SET_OFFSET', 0);
          }

          if (usersData.length < state.limit) {
            commit('SET_FINISHED', true);
          }

          commit('SET_SEARCH_USERS', { usersData, reset })
          commit('SET_OFFSET', offset + 1);
          return usersData;
        })

        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        });
    },

    getUserById({ commit }, id) {
      Vue.http_admin.get(
        `api/user/${id}`
      )

        .then(response => {
          commit('SET_USER_BY_ID', response.data)
        })

        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        });
    },

    clearUser({ commit }) {
      commit('CLEAR_USER')
    },

    getStats({ commit }) {
      Vue.http_admin.get(
        `api/users/statistics`
      )

        .then(response => {
          commit('SET_STATS', response.data)
        })

        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        });
    },

    changeBlockStatus(state, { id, status, comment }) {
      const bodyParameters = {
        blocked: status,
        comment: comment
      }

      return Vue.http_admin.put(
        `/api/user/${id}`,
        bodyParameters
      )
        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        });
    },

    getUserData({ commit }) {
      Vue.http_admin.get(
        `api/users/download-data`, {
        responseType: 'blob'
      }
      )
        .then(response => {
          commit('SET_USERS_DATA_TO_DOWNLOAD', response.data)
        })
        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        });
    },
  }
}