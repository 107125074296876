<template>
  <div @click="addToBasket" :class="{ active: iconIsActive }" class="card-links__item basket-item">
    <span class="card-basket"></span>
    <div v-if="isNotFulFilled" class="error">
      <span class="error-text">Вы не заполнили профиль!</span>
      <router-link to="/profile"><span class="error-text">В профиль</span></router-link>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    props: {
      productId: {
        required: true,
      },
      custom: {
        required: true
      }
    },

    data() {
      return {
        iconIsActive: false,
        isNotFulFilled: false,
      }
    },

    computed: {
      ...mapState({
        userData: state => state.profile.user
      })
    },

    methods: {
      addToBasket() {
        if (this.userData.isCompleted) {
          this.iconIsActive = !this.iconIsActive

          let params = {
            order: {
              [this.productId]: 1
            },
            custom: this.custom
          }
          if(this.iconIsActive !== true) {
            params.order = {[this.productId]: null}
          }
          this.$store.dispatch('upsertBasket', params)
        } else {
          this.isNotFulFilled = true
          setTimeout(() => {
            this.isNotFulFilled = false
          }, 2000)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .error {
    position: absolute;
    z-index: 1;
    color: #fc2323;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    margin-top: 85px;
    border: 1px solid #232525;
    background-color: #fffefa;
    padding: 3px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 1px rgb(0 0 0 / 20%);
  }

  .error-text {
    text-wrap: nowrap;
    align-self: center;
     font: 400 14px/16px $roboto;

  }

  .card-basket {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url('../assets/images/icons/icon-basket.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .basket-item {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .active .card-basket {
    background-image: url('../assets/images/icons/icon-basket-delete.svg');
  }

  .card-links {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: auto;
    
    &__item {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #232525;
    }
  }

  .product-detailed-link {
    div {
      width: 6px;
      height: 6px;
      background: #FF9C43;
      border-radius: 50%;
      margin: 0 1px;
    }
  }
</style>
