import Vue from 'vue'

export default {
  state: {
    orders: null,
    order: null,
    act: null,
    status: null,
    accounting: null,
    registry: null,

    offset: 0,
    limit: 50,
    finished: false,
  },

  mutations: {
    SET_ORDERS(state, { orders, reset }) {
      if (reset) {
        state.orders = orders
      } else {
        state.orders = state.orders.concat(orders);
      }
    },

    SET_ORDER(state, payload) {
      state.order = payload
    },

    SET_ORDER_ACT(state, payload) {
      state.act = payload
    },

    SET_ACCOUNTING_ACT(state, payload) {
      state.accounting = payload
      setTimeout(() => {
        state.accounting = null
      }, 200);
    },

    SET_REGISTRY_ACT(state, payload) {
      state.registry = payload
      setTimeout(() => {
        state.registry = null
      }, 200);
    },

    SET_STATUS(state, payload) {
      state.status = payload
    },

    SET_OFFSET(state, offset) {
      state.offset = offset;
    },
    SET_FINISHED(state, finished) {
      state.finished = finished;
    },
  },

  actions: {
    getOrders({ commit, state }, { reset = false, startDate = null, completeDate = null, recipient = '', status = null, shipper = '' } = {}) {
      const { offset: offst, limit } = state
      const offset = reset ? 0 : offst;

      const params = [
        `offset=${offset}`,
        `limit=${limit}`,
        startDate ? `startDate=${startDate}` : '',
        completeDate ? `completeDate=${completeDate}` : '',
        recipient.length ? `recipient=${recipient}` : '',
        shipper.length ? `shipper=${shipper}` : '',
        status ? `status=${status}` : ''
      ].filter(Boolean).join('&');

      return Vue.http_admin.get(`api/orders?${params}`)
        .then(response => {
          const orders = response.data
          if (reset) {
            commit('SET_FINISHED', false);
            commit('SET_OFFSET', 0);
          }

          if (orders.length < state.limit) {
            commit('SET_FINISHED', true);
          }

          commit('SET_ORDERS', { orders, reset })
          commit('SET_OFFSET', offset + 1);
          return orders;
        })


        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        });
    },

    getOrder({ commit }, id) {
      Vue.http_admin.get(
        `api/order/${id}`
      )

        .then(response => {
          commit('SET_ORDER', response.data)
        })

        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        });
    },

    getOrderAct({ commit }, id) {
      Vue.http_admin.get(
        `api/order/${id}/act`, {
        responseType: 'blob'
      }
      )

        .then(response => {
          commit('SET_ORDER_ACT', response.data)
        })

        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        });
    },

    getAccountingAct({ commit }, { from, to }) {
      Vue.http_admin.get(
        `api/orders/accounting?startDate=${from}&endDate=${to}`, {
        responseType: 'blob'
      }
      ).then(response => {
        commit('SET_ACCOUNTING_ACT', response.data)
      }).catch(error => {
        if (error?.response && error?.response?.status === 500) {
          this.$toast("Ой, что-то пошло не так", {
            timeout: 5000,
            type: "error",
          })
        }
      });
    },

    getRegistry({ commit }, { from, to }) {
      Vue.http_admin.get(
        `api/orders/registry?startDate=${from}&endDate=${to}`, {
        responseType: 'blob'
      }
      )

        .then(response => {
          commit('SET_REGISTRY_ACT', response.data)
        })

        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        });
    },

    changeStatus({ commit }, { orderId, status, price, onSuccess, onError }) {
      const bodyParameters = { "status": status, "price": price }
      Vue.http_admin.put(
        `api/order/${orderId}`,
        bodyParameters
      )

        .then(() => {
          onSuccess && onSuccess()
          commit('SET_STATUS', bodyParameters)
        })

        .catch(error => {
          onError && onError()
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        });
    },
  },

  getters: {
    getOrders: (state) => {
      return state.orders
    }
  }
}
