<template>
    <div class="users-info">
        <div class="users-info-block">
            <div class="users-info-block__title">Количество записей в системе:</div>
            <div class="users-info-block__value">{{ stats.allUserCount }}</div>
        </div>
        <div class="users-info-block">
            <div class="users-info-block__title">Авторизованых пользователей:</div>
            <div class="users-info-block__value">{{ stats.authenticatedUsersCount }}</div>
        </div>
        <div class="users-info-block">
            <div class="users-info-block__title">Заполненых профилей:</div>
            <div class="users-info-block__value">{{ stats.completedProfilesCount }}</div>
        </div>
    </div>
</template>


<script>
import { mapState } from 'vuex';

export default {
    created() {
        this.$store.dispatch('getStats')
    },
    computed: {
        ...mapState({
            stats: state => state.users.stats,
        })
    },
}
</script>

<style lang="scss" scoped>
.users-info {
    width: 63.3%;
    padding-top: 80px;
}

.users-info-block {
    display: flex;
    width: 350px;
    margin-bottom: 30px;

    &__title {
        font: 400 16px/1 $roboto;
        color: $dark-font;
    }

    &__value {
        font: 400 16px/1 $roboto;
        color: $dark-font;
        margin-left: auto;
    }
}
</style>