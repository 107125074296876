<template>
  <main class="history">
    <div class="history-main">
      <article class="history-container">
        <form class="search">
          <div class="input-wrap">
            <div class="search-container">
              <label>
                <input
                  class="search__input by-descr"
                  type="text"
                  placeholder=" "
                  v-model="description"
                  @keyup="searchByDescription"
                  @keyup.enter="searchByDescription"
                />
                <span>поиск по операциям</span>
              </label>
              <button
                class="search__btn"
                v-if="description"
                @click="clearSearch"
              ></button>
            </div>
          </div>

          <div class="input-wrap date-filter">
            <div class="datepicker">
              <div class="datepicker__info">c:</div>
              <div class="datepicker__calendar">
                <datepicker
                  :language="ru"
                  :format="customFormatter"
                  input-class="dateInput"
                  wrapper-class="dateInputWrap"
                  v-model="startDate"
                  @selected="dateStartChange"
                />
              </div>
            </div>

            <div class="datepicker">
              <div class="datepicker__info">по:</div>
              <div class="datepicker__calendar">
                <datepicker
                  :language="ru"
                  :format="customFormatter"
                  input-class="dateInput"
                  wrapper-class="dateInputWrap"
                  v-model="endDate"
                  @selected="dateEndChange"
                />
              </div>
            </div>
          </div>
        </form>

        <h1 class="history-heading" v-if="balance">
          Баланс: {{ balance.data.balance }} баллов
        </h1>
        <div
          style="width: 100%"
          v-if="expiringBonuses && expiringBonuses.length > 0"
        >
          <h2 class="history-heading-md">Скоро сгорят</h2>
          <div
            class="history-list"
            v-for="(item, idx) in expiringBonuses"
            :key="idx"
          >
            <div class="history-list__item">
              <p class="history-list__date">{{ formatDate(item.burnDate) }}</p>
            </div>
            <div class="history-list__amount">{{ item.sum }} МБ</div>
          </div>
        </div>

        <div style="width: 100%" v-if="bonusHistory && bonusHistory.length > 0">
          <h2 class="history-heading-history">История</h2>
          <div class="history-list-wrap">
            <div
              class="history-list"
              v-for="(item, idx) in bonusHistory"
              :key="idx"
            >
              <div class="history-list__item">
                <div class="history-list__type">
                  <span
                    v-if="
                      (item.order === null && item.description.length) ||
                      (item.description.length > 0 && item.order !== null)
                    "
                  >
                    {{ item.description }}
                  </span>
                  <span
                    v-else-if="
                      item.order === null &&
                      item.description.length === 0 &&
                      item.ruleName === null
                    "
                  >
                    {{ item.registratorName }}
                  </span>
                  <span v-else>
                    {{ item.ruleName }}
                  </span>

                  <span v-if="item.order" class="history-list__badge">{{
                    item.order.status
                  }}</span>
                </div>
                <p class="history-list__desc">
                  {{ formatDate(item.registratorDate.split("T")[0]) }}
                </p>
                <p
                  class="history-list__desc"
                  v-if="
                    item &&
                    item.order &&
                    item.order.purchases &&
                    item.order.purchases.goods &&
                    item.order.purchases.goods[0]
                  "
                >
                  {{ item.order.purchases.goods[0].name }}
                </p>
                <p
                  class="history-list__desc"
                  v-if="item.order === null && Number(item.totalSum) < 0"
                >
                  {{ item.registratorName }}
                </p>
              </div>
              <div
                class="history-list__amount"
                :class="{
                  green: !item.order && item.totalSum >= 0,
                  minus: item.order && item.totalSum >= 0,
                }"
              >
                {{ item.totalSum }} МБ
              </div>
            </div>
            <infinite-loading @infinite="loadMoreData">
              <div slot="no-more" class="history-list__no-data"></div>
              <div slot="no-results" class="history-list__no-data"></div>
            </infinite-loading>
          </div>
        </div>
      </article>
    </div>
  </main>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { ru } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { voidDateTime, debounce } from "../utils";

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      ru: ru,
      description: "",
      startDate: null,
      endDate: null,
    };
  },

  methods: {
    customFormatter(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    formatDate(date) {
      const parts = date.split("-");
      const day = parts[2];
      const month = parts[1];
      const year = parts[0];
      const months = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];
      return `${day} ${months[month - 1]} ${year}`;
    },

    ...mapActions(["fetchBonusHistory", "fetchExpiringBonuses"]),
    loadMoreData($state) {
      if (this.finished) {
        $state.complete();
        return;
      }

      this.fetchBonusHistory({
        startDate:
          this.startDate === null ? undefined : voidDateTime(this.startDate),
        endDate: this.endDate === null ? undefined : voidDateTime(this.endDate),
      })
        .then(() => {
          $state.loaded();
        })
        .catch(() => {
          $state.complete();
        });
    },

    dateStartChange(date) {
      this.fetchBonusHistory({
        reset: true,
        startDate: voidDateTime(date),
        endDate: this.endDate === null ? undefined : voidDateTime(this.endDate),
      });
    },

    dateEndChange(date) {
      this.endDate = date;
      this.fetchBonusHistory({
        reset: true,
        startDate:
          this.startDate === null ? undefined : voidDateTime(this.startDate),
        endDate: voidDateTime(date),
      });
    },

    searchByDescription() {
      this.debouncedFetchBonusHistory();
    },

    clearSearch() {
      (this.description = ""),
        this.$store.dispatch("fetchBonusHistory", {
          reset: true,
          description: "",
        });
    },
  },

  computed: {
    ...mapState({
      balance: (state) => state.auth.balance,
      bonusHistory: (state) => state.history.bonusHistory,
      expiringBonuses: (state) => state.history.expiringBonuses,
      finished: (state) => state.history.finished,
    }),
  },

  created() {
    this.fetchExpiringBonuses();
    this.fetchBonusHistory({
      reset: true,
      startDate:
        this.startDate === null ? undefined : voidDateTime(this.startDate),
      endDate: this.endDate === null ? undefined : voidDateTime(this.endDate),
    });
    this.debouncedFetchBonusHistory = debounce(() => {
      this.$store.dispatch("fetchBonusHistory", {
        reset: true,
        description: this.description,
      });
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin-top: 50px;
  display: inline-flex;
  align-items: baseline;
  justify-content: center;
  gap: 50px;

  @include display-less(phablet) {
    flex-direction: column;
    gap: 10px;
  }

  &-container {
    display: inline-flex;
    gap: 10px;
  }

  &__btn {
    background-color: inherit;
    border: none;
    cursor: pointer;
    background-image: url("../assets/images/icons/close-search.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &__input {
    width: 100%;
    height: 30px;
    border: 1px solid #545454;
    border-radius: 4px;
    color: #9e9e9e;
    font: 400 16px/30px $roboto;
    padding: 0 5px;
    background-color: inherit;
    color: $white;

    &:placeholder {
      color: #9e9e9e;
    }
  }

  label {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  span {
    padding: 8px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.5;
    color: $white;
    font: 400 13px $roboto;
  }

  input:focus + span,
  input:not(:placeholder-shown) + span {
    display: none;
  }
}

.input-wrap {
  margin-bottom: 20px;
}

.history-main {
  background-color: #1a1c1c;
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
  min-height: calc(100vh - 184px);

  @include display-less(tablet) {
    padding-bottom: 120px;
  }
}

.history-list {
  display: flex;
  flex-direction: row;
  color: #fffefa;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @include display-less(tablet) {
    align-items: flex-start;
  }

  &__no-data {
    font: 400 10px/26px $roboto;
    color: #fffefa;
    opacity: 0.5;

    @include display-less(tablet) {
      font: 400 16px/26px $roboto;
    }
  }

  &-wrap {
    height: 500px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }

  &__badge {
    font: 400 15px/26px $roboto;
    padding: 0px 8px;
    background-color: #64c464;
    border-radius: 4px;
    margin-left: 5px;
    display: inline-block;
    white-space: nowrap;

    @include display-less(smart) {
      font: 400 12px/26px $roboto;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 500px;

    @include display-less(smart) {
      width: 200px;
    }
  }

  &__type {
    font: 400 20px/26px $roboto;
    width: 500px;

    @include display-less(smart) {
      font: 400 16px/26px $roboto;
      width: 270px;
    }
  }

  &__date {
    font: 400 20px/26px $roboto;

    @include display-less(smart) {
      font: 400 16px/26px $roboto;
    }
  }

  &__desc {
    font: 200 15px/26px $roboto;

    @include display-less(smart) {
      font: 200 13px/26px $roboto;
      width: 90vw;
    }
  }

  &__amount {
    font: 400 20px/26px $roboto;
    text-align: end;
    text-wrap: nowrap;

    @include display-less(smart) {
      font: 400 15px/26px $roboto;
      width: 300px;
    }
  }
}

.history-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
  width: 100%;
  max-width: 700px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @include display-less(phablet) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include display-less(smart) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.history-heading {
  font: 700 32px/26px $roboto;
  color: #fffefa;
  padding-top: 38px;
  margin-bottom: 40px;
  text-align: center;

  @include display-less(phablet) {
    font: 700 28px/26px $roboto;
    text-align: left;
    margin-bottom: 0px;
  }

  &-history {
    margin-top: 40px;
    margin-bottom: 30px;
    font: 700 30px/26px $roboto;
    color: #fffefa;
  }

  &-md {
    font: 500 24px/26px $roboto;
    line-height: 32px;
    color: #fffefa;
    padding-top: 38px;
    margin-bottom: 20px;
    text-align: left;
  }
}

.green {
  color: #64c464;

  &::before {
    content: "+";
  }
}

.minus {
  color: #fffefa;

  &::before {
    content: "- ";
  }
}

.datepicker {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &__info {
    font: 400 16px $roboto;
    color: $white;
    margin-right: 10px;
  }

  &__calendar {
    border-bottom: 1px solid $dark-font;
    width: 150px;
  }
}

::v-deep {
  .vdp-datepicker {
    .dateInput {
      width: 100%;
      background-color: inherit;
      color: #ffffff !important;
      font-size: 16px !important;
    }
  }

  .datepicker {
    display: flex;
    align-items: center;

    &__info {
      font: 400 16px $roboto;
      color: $white;
      margin-right: 10px;
    }

    &__calendar {
      border: none;
      width: 150px;
    }
  }
}

.date-filter {
  display: flex;
  gap: 30px;

  @include display-less(phablet) {
    flex-direction: column;
    gap: 5px;
  }
}
</style>
