import Vue from 'vue';

export default {
  state: {
    bonusHistory: [],
    expiringBonuses: [],
    offset: 0,
    limit: 10,
    finished: false,
  },

  mutations: {
    SET_HISTORY(state, { history, reset }) {
      if (reset) {
        state.bonusHistory = history;
      } else {
        state.bonusHistory = state.bonusHistory.concat(history);
      }
    },
    SET_BONUSES(state, { bonuses }) {
        state.expiringBonuses = state.expiringBonuses.concat(bonuses);
    },
    SET_OFFSET(state, offset) {
      state.offset = offset;
    },
    SET_FINISHED(state, finished) {
      state.finished = finished;
    },
  },

  actions: {
    fetchBonusHistory({ commit, state }, { reset = false, description = '', startDate=null, endDate=null } = {}) {
      const offset = reset ? 0 : state.offset;

      if (reset) {
        commit('SET_FINISHED', false); 
      }

      const params = [
        `offset=${offset}`,
        startDate ? `startDate=${startDate}` : '',
        endDate ? `endDate=${endDate}` : '',
        description.length ? `description=${description}` : ''
      ].filter(Boolean).join('&');

      return Vue.http
        .get(`/api/history?${params}`)
        .then(response => {
          const bonusHistory = response.data?.bonusHistory;
          commit('SET_HISTORY', { history: bonusHistory, reset });
          commit('SET_OFFSET', offset + 1);

          if (bonusHistory.length < state.limit) {
            commit('SET_FINISHED', true);
          }
        })
        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
          console.error('Ошибка при загрузке истории:', error);
        });
    },

    fetchExpiringBonuses({ commit }) {
      return Vue.http
        .get(`/api/expiring-bonuses`)
        .then(response => {
          const bonuses = response.data?.expiringBonuses;
          commit('SET_BONUSES', { bonuses });
        })
        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
          console.error('Ошибка при загрузке fetchExpiringBonuses:', error);
        });
    }
  }
};
