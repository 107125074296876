<template>
    <div>
        <UserPopup :comment="form.comment" :popupState="popupState" :selected="selected"
            @toggle-popup="togglePopupFn" />

        <div class="row user-btn-edit" v-if="selected != undefined && users.length > 0">
            <div class="col">
                <button v-if="!form.isBlocked" @click="blockBtn">Блокировать</button>
                <button v-if="form.isBlocked" @click="unBlockUser">Разблокировать</button>
            </div>
            <div class="col">
                <button @click="enterAsUser">Войти как пользователь</button>
            </div>
        </div>

        <div class="user-edit" v-if="selected != undefined && users.length > 0">
            <div class="row">
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.userName">
                            <span>Фио</span>
                        </label>
                    </div>
                </div>
            </div>
            <div v-if="userData != null && userData.photo !== null" class="row">
                <a class="col snils-photo" v-on:click="openPhoto(userData.photo)">
                    <label for="imgs">Фото снилса</label>
                    <img id="imgs" v-bind:src="form.imageContent" />
                </a>
                <div class="col user-btn-edit" style="margin-top: 10px">
                    <a v-bind:href="form.imageContent" v-bind:download="'snils.' + form.extension">Скачать СНИЛС</a>
                </div>
            </div>

            <div v-if="userData != null && userData.snils != null" class="row">
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-mask="masks.snilsMask"
                                v-model="userData.snils">
                            <span>СНИЛС</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col" v-if="userData != null && userData.birth_date != null">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-mask="masks.birthDateMask"
                                v-model="userData.birth_date">
                            <span>Дата рождения</span>
                        </label>
                    </div>
                </div>

                <div class="col" v-if="userData != null && userData.inn != null">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-mask="masks.innDateMask"
                                v-model="userData.inn">
                            <span>ИНН</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="users-select">
                        <v-select class="admin-select" :class="{ active: form.nationality }" :options="countries"
                            label="name" v-model="form.nationality">
                            <div slot="no-options">Выберете из списка</div>
                        </v-select>
                        <label>
                            <span>Страна</span>
                        </label>
                    </div>
                </div>
                <div class="col" v-if="form.nationality && form.nationality.code === 'kz'">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.addressRegister">
                            <span>Адрес регистрации</span>
                        </label>
                    </div>
                </div>
            </div>


            <div v-if="form.nationality && form.nationality.code !== 'kz'" class="row">
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.address_city">
                            <span>Город</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.street">
                            <span>Улица</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.houseNumber">
                            <span>Номер дома</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.apartment">
                            <span>Квартира</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.building">
                            <span>Здание</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.build">
                            <span>Корпус</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.structure">
                            <span>Строение</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.possession">
                            <span>Владение</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.index">
                            <span>Индекс</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.region">
                            <span>Регион</span>
                        </label>
                    </div>
                </div>
            </div>

            <div v-if="userData != null && userData.photoPassport !== null" class="row">
                <a class="col snils-photo" v-on:click="openPhotoPassport(userData.photoPassport)">
                    <label for="imgs">Фото паспорта</label>
                    <img id="imgs" v-bind:src="form.imagePassportContent" />
                </a>
                <div class="col user-btn-edit" style="margin-top: 10px">
                    <a v-bind:href="form.imagePassportContent"
                        v-bind:download="'passport.' + form.passportExtension">Скачать
                        паспорт</a>
                </div>
            </div>

            <div class="row" v-if="form.nationality">
                <div class="col">
                    <div v-if="form.nationality.code != 'kz'" class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" "
                                v-mask="masks.passportSeriesMask" v-model="form.passportSeries">
                            <span>Серия паспорта</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" "
                                v-mask="(form.nationality.code != 'kz') ? masks.passportNumMask : masks.passportKzNumMask"
                                v-model="form.passportNum">
                            <span>{{ form.nationality.code != 'kz' ? 'Номер паспорта' : 'Удостоверение личности'
                                }}</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-mask="masks.passportDateMask"
                                v-model="form.passportDate">
                            <span>Когда выдан</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.passportIssued">
                            <span>Кем выдан</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div v-if="form.nationality" class="col">
                    <div v-if="form.nationality.code != 'kz'" class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-mask="masks.passportCodeMask"
                                v-model="form.passportCode">
                            <span>Код подразделения</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.phoneNumber">
                            <span>Номер телефона</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="users-select">
                        <v-select class="admin-select " label="name" :class="{ active: form.stationsSelected }"
                            :options="cities" @input="changeCity" v-model="form.citySelected">
                            <div slot="no-options">Нет такого города</div>
                        </v-select>
                        <label>
                            <span>Город</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="users-select">
                        <v-select class="admin-select" :class="{ active: form.stationsSelected }" label="address"
                            :options="stationsFiltered" :disabled="stationsFiltered.length == 0"
                            v-model="form.stationsSelected">
                            <div slot="no-options">Нет такой станции</div>
                        </v-select>
                        <label>
                            <span>Станция</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="form.comment">
                            <span>Комментарий</span>
                        </label>
                    </div>
                </div>
            </div>

            <div id="unconfirmLine" class="row d-flex-justify-content-center" style="display: none">
                Данные пользователя отклонены
            </div>
            <div class="row">
                <div class="col">
                    <button class="user-edit-save" @click="updateUser">Сохранить</button>
                </div>
                <div class="col">
                    <button class="user-edit-save" @click="confirmUser">Проверено</button>
                    <button style="margin-left: 5px" class="user-edit-save" @click="unconfirmUser">Отклонить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from 'vuex'
import axios from "axios";
import { masks } from '../utils';
import UserPopup from './UserPopup.vue';

export default {
    components: {
        UserPopup,
    },
    data() {
        return {
            masks,
            form: {
                isBlocked: false,
                userName: '',
                addressRegister: '',
                address_city: '',
                street: '',
                houseNumber: '',
                index: '',
                region: '',
                possession: '',
                building: '',
                build: '',
                structure: '',
                apartment: '',
                passportSeries: '',
                passportNum: '',
                passportDate: '',
                passportIssued: '',
                passportCode: '',
                phoneNumber: '',
                inn: '',
                snils: '',
                stationOptions: [],
                citySelected: null,
                stationsSelected: null,
                nationality: null,
                imageContent: "",
                extension: "",
                imagePassportContent: "",
                passportExtension: "",
                comment: "",
            }
        }
    },
    props: {
        selected: {
            type: Number,
        },
        popupState: {
            type: Object,
            required: true
        },
    },
    created() {
        this.$store.dispatch('fetchCitiesAdmin')
        this.$store.dispatch('fetchStationsAdmin')
        this.$store.dispatch('fetchCountriesAdmin')
        this.$store.dispatch('getStats')
    },
    computed: {
        ...mapState({
            users: state => state.users.users,
            userData: state => state.users.user,
            countries: state => state.profile.countries,
            stations: state => state.profile.stations,
            cities: state => state.profile.cities,
            stationsFiltered() {
                return (this.form.citySelected)
                    ? this.form.citySelected.stations
                    : [];
            },
        })
    },
    watch: {
        userData() {
            this.form.phoneNumber = this.userData.phone
            this.userData.full_name != null ? this.form.userName = this.userData.full_name : this.form.userName = ""
            this.form.nationality = this.userData.country.name
            this.userData.city != null ? this.form.citySelected = this.userData.city : this.form.citySelected = null
            this.userData.station != null ? this.form.stationsSelected = this.userData.station.address : this.form.stationsSelected = null
            this.userData.address != null ? this.form.addressRegister = this.userData.address : this.form.addressRegister = ""
            this.userData.passport_serie != null ? this.form.passportSeries = this.userData.passport_serie : this.form.passportSeries = ""
            this.userData.passport_number != null ? this.form.passportNum = this.userData.passport_number : this.form.passportNum = ""
            this.userData.passport_emit_date != null ? this.form.passportDate = this.revertDate(this.userData.passport_emit_date) : this.form.passportDate = ""
            this.userData.emitter_code != null ? this.form.passportCode = this.userData.emitter_code : this.form.passportCode = ""
            this.userData.emitter != null ? this.form.passportIssued = this.userData.emitter : this.form.passportIssued = ""
            this.form.isBlocked = this.userData.blocked
            this.userData.birth_date = this.revertDate(this.userData.birth_date);
            if (this.userData.photo !== "") {
                this.openPhoto();
            }

            if (this.userData.photoPassport !== "") {
                this.openPhotoPassport();
            }

            this.form.address_city = this.userData.address_city != null ? this.userData.address_city : '';
            this.form.street = this.userData.street != null ? this.userData.street : '';
            this.form.houseNumber = this.userData.houseNumber != null ? this.userData.houseNumber : '';
            this.form.index = this.userData.index != null ? this.userData.index : '';
            this.form.region = this.userData.region != null ? this.userData.region : '';
            this.form.possession = this.userData.possession != null ? this.userData.possession : '';
            this.form.building = this.userData.building != null ? this.userData.building : '';
            this.form.build = this.userData.build != null ? this.userData.build : '';
            this.form.structure = this.userData.structure != null ? this.userData.structure : '';
            this.form.apartment = this.userData.apartment != null ? this.userData.apartment : '';
        },

        stations() {
            if (this.stations) {
                this.form.stationOptions = this.stations
            }
        },

    },
    methods: {
        togglePopupFn() {
            this.$emit('toggle-popup', false)
        },
        confirmUser() {
            let url = `${process.env.VUE_APP_baseURL}/api/user/${this.selected}/confirmation?comment=${this.form.comment}`;
            let token = "Bearer " + localStorage.getItem('jwt-admin');
            axios.get(url, {
                headers: {
                    "Authorization": token
                }
            }).then((response) => {
                if (response.data.status == "OK") {
                    this.$toast("Данные пользователя подтверждены", {
                        timeout: 2000,
                    });
                    this.$store.dispatch('searchUsers', {
                        reset: true,
                    })
                }
            });
        },

        unconfirmUser() {
            let url = `${process.env.VUE_APP_baseURL}/api/user/${this.selected}/unconfirmation?comment=${this.form.comment}`;
            let token = "Bearer " + localStorage.getItem('jwt-admin');
            axios.get(url, {
                headers: {
                    "Authorization": token
                }
            }).then((response) => {
                if (response.data.status == "OK") {
                    this.$toast("Данные пользователя отклонены", {
                        timeout: 2000,
                    });
                    this.$store.dispatch('searchUsers', {
                        reset: true,
                    })

                }
            });
        },
        revertDate(date_line) {
            if (date_line == null) {
                return null
            }

            let sp = date_line.split("-").reverse();
            return sp.join("-");
        },

        updateUser() {
            let profileData = {
                "full_name": this.form.userName,
                "countryId": this.form.nationality && this.form.nationality.id,
                "address": this.form.addressRegister,
                "passport_serie": this.form.passportSeries,
                "passport_number": this.form.passportNum,
                "passport_emit_date": this.revertDate(this.form.passportDate) || undefined,
                "emitter": this.form.passportIssued,
                "emitter_code": this.form.passportCode,
                "phone": this.form.phoneNumber,
                "cityId": this.form.citySelected && this.form.citySelected.id,
                "stationId": this.form.stationsSelected && this.form.stationsSelected.id,
                "inn": this.userData.inn,
                "birth_date": this.revertDate(this.userData.birth_date),
                "address_city": this.form.address_city,
                "street": this.form.street,
                "houseNumber": this.form.houseNumber,
                "index": Number(this.form.index),
                "region": this.form.region,
                "possession": this.form.possession,
                "building": this.form.building,
                "build": this.form.build,
                "structure": this.form.structure,
                "letter": this.form.letter,
                "apartment": this.form.apartment,
            }


            this.$store.dispatch('updateUserAdmin', {
                id: this.selected, profileData: profileData, onSuccess: () => {
                    this.$toast("Изменения сохранены!", {
                        timeout: 2000
                    })
                    this.$store.dispatch('searchUsers', {
                        reset: true,
                    })
                },
                onError: () => {
                    this.$toast.error("Произошла ошибка", {
                        timeout: 2000
                    });
                }
            })
        },
        changeCity() {
            this.form.stationsSelected = null
        },
        openPhoto() {
            let token = "Bearer " + localStorage.getItem('jwt-admin');
            if (token === "") {
                return;
            }
            axios.get(process.env.VUE_APP_baseURL + "/" + this.userData.photo, {
                headers: {
                    "Authorization": token
                }
            }).then((res) => {
                this.form.imageContent = res.data;
                this.form.extension = res.data.split(';')[0].split(':image/')[1];
            });
        },
        openPhotoPassport() {
            let token = "Bearer " + localStorage.getItem('jwt-admin');
            if (token === "") {
                return;
            }
            axios.get(process.env.VUE_APP_baseURL + "/" + this.userData.photoPassport, {
                headers: {
                    "Authorization": token
                }
            }).then((res) => {
                this.form.imagePassportContent = res.data;
                this.form.passportExtension = res.data.split(';')[0].split(':image/')[1];
            });
        },
        enterAsUser() {
            localStorage.removeItem('jwt')
            this.$store.dispatch('loginAdminAsUser', this.selected)
        },
        blockBtn() {
            this.$emit('toggle-popup', true)
        },
        unBlockUser() {
            this.$store.dispatch('changeBlockStatus', { id: this.selected, status: false, comment: this.form.comment })
            this.$store.dispatch('getUserById', this.selected)
            this.$store.dispatch('searchUsers', { reset: true })
        },
    }
}
</script>


<style lang="scss" scoped>
.snils-photo>img {
    max-width: 300px;
    max-height: 300px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.users-wrap {
    background: #fff
}

.user-search {
    width: 300px;

    &__input {
        width: 100%;
        height: 30px;
        border: none;
        border-bottom: 1px solid #9e9e9e;
        color: #9e9e9e;
        font: 400 16px/30px $roboto;
        padding: 0 5px;
        color: $dark-font;

        &:placeholder {
            color: #9e9e9e;
        }

        &:focus {
            border-bottom: 1px solid blue;
        }
    }

    label {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    span {
        padding: 5px;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.2s;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity: 0.5;
        color: $dark-font;
        font: 400 16px $roboto;
    }

    input:focus+span,
    input:not(:placeholder-shown)+span {
        opacity: 1;
        transform: translateY(-100%) translateX(-10px);
        font-size: 13px;
    }

    input:focus+span,
    input:not(:-ms-input-placeholder)+span {
        opacity: 1;
        transform: translateY(-100%) translateX(-10px);
        font-size: 13px;
    }
}

.input-wrap {
    margin-bottom: 20px;
}

.user-edit {
    .col {
        width: 300px;
        margin-right: 30px;
    }

    &__input {
        width: 300px;
        height: 30px;
        border: none;
        border-bottom: 1px solid #9e9e9e;
        color: #9e9e9e;
        font: 400 16px/30px $roboto;
        padding: 0 5px;
        color: $dark-font;

        &:placeholder {
            color: #9e9e9e;
        }

        &:focus {
            border-bottom: 1px solid blue;
        }
    }

    label {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    span {
        padding: 5px;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.2s;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity: 0.5;
        color: $dark-font;
        font: 400 16px $roboto;
    }

    input:focus+span,
    input:not(:placeholder-shown)+span {
        opacity: 1;
        transform: translateY(-100%) translateX(-10px);
        font: 300 13px $roboto;
    }

    input:focus+span,
    input:not(:-ms-input-placeholder)+span {
        opacity: 1;
        font: 300 13px $roboto;
        transform: translateY(-100%) translateX(-10px);
    }
}

.user-edit-save {
    display: inline-block;
    height: 30px;
    padding: 0 15px;
    color: #fff;
    font: 400 14px/30px $roboto;
    background: $admin-blue;
    border: none;
    cursor: pointer;

    &:hover {
        background: lighten($admin-blue, 10%)
    }

    &:disabled {
        background: gray;
        cursor: not-allowed;
    }
}

.user-btn-download,
.user-btn-edit {
    margin-top: 30px;

    button {
        display: block;
        height: 30px;
        padding: 0 15px;
        color: #fff;
        font: 400 14px/30px $roboto;
        background: $admin-blue;
        border: none;
        cursor: pointer;
        margin: 30px 0;

        &:hover {
            background: lighten($admin-blue, 10%)
        }
    }
}

.users-select {
    width: 300px;
}

.users-select {
    label {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    span {
        padding: 5px;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: -42px;
        transition: 0.2s;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity: 0.5;
        color: $dark-font;
        font: 400 16px $roboto;
    }
}

.users-select .vs--open+label span,
.users-select .active+label span,
input:not(:placeholder-shown)+span {
    opacity: 1;
    transform: translateY(-100%) translateX(-10px);
    font-size: 13px
}

.users-select .vs--open+label span,
.users-select .active+label span,
input:not(:-ms-input-placeholder)+span {
    opacity: 1;
    transform: translateY(-100%) translateX(-10px);
    font-size: 13px;
}

#unconfirmLine {
    color: red;
}
</style>
