<template>
    <div class="profile-wrap">
        <div class="profile-main">
            <div class="profile-container" v-if="this.userData">
                <h1 class="profile-heading">
                    Профиль
                    <span v-if="verified === 1">(проверен)</span>
                    <span v-else-if="verified === 2">(отклонен)</span>
                    <span v-else-if="verified === 3">(на проверке)</span>
                </h1>
                <div v-if="!this.userData.isCompleted" class="order-blocked">
                    <span class="order-blocked__icon"></span>
                    <div class="order-blocked__text">Оформление заказов заблокировано! Заполните ваш профиль</div>
                </div>
                <form class="profile-form" @submit.prevent="updateUser">
                    <div class="row">
                        <div class="col col-lg">
                            <label :class="{ active: userName.length }" class="profile-form__label">Фио</label>
                            <div class="profile-input-wrap">
                                <input v-mask="maskName" v-model="userName" placeholder="Сергеев Евгений Олегович"
                                    :class="{ active: userName.length, error: hasNumber }" class="profile-form__input">
                                <span class="profile-form__clear" @click="clearName()"></span>
                                <div v-if="hasNumber" class="error-text">Поле должно содержать только буквы</div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-lg">
                            <label :class="{ active: nationality != null }"
                                class="profile-form__label">Гражданство</label>
                            <div class="profile-input-wrap">
                                <v-select :class="{ choosed: nationality }" class="style-chooser" :options="countries"
                                    label="name" v-model="nationality" @input="changeNationality">
                                    <div slot="no-options">Выберете из списка</div>
                                </v-select>
                            </div>
                        </div>
                    </div>

                    <div class="row passport" v-if="nationality">
                        <div class="col col-sm" v-if="nationality.code !== 'kz'">
                            <label :class="{ active: passportSeries.length }" class="profile-form__label">Серия
                                паспорта</label>
                            <div class="profile-input-wrap">
                                <input v-mask="passportSeriesMask" v-model="passportSeries" placeholder="3333"
                                    :class="{ active: passportSeries.length === 4 }" class="profile-form__input">
                                <span class="profile-form__clear" @click="clearPassportSeries()"></span>
                            </div>
                        </div>

                        <div class="col col-sm">
                            <label :class="{ active: passportNum.length }" class="profile-form__label">
                                {{
                                    nationality.code !== 'kz'
                                        ? 'Номер паспорта'
                                        : 'Удостоверение личности'
                                }}
                            </label>
                            <div class="profile-input-wrap">
                                <input v-mask="(nationality.code !== 'kz') ? passportNumMask : passportKzNumMask"
                                    v-model="passportNum"
                                    :placeholder="nationality.code !== 'kz'? '333333' : '333333333'"
                                    :class="{ active: (nationality.code !== 'kz') ? (passportNum.length === 6) : (passportNum.length === 9) }"
                                    class="profile-form__input">
                                <span class="profile-form__clear" @click="clearPassportNum()"></span>
                            </div>
                        </div>

                        <div class="col col-sm" v-if="nationality.code !== 'kz'">
                            <label :class="{ active: INN.value.length }" class="profile-form__label">ИНН</label>
                            <div class="profile-input-wrap">
                                <input placeholder="123456789101" v-model="INN.value" v-mask="INN.mask" :class="{ active: INN.value.length }"
                                    class="profile-form__input">
                                <span class="profile-form__clear" @click="INN.value = ''"></span>
                            </div>
                        </div>

                        <div class="col col-sm">
                            <label :class="{ active: passportDate.length }" class="profile-form__label">Дата
                                выдачи</label>
                            <div class="profile-input-wrap">
                                <input name="passport" v-mask="passportDateMask"
                                    placeholder="11-11-2000"
                                    v-validate="{ date_format: 'dd-MM-yyyy', date_between: ['01-01-1900', maxStartDate] }"
                                    v-model="passportDate"
                                    :class="{ active: !errors.has('passport'), error: errors.has('passport') }"
                                    class="profile-form__input">
                                <span class="profile-form__clear" @click="clearPassportDate()"></span>
                                <div v-if="errors.has('passport')" class="error-text">Введите корректную дату</div>
                            </div>
                        </div>

                        <div v-if="nationality.code !== 'kz'" class="col col-sm">
                            <label :class="{ active: passportCode.length }" class="profile-form__label">Код
                                подразделения</label>
                            <div class="profile-input-wrap">
                                <input placeholder="333-333" v-mask="passportCodeMask" v-model="passportCode"
                                    :class="{ active: passportCode.length === 7 }" class="profile-form__input">
                                <span class="profile-form__clear" @click="clearPassportCode()"></span>
                            </div>
                        </div>

                        <div v-if="nationality.code !== 'kz'" class="col col-sm">
                            <label :class="{ active: SNILS.value.length }" class="profile-form__label">СНИЛС</label>
                            <div class="profile-input-wrap">
                                <input placeholder="999-999-999 99" v-model="SNILS.value" v-mask="SNILS.mask" :class="{ active: SNILS.value.length }"
                                    class="profile-form__input">
                                <span class="profile-form__clear" @click="SNILS.value = ''"></span>
                            </div>
                        </div>

                        <div v-if="nationality.code !== 'kz'" class="col col-sm">
                            <label :class="{ active: birthDate.length }" class="profile-form__label">Дата
                                рождения</label>
                            <div class="profile-input-wrap">
                                <input name="birthDate" v-mask="passportDateMask"
                                    placeholder="10-10-1980"
                                    v-validate="{ date_format: 'dd-MM-yyyy', date_between: ['01-01-1900', maxStartDate] }"
                                    v-model="birthDate"
                                    :class="{ active: !errors.has('birthDate'), error: errors.has('birthDate') }"
                                    class="profile-form__input">
                                <span class="profile-form__clear" @click="birthDate = ''"></span>
                                <div v-if="errors.has('birthDate')" class="error-text">Введите корректную дату</div>
                            </div>
                        </div>

                        <div v-if="nationality.code !== 'kz'" class="col col-65">
                            <label class="profile-form__label active">СНИЛС (фото)</label>
                            <div class="flex">
                                <div class="photo_info">
                                    <label class="profile-form__input active choose_photo" for="photo">Выбрать
                                        файл</label>
                                    <span v-if="photo" class="profile-form__clear" @click="photo = null"></span>

                                    <div v-if="photo || userData.photo" class="photo_info__inner">
                                      
                                        <span v-if="photo">{{ photo.name }}</span>&nbsp;
                                         
                                        <span v-if="userData.photo && photo === null" @click="openImage(userData.photo)"
                                            class="photo_info__link">Просмотреть фото</span>&nbsp;
                                    </div>
                                </div>
                                <div class="photo_desc" :class="{ 'alert': imageFormatError }">
                                    Выберите файл формата jpeg, jpg, png размером не более 10Мб.
                                </div>
                            </div>
                            <input style="display: none" id="photo" type="file" ref="photo" @change="previewFiles">
                        </div>
                        <div v-if="nationality.code !== 'kz'" class="col col-65">
                            <label class="profile-form__label active">Паспорт (фото 2-й и 3-й страницы)</label>
                            <div class="flex">
                                <div class="photo_info">
                                    <label class="profile-form__input active choose_photo" for="photoPassport">Выбрать
                                        файл</label>
                                    <span v-if="photoPassport" class="profile-form__clear"
                                        @click="photoPassport = null"></span>

                                    <div v-if="photoPassport || userData.photoPassport" class="photo_info__inner">
                                        <span v-if="photoPassport">{{ photoPassport.name }}</span>&nbsp;
                                        <span v-if="userData.photoPassport && photoPassport === null" @click="openImage(userData.photoPassport)"
                                            class="photo_info__link">Просмотреть фото</span>&nbsp;

                                    </div>
                                </div>
                                <div class="photo_desc" :class="{ 'alert': imageFormatError }">
                                    Выберите файл формата jpeg, jpg, png размером не более 10Мб.
                                </div>
                            </div>
                            <input style="display: none" id="photoPassport" type="file" ref="photoPassport"
                                @change="previewPhotoPassport">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-lg">
                            <label :class="{ active: passportIssued.length }" class="profile-form__label">Кем
                                выдан</label>
                            <div class="profile-input-wrap">
                                <textarea v-model="passportIssued"
                                    placeholder="МВД Республики Казахстан"
                                    :class="{ active: passportIssued.length, error: minLength }"
                                    class="profile-form__textarea"></textarea>
                                <span class="profile-form__clear" @click="clearPassportIssued()"></span>
                                <div v-if="minLength" class="error-text">Минимальное количество символов: 8</div>
                            </div>
                        </div>
                    </div>

                    <div class="row hide">
                        <div class="col col-md">
                            <label :class="{ active: inn.length }" class="profile-form__label">ИНН</label>
                            <div class="profile-input-wrap">
                                <input placeholder="123456789101" v-model="inn" :class="{ active: inn.length }" class="profile-form__input">
                                <span class="profile-form__clear" @click="clearInn()"></span>
                            </div>
                        </div>
                        <div class="col col-md">
                            <label :class="{ active: snils.length }" class="profile-form__label">СНИЛС</label>
                            <div class="profile-input-wrap">
                                <input placeholder="999-999-999 99" v-model="snils" :class="{ active: snils.length, }" class="profile-form__input">
                                <span class="profile-form__clear" @click="clearSnils()"></span>
                            </div>
                        </div>
                    </div>

                    <div v-if="nationality && nationality.code !== 'kz'" class="row">
                        <div class="col col-lg address-heading">
                            <label class="profile-form__label" :class="{ active: address_city.length }">Адрес
                                регистрации</label>
                        </div>
                        <input-with-suggestions v-model="address_city" :classes="'col-lg address-input'" bound="city"
                            data-part="city" placeholder="Город" />

                        <input-with-suggestions v-model="street" :query="queryString" :classes="'col-lg address-input'"
                            bound="street" data-part="street" placeholder="Улица" />

                        <input-with-suggestions v-model="houseNumber" :query="queryString"
                            :classes="'col-md address-input'" :set-index="true" bound="house" data-part="house"
                            placeholder="Номер дома" />

                        <div class="col col-md address-input">
                            <div class="profile-input-wrap">
                                <input v-model="apartment" :class="{ active: apartment.length }"
                                    class="profile-form__input" placeholder="Квартира">
                                <span class="profile-form__clear" @click="clearApartment()"></span>
                            </div>
                        </div>
                        <div class="col col-md address-input">
                            <div class="profile-input-wrap">
                                <input v-model="building" :class="{ active: building.length }"
                                    class="profile-form__input" placeholder="Здание">
                                <span class="profile-form__clear" @click="clearBuilding()"></span>
                            </div>
                        </div>
                        <div class="col col-md address-input">
                            <div class="profile-input-wrap">
                                <input v-model="build" :class="{ active: build.length }" class="profile-form__input"
                                    placeholder="Корпус">
                                <span class="profile-form__clear" @click="clearBuild()"></span>
                            </div>
                        </div>
                        <div class="col col-md address-input">
                            <div class="profile-input-wrap">
                                <input v-model="structure" :class="{ active: structure.length }"
                                    class="profile-form__input" placeholder="Строение">
                                <span class="profile-form__clear" @click="clearStructure()"></span>
                            </div>
                        </div>
                        <div class="col col-md address-input">
                            <div class="profile-input-wrap">
                                <input v-model="possession" :class="{ active: possession.length }"
                                    class="profile-form__input" placeholder="Владение">
                                <span class="profile-form__clear" @click="clearPossession()"></span>
                            </div>
                        </div>
                        <div class="col col-md address-input">
                            <div class="profile-input-wrap">
                                <input v-mask="indexNumMask" v-model="index" :class="{ active: possession.length }"
                                    class="profile-form__input" placeholder="Индекс">
                                <span class="profile-form__clear" @click="clearIndex()"></span>
                            </div>
                        </div>

                        <input-with-suggestions v-model="region" :classes="'col-md address-input'" bound="region"
                            data-part="region" placeholder="Регион" />
                    </div>
                    <div v-else>
                        <div class="col col-lg">
                            <label :class="{ active: addressRegister.length }" class="profile-form__label">
                                Адрес регистрации
                            </label>
                            <div class="profile-input-wrap">
                                <input placeholder="Адрес" v-model="addressRegister" :class="{ active: addressRegister.length }"
                                    class="profile-form__input">
                                <span class="profile-form__clear" @click="clearAddressRegister()"></span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-cities">
                            <label :class="{ active: citySelected != null }" class="profile-form__label">Город</label>
                            <div class="profile-input-wrap">
                                <v-select :class="{ choosed: citySelected }" class="style-chooser" :options="cities"
                                    label="name" v-model="citySelected" @input="changeCity">
                                    <div slot="no-options">Нет такого города</div>
                                </v-select>
                            </div>
                        </div>

                        <div class="col col-stations">
                            <label :class="{ active: stationsSelected != null }"
                                class="profile-form__label">Станции</label>
                            <div class="profile-input-wrap">
                                <v-select :class="{ choosed: stationsSelected }" class="style-chooser"
                                    :options="stationsFiltered" label="address"
                                    :disabled="stationsFiltered.length === 0" v-model="stationsSelected">
                                    <div slot="no-options">Нет такой станции</div>
                                </v-select>
                            </div>
                        </div>
                    </div>

                    <div class="row profile-tel">
                        <div class="col col-md">
                            <label class="profile-form__label active">Номер телефона</label>
                            <div class="profile-input-wrap">
                                <input placeholder="9999999999" class="profile-form__input active" v-model="phone" disabled>
                            </div>
                        </div>
                        <div class="col col-md">
                            <label class="profile-form__label"></label>
                            <div class="profile-input-wrap number-accepted">
                                <span class="accepted-icon"></span>Номер подтвержден
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg">
                            <button class="profile-form__btn" type="submit" :disabled="btnDisabled">Сохранить</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="profile-popup" v-if="saved">
                <div>Ваш профиль отправлен на проверку внесенных данных. Обработка данных займет 3 рабочих дня</div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import moment from 'moment'
import 'vue-select/dist/vue-select.css'
import InputWithSuggestions from "../components/Form/InputWithSuggestions";

export default {
    components: { InputWithSuggestions },
    data() {
        return {
            userName: '',
            passportSeries: '',
            passportNum: '',
            passportDate: '',
            birthDate: '',
            passportCode: '',
            passportIssued: '',
            inn: '',
            snils: '',
            SNILS: {
                value: '',
                mask: "###-###-### ##"
            },
            INN: {
                value: '',
                mask: "############"
            },
            reader: new FileReader(),
            photo: null,
            readerPassport: new FileReader(),
            photoPassport: null,
            addressRegister: '',
            city: '',
            address_city: '',
            street: '',
            houseNumber: '',
            index: '',
            region: '',
            possession: '',
            building: '',
            build: '',
            structure: '',
            apartment: '',
            citiesOptions: [],
            stationOptions: [],
            citySelected: null,
            stationsSelected: null,
            nationality: null,
            phone: '',
            maskName: "X".repeat(60),
            indexNumMask: "######",
            passportSeriesMask: "####",
            passportNumMask: "######",
            passportKzNumMask: "#########",
            passportDateMask: "##-##-####",
            passportCodeMask: "###-###",
            saved: false,
            imageFormatError: false,
            verified: 0,
            queryString: ''
        }
    },

    computed: {
        ...mapState({
            userData: state => state.profile.user,
            cities: state => state.profile.cities,
            stations: state => state.profile.stations,
            countries: state => state.profile.countries,
            suggestedRegion: state => state.profile.suggestedRegion,
            suggestedIndex: state => state.profile.suggestedIndex,
        }),

        minLength() {
            if (this.passportIssued.length > 0 && this.passportIssued.length < 8) {
                return true
            }
            return false
        },

        hasNumber() {
            if (!(/^[a-zA-Zа-яА-ЯЁё\s]+$/.test(this.userName))) {
                return true
            }
            return false
        },

        hasInn() {
            if (this.INN.value.length > 0) {
                return true;
            } else {
                return false;
            }
        },

        hasSnils() {
            if (this.SNILS.value.length > 0) {
                return true;
            } else {
                return false;
            }
        },

        hasDateOfBirth() {
            if (this.birthDate.length) {
                return true;
            } else {
                return false;
            }
        },

        stationsFiltered() {
            return (this.citySelected)
                ? this.citySelected.stations
                : [];
        },

        btnDisabled() {
            // если не рф
            if (this.nationality !== null && this.nationality.code === 'kz') {
                 if (!this.userName.length || !this.nationality
                    || this.citySelected === null || this.stationsSelected === null 
                    || (this.nationality.code === 'kz' && (this.passportNum.length !== 9)) 
                    || !this.passportDate.length || !this.passportIssued.length || !this.addressRegister.length
                ) {
                    return true;
                }
            }

            // если рф
            if (this.nationality !== null && this.nationality.code !== 'kz') {
                if (!this.userName.length || !this.passportIssued || !this.index || !this.region || !this.address_city || !this.street || !this.houseNumber
                    || this.citySelected === null || this.stationsSelected === null || !this.nationality || this.hasNumber === true || this.minLength === true
                    || (this.nationality.code !== 'kz' && (this.passportSeries.length !== 4 || this.passportNum.length !== 6 || this.passportCode.length !== 7))
                    || (this.photo === null && this.userData.photo === null) || this.hasDateOfBirth === false || this.hasInn === false || this.hasSnils === false
                    || (this.photoPassport === null && this.userData.photoPassport === null) || this.errors.has('photoPassport')
                ) {
                    return true;
                }
            }

            return false;
        },


        maxStartDate() {
            return moment().format('DD-MM-YYYY');
        }
    },

    watch: {
        stations() {
            if (this.stations) {
                this.stationOptions = this.stations
            }
        },

        userData() {
            this.phone = this.userData.phone
            this.userData.birth_date != null ? this.birthDate = this.revertDate(this.userData.birth_date) : this.birthDate = ""
            this.userData.full_name != null ? this.userName = this.userData.full_name : this.userName = ""
            this.nationality = this.userData.country
            this.SNILS.value = this.userData.snils ? this.userData.snils : ""
            this.INN.value = this.userData.inn ? this.userData.inn : ""
            this.userData.city != null ? this.citySelected = this.userData.city : this.citySelected = null
            this.userData.station != null ? this.stationsSelected = this.userData.station.address : this.stationsSelected = null
            this.userData.address != null ? this.addressRegister = this.userData.address : this.addressRegister = ""
            this.userData.passport_serie != null ? this.passportSeries = this.userData.passport_serie : this.passportSeries = ""
            this.userData.passport_number != null ? this.passportNum = this.userData.passport_number : this.passportNum = ""
            this.userData.passport_emit_date != null ? this.passportDate = this.revertDate(this.userData.passport_emit_date) : this.passportDate = ""
            this.userData.emitter_code != null ? this.passportCode = this.userData.emitter_code : this.passportCode = ""
            this.userData.emitter != null ? this.passportIssued = this.userData.emitter : this.passportIssued = ""
            this.userData.verified != null ? this.verified = this.userData.verified : this.verified = 0

            this.address_city = this.userData.address_city != null ? this.userData.address_city : '';
            this.street = this.userData.street != null ? this.userData.street : '';
            this.houseNumber = this.userData.houseNumber != null ? this.userData.houseNumber : '';
            this.index = this.userData.index;
            this.region = this.userData.region != null ? this.userData.region : '';
            this.possession = this.userData.possession != null ? this.userData.possession : '';
            this.building = this.userData.building != null ? this.userData.building : '';
            this.build = this.userData.build != null ? this.userData.build : '';
            this.structure = this.userData.structure != null ? this.userData.structure : '';
            this.apartment = this.userData.apartment != null ? this.userData.apartment : '';
        },

        suggestedRegion() {
            if (this.suggestedRegion) {
                this.region = this.suggestedRegion;
            }
        },

        suggestedIndex() {
            if (this.suggestedIndex) {
                this.index = this.suggestedIndex;
            }
        },

        region() {
            this.buildQuery();
        },

        address_city() {
            this.buildQuery();
        },

        street() {
            this.buildQuery();
        },

        houseNumber() {
            this.buildQuery();
        }
    },

    methods: {
        openBase64Frame(base64URL) {
            var win = window.open();
            win.document.write('<img src="' + base64URL + '" alt="Image" style="width:100%; height:auto;">');
        },
        openImage(img) {
            const link = process.env.VUE_APP_baseURL + '/' + img
            const token = localStorage.getItem('jwt');
            fetch(link, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => response.text())
                .then(data => {
                    this.openBase64Frame(data);
                });
        },
        buildQuery() {
            this.queryString = [
                this.region,
                this.address_city,
                this.street,
                this.houseNumber
            ].join(' ').trim();
        },

        // Функция преобразования формата даты
        revertDate(date_line) {
            let sp = date_line.split("-").reverse();
            return sp.join("-");
        },

        previewFiles() {
            let types = ["image/jpg", "image/jpeg", "image/png"]
            this.photo = this.$refs.photo.files[0]
            this.imageFormatError = false;

            if (types.includes(this.photo.type) && (this.photo.size < 10000000)) {
                if (this.photo) {
                    this.reader.readAsDataURL(this.photo)
                }
            } else {
                this.imageFormatError = true;
                console.log('false')
                this.photo = null
            }
        },

        previewPhotoPassport() {
            let types = ["image/jpg", "image/jpeg", "image/png"];
            this.photoPassport = this.$refs.photoPassport.files[0];
            this.imageFormatError = false;

            if (types.includes(this.photoPassport.type) && (this.photoPassport.size < 10000000)) {
                if (this.photoPassport) {
                    this.readerPassport.readAsDataURL(this.photoPassport);
                }
            } else {
                this.imageFormatError = true;
                this.photoPassport = null;
            }
        },

        updateUser() {
            this.saved = false
            if (this.reader['result']) {
                this.photo = this.reader['result']
            }

            if (this.readerPassport['result']) {
                this.photoPassport = this.readerPassport['result'];
            }

            let profileData = {
                "full_name": this.userName,
                "countryId": this.nationality.id,
                "address": this.addressRegister,
                "passport_serie": this.nationality.code !== 'kz' ? this.passportSeries : '',
                "inn": this.INN.value ? this.INN.value : "",
                "snils": this.SNILS.value ? this.SNILS.value : "",
                "photo": this.photo ? this.photo : "",
                "birth_date": this.birthDate ? this.revertDate(this.birthDate) : "",
                "passport_number": this.passportNum,
                "passport_emit_date": this.revertDate(this.passportDate),
                "emitter": this.passportIssued,
                "emitter_code": this.nationality.code !== 'kz' ? this.passportCode : '',
                "cityId": this.citySelected.id,
                "stationId": this.stationsSelected.id,
                "verified": this.verified,
                "address_city": this.address_city,
                "street": this.street,
                "houseNumber": this.houseNumber,
                "index": this.index,
                "region": this.region,
                "possession": this.possession,
                "building": this.building,
                "build": this.build,
                "structure": this.structure,
                "letter": this.letter,
                "apartment": this.apartment,
                "photoPassport": this.photoPassport ? this.photoPassport : "",
            }

            this.$store.dispatch('updateUser', { id: this.userData.id, profileData: profileData }).then(() => {
                this.saved = true
            })
        },

        clearName() {
            this.userName = ''
        },

        clearPassportSeries() {
            this.passportSeries = ''
        },

        clearPassportNum() {
            this.passportNum = ''
        },

        clearPassportDate() {
            this.passportDate = ''
        },

        clearPassportCode() {
            this.passportCode = ''
        },

        clearPassportIssued() {
            this.passportIssued = ''
        },

        clearInn() {
            this.inn = ''
        },

        clearSnils() {
            this.snils = ''
        },

        clearAddressRegister() {
            this.addressRegister = ''
        },

        clearCity() {
            this.city = ''
        },

        clearIndex() {
            this.index = ''
        },

        clearPossession() {
            this.possession = ''
        },

        clearBuilding() {
            this.building = ''
        },

        clearApartment() {
            this.apartment = ''
        },

        clearBuild() {
            this.build = ''
        },

        clearStructure() {
            this.structure = ''
        },

        changeCity() {
            this.stationsSelected = null
        },

        changeNationality() {
            this.passportNum = ''
        }
    },

    created() {
        this.$store.dispatch('fetchUser');
        this.$store.dispatch('fetchCities');
        this.$store.dispatch('fetchStations');
        this.$store.dispatch('fetchCountries');
    },

}
</script>

<style lang="scss" scoped>
.alert {
    font-size: 18px !important;
    color: red !important;
}

.flex {
    display: flex;

    @include display-less(tablet) {
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
    }
}

.photo_desc {
    width: 50%;
    color: white;
    font-size: 13px;
}

.photo_info {
    position: relative;
    width: 190px;

    &__link {
        color: white;
        font-size: 13px;
        width: 165px;
        padding-top: 5px;
        position: absolute;
        text-decoration: underline;
        cursor: pointer;
    }

    &__inner {
        color: white;
        font-size: 13px;
        width: 165px;
        padding-top: 5px;
        position: absolute;
    }

    .choose_photo {
        cursor: pointer;
        padding: 10px;
        border: 1px solid #5A5A5A;
        border-radius: 4px;
        color: #fff;
        font: 300 16px/40px "Roboto", sans-serif;
        text-transform: uppercase;
    }
}

.col {
    margin-top: 30px;
}

.col-lg {
    width: 100%;
}

.col-sm {
    width: 33.33%;
}

.col-md {
    width: 50%;
}

.col-65 {
    width: 65%;
}

.col-cities {
    width: 40%;

    @include display-less(tablet) {
        width: 100%;
    }
}

.col-stations {
    width: 60%;

    @include display-less(tablet) {
        width: 100%;
    }
}

.passport .col-sm {
    @include display-less(tablet) {
        width: 25%;
    }

    @include display-less(phablet) {
        width: 50%;
    }

}

.empty {
    width: 10%;

    @include display-less(tablet) {
        display: none;
    }
}

.number-accepted {
    display: flex;
    align-items: center;
    height: 40px;
    color: #00FFB2;
    font: 400 14px/40px $roboto;
}

.profile-tel {
    @include display-less(phablet) {
        flex-direction: column;

        .col-md {
            width: 100%;

            &:nth-child(2) {
                margin-top: 0px;

                label {
                    display: none;
                }
            }
        }
    }
}

.accepted-icon {
    display: block;
    width: 17px;
    height: 12px;
    margin-right: 12px;
    background-image: url('../assets/images/icons/accepted-green.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.error-text {
    display: block;
    position: absolute;
    left: 0;
    bottom: -5px;
    height: 16px;
    font: 400 14px/40px $roboto;
    color: $red;
}

.profile-popup {
    box-sizing: border-box;
    width: 350px;
    color: #ffffff;
    display: block;
    position: fixed;
    background: #3f3f3f;
    font: 300 16px/18px $roboto;
    padding: 10px 15px;
    border-radius: 4px;
    text-align: center;
    z-index: 3;
    top: 80px;
    left: 30px;
    font-size: 16px;
    line-height: 30px;
    min-height: 50px;
    pointer-events: none;
}

.profile-popup {
    animation: css 0s 3s forwards;
    transition: (a)
}

@keyframes css {
    to {
        visibility: hidden;
    }
}
</style>
