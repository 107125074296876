import Vue from 'vue'
import router from '../../services/router/index'

export default {
  state: {
    userData: null,
    authResult: null,
    loginError: null,
    smsError: null,
    balance: null
  },

  mutations: {
    SET_USER_DATA(state, payload) {
      state.userData = payload
    },

    SET_AUTH(state, payload) {
      state.authResult = payload.success
      localStorage.setItem('jwt', payload.jwt)
      router.push({ name: "home" })
    },

    CLEAR_AUTH(state) {
      state.authResult = null
      localStorage.removeItem('jwt')
    },

    LOGIN_ERROR(state, payload) {
      state.loginError = payload
    },

    SMS_ERROR(state, payload) {
      state.smsError = payload
    },

    USER_BALANCE(state, payload) {
      state.balance = payload
    }
  },

  actions: {
    login({ commit }, userData) {
      Vue.http.post('/api/login', {
        "phone": userData.tel
      })
        .then(response => {
          // Полученный токен еще не валиден, поэтому мы не можем его сохранить в localStorage
          let result = {
            "jwt": response.data.token.jwt,
            "tel": userData.tel
          }
          commit('SET_USER_DATA', result)
          localStorage.removeItem('jwt')
          commit('LOGIN_ERROR', null)
        })
        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        });
    },

    checkSMS({ commit, state }, smsCode) {
      // Но токен необходим для смс подтверждения (через плагин он не подтянется, тк его нет в localStorage)
      const config = {
        headers: {
          'Authorization': `Bearer ${state.userData.jwt}`
        }
      };

      commit('SMS_ERROR', null)

      const bodyParameters = {
        "phone": state.userData.tel,
        ...smsCode
      };

      Vue.http.post(
        `/api/checkSMSCode`,
        bodyParameters,
        config
      )
        .then(response => {
          if (response.data.success.length) {
            let result = {
              "jwt": state.userData.jwt,
              "success": response.data.success
            }
            commit('SET_AUTH', result)
          }
        })
        .catch(error => {
          commit('SMS_ERROR', error)
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        });
    },

    getMeBalance({ commit }) {
      Vue.http.get('/api/me/balance')
        .then(response => {
          commit('USER_BALANCE', response)
        })
        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
          console.log(error);
        });
    }
  }
}