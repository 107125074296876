import Vue from 'vue'

export default {
  state: {
    statusHistory: [],
    offset: 0,
    limit: 10,
    finished: false,
  },

  mutations: {
    SET_STATUS_HISTORY(state, { statusHistory, reset }) {
      if (reset) {
        state.statusHistory = statusHistory
      } else {
        state.statusHistory = state.statusHistory.concat(statusHistory);
      }
    },

    SET_OFFSET(state, offset) {
      state.offset = offset;
    },

    SET_FINISHED(state, finished) {
      state.finished = finished;
    },
  },

  actions: {
    getStatusHistory({ commit, state }, { reset = false, startDate = null, endDate = null, fullName = '' } = {}) {
      const { offset, limit } = state
      const offsetParam = reset ? 0 : offset;

      const params = [
        `offset=${offsetParam}`,
        `limit=${limit}`,
        startDate ? `startDate=${startDate}` : '',
        endDate ? `endDate=${endDate}` : '',
        fullName.length ? `fullName=${fullName}` : '',
      ].filter(Boolean).join('&');

      return Vue.http_admin.get(`api/status-history?${params}`)
        .then(response => {
          const statusHistory = response.data.statusHistory
          if (reset) {
            commit('SET_FINISHED', false);
            commit('SET_OFFSET', 0);
          }
          if (statusHistory.length < state.limit) {
            commit('SET_FINISHED', true);
          }

          commit('SET_STATUS_HISTORY', { statusHistory, reset })
          commit('SET_OFFSET', offset + 1);
          return statusHistory;
        })
        .catch(error => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        });
    },

  },
}
