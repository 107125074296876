<template>
  <div class="orders admin-tasks">

    <!-- popup -->
    <div class="modal" v-if="popupIsActive">
      <div class="category-popup" v-if="taskDeletePopup">
        <div class="modal__title">
          Вы действительно хотите удалить задание «{{ taskName }}»?
        </div>
        <div class="modal__text">
          Это действие приведет к удалению всей информации о задании.
        </div>
        <div class="modal-btns">
          <button class="modal-btn delete" @click="
            deleteTask();
          popupIsActive = false;
          taskDeletePopup = false;
          ">
            Удалить
          </button>
          <button class="modal-btn decline" @click="
            popupIsActive = false;
          taskDeletePopup = false;
          ">
            Отмена
          </button>
        </div>
      </div>
    </div>

    <h1 class="admin-tasks-heading">Задания</h1>

    <AdminTasksFilter :filters="filters" @update-filter="updateFilter" />

    <!-- table -->
    <div class="admin-tasks-top">
      <div class="tasks-result-wrap">
        <div class="tasks-result-head">
          <div class="col tasks-result-head__name">
            Задание от (бренд)
            <img @click="toggleSort()" class="sort-icon" :class="{ iconActive: filters.order === 'asc' }" width="15"
              height="15" src="../../../assets/images/icons/sort.svg" alt="">
          </div>
          <div class="col tasks-result-head__reward">Вознаграждение <img @click="toggleMaxFee()" class="sort-icon"
              :class="{ iconActive: filters.orderColumn === 'max_fee' }" width="15" height="15"
              src="../../../assets/images/icons/sort.svg" alt=""></div>
          <div class="col tasks-result-head__date-start">Дата начала <img @click="toggleStartDate()" class="sort-icon"
              :class="{ iconActive: filters.orderColumn === 'startDate' }" width="15" height="15"
              src="../../../assets/images/icons/sort.svg" alt=""></div>
          <div class="col tasks-result-head__date-end">Дата окончания <img @click="toggleEndDate()" class="sort-icon"
              :class="{ iconActive: filters.orderColumn === 'endDate' }" width="15" height="15"
              src="../../../assets/images/icons/sort.svg" alt=""></div>
        </div>

        <div class="tasks-result-list-wrap">
          <div class="list-wrap">
            <div class="tasks-result" v-for="(task, idx) in tasks" :key="idx" :class="{ active: task.id == selected }"
              @click="
                getTaskId(task.id);
              selected = task.id;
              ">
              <div class="col tasks-result__name">{{ task.name }}</div>
              <div class="col tasks-result__reward">{{ task.fee }}</div>
              <div class="col tasks-result__date-start">
                {{ task.startDate }}
              </div>
              <div class="col tasks-result__date-end">{{ task.endDate }}</div>
            </div>
          </div>
        </div>
        <div class="categories-list-btns">
          <button class="categories-list-btn add" @click="createTask">
            Добавить
          </button>
          <button v-if="selected != undefined" class="categories-list-btn remove" @click="
            popupIsActive = true;
          taskDeletePopup = true;
          ">
            Удалить
          </button>
        </div>
      </div>
    </div>

    <!-- form -->
    <div class="admin-tasks-bot" v-if="selected != undefined">
      <div class="row">
        <div class="col">
          <div class="input-wrap">
            <label>
              <input class="user-edit__input" type="text" placeholder=" " v-model="taskName" />
              <span>Наименование бренда </span>
            </label>
          </div>
          <label class="product-hide">Действует бессрочно
            <input type="checkbox" v-model="termless" />
            <span class="checkmark"></span>
          </label>
          <div class="datepickers">
            <div class="datepicker startDate">
              <div class="datepicker__info">c:</div>
              <div class="datepicker__calendar">
                <datepicker :language="ru" :format="customFormatter" :disabled-dates="disabledDatesStart"
                  input-class="dateInput" wrapper-class="dateInputWrap" v-model="startDate" @selected="dateChangeStart">
                </datepicker>
              </div>
            </div>
            <div class="datepicker endDate" v-if="!termless">
              <div class="datepicker__info">по:</div>
              <div class="datepicker__calendar">
                <datepicker :language="ru" :format="customFormatter" :disabled-dates="disabledDatesEnd"
                  input-class="dateInput" wrapper-class="dateInputWrap" v-model="endDate" @selected="dateChangeEnd">
                </datepicker>
              </div>
            </div>
          </div>

          <div class="product-foto">
            <div class="product-foto__item">
              <picture-input v-if="show_upload" :class="{ visible: show_upload }" ref="pictureInput"
                accept="image/jpeg,image/png" :alertOnError="false" size="10" button-class="btn" :removable="true"
                :custom-strings="{
                  drag: 'Вставить изображение',
                  change: 'Изменить',
                  remove: 'Удалить',
                }" @change="onChange" @remove="removeImage">
              </picture-input>

              <div class="preview" :class="{ visible: !show_upload }">
                <img v-if="logo_url" class="product-foto__item__preview" :src="logo_url" />
                <button class="image-btn image-btn--replace" @click="
                  show_upload = true;
                $refs.pictureInput.click();
                ">
                  Изменить
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="input-wrap">
            <label>
              <textarea class="user-edit__input" type="text" placeholder=" " v-model="description"></textarea>
              <span>Описание задания</span>
            </label>
          </div>
          <div class="input-wrap">
            <label>
              <input class="user-edit__input" type="text" placeholder=" " v-model="video_link" />
              <span>Ссылка на youtube</span>
            </label>
          </div>
          <div class="tasks-select">
            <v-select class="admin-select " :class="{ active: selectFor }" :options="userRoles" label="name"
              v-model="selectFor">
              <div slot="no-options">Выберете из списка</div>
            </v-select>
            <label>
              <span>Для кого задание</span>
            </label>
          </div>
          <div class="tasks-select">
            <v-select class="admin-select" :class="{ active: selectFrom }" :options="organizations" label="name"
              v-model="selectFrom">
              <div slot="no-options">Выберете из списка</div>
            </v-select>
            <label>
              <span>От кого задание</span>
            </label>
          </div>
        </div>
        <div class="col">
          <div class="input-wrap">
            <label>
              <input class="user-edit__input" type="text" placeholder=" " v-model="fee" />
              <span>Вознаграждение</span>
            </label>
          </div>

          <div class="upload-file">
            <h3 class="upload-file__title">Приказ</h3>

            <div class="upload-file__previous" v-if="order_document_url">
              <span>
                Загружен:
                <a target="_blank" :href="order_document_url">{{
                  order_document_name
                }}</a>
              </span>
            </div>

            <label class="file-select">
              <div class="select-button">
                <div class="upload-file-block">
                  <span v-if="file" class="file-txt">{{ file.name }}</span>
                  <span v-else class="file-txt">Выберите новый файл</span>
                  <input type="file" ref="inputFile" id="input-file" @change="handleFileChange" />
                  <div class="upload-file-bottom">
                    <div class="file-type" v-if="file">
                      Тип файла: {{ file.type }}
                    </div>
                    <div class="file-size" v-if="file">
                      Размер: {{ bytesToSize(file.size) }}
                    </div>
                  </div>
                </div>
              </div>
            </label>
            <button class="file-btn file-replace" @click="$refs.inputFile.click()">
              Заменить
            </button>
            <button class="file-btn file-delete" @click="removeFile">
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>

    <button class="save-product" @click="updateTask" v-if="selected != undefined" :disabled="cantSubmit">
      Сохранить
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import Datepicker from "vuejs-datepicker";
import PictureInput from "vue-picture-input";
import { ru } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import AdminTasksFilter from "./components/AdminTasksFilter.vue";


export default {
  components: {
    Datepicker,
    PictureInput,
    AdminTasksFilter
  },
  data() {
    return {
      filters: {
        brand: '',
        orderColumn: null,
        order: 'desc',
      },

      selected: undefined,
      taskId: null,
      ru: ru,
      dateStartIsChanged: false,
      dateEndIsChanged: false,
      logo_url: "",
      order_document_url: "",
      order_document_name: "",
      taskName: "",
      termless: false,
      startDate: "",
      endDate: "",
      convertedStartDate: "",
      convertedEndDate: "",
      description: "",
      video_link: "",
      selectFor: "",
      selectFrom: "",
      fee: "0",
      logo: null,
      file: null,
      disabledDatesStart: {
        from: null,
      },
      disabledDatesEnd: {
        to: null,
      },
      show_upload: false,
      taskDeletePopup: false,
      popupIsActive: false,
    };
  },

  computed: {
    ...mapState({
      tasks: (state) => state.tasks.tasks,
      task: (state) => state.tasks.task,
      userRoles: (state) => state.tasks.userRoles,
      organizations: (state) => state.tasks.organizations,
    }),

    cantSubmit() {
      return (
        !this.taskName ||
        !this.convertedStartDate ||
        !this.convertedEndDate ||
        !this.selectFrom ||
        !this.selectFor
      );
    },

  },




  methods: {
    ...mapActions(['fetchTasksAdmin']),
    updateFilter(key, value) {
      this.filters[key] = value;
    },

    toggleMaxFee() {
      this.filters.orderColumn = this.filters.orderColumn === "max_fee" ? '' : "max_fee";
    },

    toggleSort() {
      this.filters.order = this.filters.order === 'asc' ? 'desc' : 'asc';
    },

    toggleStartDate() {
      this.filters.orderColumn = this.filters.orderColumn === "startDate" ? '' : "startDate";
      this.filters.order = this.filters.orderColumn === "startDate" ? 'asc' : 'desc'
    },

    toggleEndDate() {
      this.filters.orderColumn = this.filters.orderColumn === "endDate" ? '' : "endDate";
    },

    async reloadDataOnFilterChange() {
      await this.fetchTasksAdmin({
        brand: this.filters.brand,
        orderColumn: this.filters.orderColumn,
        order: this.filters.order,
      });
    },

    getTaskId(id) {
      this.taskId = id;

      this.$store.dispatch("fetchTaskByIdAdmin", id);
    },

    createTask() {
      const formData = new FormData();
      formData.append("name", "Новое задание");
      formData.append("startDate", Date.now());
      formData.append("endDate", Date.now());
      formData.append("description", "");
      formData.append("video_link", "");
      formData.append("fee", "0");
      formData.append("logo", "");
      formData.append("unlimited", true);
      formData.append("document", "");

      Vue.http_admin
        .post(`/api/task`, formData)
        .then(() => {
          this.$store.dispatch("fetchTasksAdmin");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateTask() {
      const formData = new FormData();
      formData.append("id", this.taskId);
      formData.append("name", this.taskName || "");
      formData.append("startDate", this.convertedStartDate);
      formData.append("endDate", this.convertedEndDate);
      formData.append("description", this.description || "");
      formData.append(
        "video_link",
        this.video_link
          ? this.video_link
          : ""
      );
      formData.append("fee", this.fee || 0);
      formData.append("unlimited", this.termless ? true : false);
      formData.append("fromOrganizationId", this.selectFrom.id);
      formData.append("forRoleId", this.selectFor.id);
      this.logo != null ? formData.append("logo", this.logo) : null;
      this.file != null ? formData.append("document", this.file) : null;

      Vue.http_admin
        .post(`/api/task`, formData)
        .then(() => {
          this.$store.dispatch("fetchTasksAdmin");
          this.$store.dispatch("fetchTaskByIdAdmin", this.taskId)
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteTask() {
      this.popupIsActive = false;
      this.taskDeletePopup = false;
      Vue.http_admin
        .delete(`/api/task/${this.taskId}`)
        .then(() => {
          this.$store.dispatch("fetchTasksAdmin");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    customFormatter(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    dateChangeStart(date) {
      this.dateStartIsChanged = true;
      this.convertedStartDate = this.convertToMilliseconds(date);
      this.disabledDatesEnd.to = new Date(date);
    },

    dateChangeEnd(date) {
      this.dateEndIsChanged = true;
      this.convertedEndDate = this.convertToMilliseconds(date);
      this.disabledDatesStart.from = new Date(date);
    },

    convertToMilliseconds(val) {
      return moment.utc(val).format("x");
    },

    onChange(image) {
      if (image) {
        this.logo = this.$refs.pictureInput.file;
      }
    },

    removeImage() {
      this.logo = null;
      this.show_upload = false;
    },

    handleFileChange(e) {
      this.file = e.target.files[0];
      const maxSize = 20 * 1024 * 1024; // =20MB

      if (this.file.size > maxSize) {
        this.$toast.error("Файл слишком большой. Максимальный размер 20MB.", {
          timeout: 2000,
        });
        return;
      }
    },

    removeFile() {
      document.querySelector("#input-file").value = "";
      this.file = null;
    },

    bytesToSize(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },

  watch: {
    filters: {
      handler() {
        this.reloadDataOnFilterChange();
      },
      deep: true,
    },
    task() {
      if (this.task) {
        this.taskName = this.task.name;
        this.startDate = this.task.startDate;
        this.endDate = this.task.endDate;
        this.description = this.task.description;
        this.video_link = this.task.video_link;
        this.fee = this.task.fee;
        this.logo = this.task.logo;
        this.file = this.task.file;
        this.convertedStartDate = this.convertToMilliseconds(this.startDate);
        this.convertedEndDate = this.convertToMilliseconds(this.endDate);
        this.logo_url = this.task.logo_url;
        this.order_document_name = this.task.order_document_name;
        this.order_document_url = this.task.order_document_url;
        this.termless = this.task.unlimited;
        this.disabledDatesStart.from = this.endDate
          ? new Date(this.endDate)
          : null;
        this.disabledDatesEnd.to = new Date(this.startDate);
        this.selectFor = this.userRoles.find(
          (x) => x.id === this.task.forRoleId
        );
        this.selectFrom = this.organizations.find(
          (x) => x.id === this.task.fromOrganizationId
        );
      }
    },

    termless() {
      if (this.termless === true) {
        this.disabledDatesStart.from = null;
      } else {
        this.disabledDatesEnd.to = new Date(this.startDate);
      }
    },
  },

  created() {
    this.$store.dispatch("fetchTasksAdmin");
    this.$store.dispatch("fetchUserRolesAdmin");
    this.$store.dispatch("fetchOrganizationsAdmin");
  },
};
</script>

<style lang="scss" scoped>
.sort-icon {
  cursor: pointer;
  opacity: 0.3;
}

.iconActive {
  opacity: 1;
}

.sort-icon:active {
  opacity: 0.5;
  transition: ease-in-out 0.2s;
}

.admin-tasks {
  padding-bottom: 80px;
}

.tasks-top-row {
  align-items: center;
}

.admin-tasks-heading {
  margin-top: 30px;
  margin-bottom: 10px;
  font: 500 30px/1.5 $roboto;
  color: $dark-font;
}

.tasks-result-wrap {
  margin-top: 40px;
}

.tasks-result-list-wrap {
  max-height: 280px;
  overflow-y: auto;
}

.tasks-result-head {
  height: 30px;
  display: flex;
  border-bottom: 1px solid $dark-font;
  margin-bottom: 5px;

  .col {
    font: 400 14px/8px $roboto;
    color: #9e9e9e;
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }

  &__name {
    width: 40%;
  }

  &__reward {
    width: 20%;
  }

  &__date-start {
    width: 20%;
  }

  &__date-end {
    width: 20%;
  }
}

.tasks-wrap {
  max-height: 280px;
  overflow-y: auto;
}

.tasks-result {
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  cursor: pointer;

  &:hover {
    background: lighten(#eeeeee, 4%);
  }

  &__name {
    width: 40%;
  }

  &__reward {
    width: 20%;
  }

  &__date-start {
    width: 20%;
  }

  &__date-end {
    width: 20%;
  }

  .col {
    font: 400 16px/40px $roboto;
    color: $dark-font;
  }
}

.tasks-result.active {
  background: #eeeeee;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
    background: $admin-blue;
  }
}

.admin-tasks {
  position: relative;

  textarea {
    padding: 10px;
    margin-top: 28px;
    margin-bottom: 30px;
    width: 100%;
    min-height: 200px;
    border: 1px solid #9e9e9e;
    color: #9e9e9e;
    font: 400 16px/20px $roboto;
    color: $dark-font;

    &:focus {
      border: 1px solid blue;
    }
  }

  .input-wrap {
    input {
      width: 100%;
      height: 30px;
      border: none;
      border-bottom: 1px solid #9e9e9e;
      color: #9e9e9e;
      font: 400 16px/30px $roboto;
      padding: 0 5px;
      color: $dark-font;

      &:placeholder {
        color: #9e9e9e;
      }

      &:focus {
        border-bottom: 1px solid blue;
      }
    }

    label {
      position: relative;
      display: inline-block;
      width: 100%;
    }

    span {
      padding: 5px;
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.2s;
      transition-timing-function: ease;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      opacity: 0.5;
      color: $dark-font;
      font: 400 16px $roboto;
    }

    input:focus+span,
    input:not(:placeholder-shown)+span {
      opacity: 1;
      transform: translateY(-100%) translateX(-10px);
      font-size: 13px;
    }

    input:focus+span,
    input:not(:-ms-input-placeholder)+span {
      opacity: 1;
      transform: translateY(-100%) translateX(-10px);
      font-size: 13px;
    }

    textarea:focus+span,
    textarea:not(:placeholder-shown)+span {
      opacity: 1;
      transform: translateY(-100%) translateX(-10px);
      font-size: 13px;
    }

    textarea:focus+span,
    textarea:not(:-ms-input-placeholder)+span {
      opacity: 1;
      transform: translateY(-100%) translateX(-10px);
      font-size: 13px;
    }
  }
}

.tasks-select {
  label {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  span {
    padding: 5px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: -50px;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.5;
    color: $dark-font;
    font: 400 16px $roboto;
  }
}

.tasks-select .vs--open+label span,
.tasks-select .active+label span,
input:not(:placeholder-shown)+span {
  opacity: 1;
  transform: translateY(-100%) translateX(-10px);
  font-size: 13px;
}

.tasks-select .vs--open+label span,
.tasks-select .active+label span,
input:not(:-ms-input-placeholder)+span {
  opacity: 1;
  transform: translateY(-100%) translateX(-10px);
  font-size: 13px;
}

.categories-list-btns {
  display: flex;
  margin-top: 20px;

  .add {
    margin-right: auto;
  }

  .remove,
  .save {
    margin-left: auto;
  }

  .categories-list-btn {
    display: block;
    height: 30px;
    padding: 0 15px;
    color: #fff;
    font: 400 14px/30px "Roboto", sans-serif;
    background: #1867c0;
    border: none;
    cursor: pointer;

    &:hover {
      background: lighten($admin-blue, 10%);
    }
  }
}

.admin-tasks-bot {
  margin-top: 80px;

  .col {
    width: 33.33%;
  }
}

.product-hide {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font: 400 16px/25px $roboto;
  color: $dark-font;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 30px;
  margin-top: 30px;
}

.product-hide input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 23px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.product-hide:hover input~.checkmark {
  background-color: #ccc;
}

.product-hide input:checked~.checkmark {
  background-color: $admin-blue;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.product-hide input:checked~.checkmark:after {
  display: block;
}

.product-hide .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.datepicker {
  display: flex;
  align-items: center;

  &__info {
    font: 400 16px $roboto;
    color: $dark-font;
    margin-right: 10px;
  }

  &__calendar {
    border-bottom: 1px solid $dark-font;
    width: 150px;
  }
}

.datepickers {
  display: flex;
}

.endDate {
  margin-left: 20px;
}

.startDate {
  margin-right: 20px;
}

.product-foto {
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #9e9e9e;

  &__item {
    width: 200px;
    height: 200px;
    border: 1px solid #9e9e9e;
    margin: 20px;
    cursor: pointer;
    position: relative;

    &__preview {
      max-width: 198px;
      max-height: 198px;
    }
  }
}

.tasks-select {
  margin-top: 30px;
}

.save-product {
  margin-top: 30px;
  display: block;
  height: 40px;
  padding: 0 20px;
  color: #fff;
  font: 400 16px/30px "Roboto", sans-serif;
  background: #1867c0;
  border: none;
  cursor: pointer;

  &:hover {
    background: lighten($admin-blue, 10%);
  }

  &:disabled {
    background: gray;
    cursor: not-allowed;
  }
}

.upload-file {
  margin-top: 30px;

  // margin-left: auto;
  // width: 300px;
  &__title {
    font: 400 16px/1 $roboto;
    color: $dark-font;
  }

  &__previous {
    margin-top: 5px;
  }
}

.upload-file-block {
  border: 1px solid #9e9e9e;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 60px;
  cursor: pointer;
  padding: 5px 10px;
}

.preview {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  display: none;

  &:hover .image-btn {
    display: block;
  }

  &.visible {
    display: flex;
  }
}

.image-btn {
  border: none;
  font: 400 12px "Roboto", sans-serif;
  color: #fff;
  border-radius: 3px;
  display: none;
  position: absolute;
  bottom: 10px;
  z-index: 1000000;
  width: 75px;
  height: 20px;
  text-align: center;
  margin: 0;
  cursor: pointer;

  &--replace {
    background: $admin-blue;
    left: 15px;
  }
}

.file-btn {
  margin-top: 10px;
  border: none;
  font: 400 12px $roboto;
  color: #fff;
  border-radius: 3px;
  width: 75px;
  height: 20px;
  text-align: center;
  margin: 0;
  cursor: pointer;
}

.file-replace {
  background: $admin-blue !important;
  margin-right: 15px;
}

.file-delete {
  background: $red !important;
}

.file-name {
  min-height: 30px !important;
  border: none !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding: 0px !important;
  resize: none !important;
}

.upload-file-bottom {
  display: flex;
}

.file-type,
.file-size {
  font: 400 13px/1.5 $roboto;
  color: $dark-font;
}

.file-type {
  margin-right: 20px;
}

.file-select {
  position: relative;
}

.file-select input[type="file"] {
  display: none;
}

.file-txt {
  font: 400 16px/1.5 $roboto;
  color: $dark-font;
  margin-bottom: 10px;
  margin-top: 5px;
}

.modal {
  background: #fff;
  box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.2);
  padding: 15px 50px 25px;
  position: fixed;
  display: block;
  width: 750px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  &__title {
    font: 500 20px/1.5 $roboto;
    color: $dark-font;
    text-align: center;
    margin-bottom: 20px;
  }

  &__text {
    font: 400 16px/1.5 $roboto;
    color: $dark-font;
    text-align: center;
  }
}

.modal-btns {
  display: flex;
  margin-top: 20px;

  .decline {
    margin-left: auto;
  }

  .remove {
    margin-right: auto;
  }
}

.modal-btn {
  display: block;
  height: 40px;
  padding: 0 30px;
  color: #fff;
  font: 400 16px/40px "Roboto", sans-serif;
  background: #1867c0;
  border: none;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background: lighten($admin-blue, 10%);
  }
}
</style>
