<template>
    <div class="modal" v-if="popupState.popupIsActive">
        <div class="category-popup" v-if="popupState.userBlockPopup">
            <div class="modal__title">Вы действительно хотите заблокировать пользователя?</div>
            <div class="modal-btns">
                <button class="modal-btn delete" @click="blockUserFn">
                    Заблокировать
                </button>
                <button class="modal-btn decline" @click="closePopupFn
                    ">
                    Отмена
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserPopup',
    props: {
        popupState: {
            type: Object,
            required: true
        },
        selected: {
            type: Number,
            required: true
        },
        comment: {
            type: String,
            required: true
        }
        
    },

    methods: {
        blockUserFn() {
            this.$store.dispatch('changeBlockStatus', {id: this.selected, status: true, comment: this.comment})
            this.$store.dispatch('searchUsers', {reset: true})
            this.$emit('toggle-popup', false);
        },

        closePopupFn() {
            this.$emit('toggle-popup', false);
        }
    }
}
</script>

<style scoped lang="scss">
.modal {
    background: #fff;
    box-shadow: 0 4px 8px 1px rgba(0, 0, 0, .2);
    padding: 15px 50px 25px;
    position: fixed;
    display: block;
    width: 750px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    &__title {
        font: 500 20px/1.5 $roboto;
        color: $dark-font;
        text-align: center;
        margin-bottom: 20px;
    }

    &__text {
        font: 400 16px/1.5 $roboto;
        color: $dark-font;
        text-align: center;
    }
}

.modal-btns {
    display: flex;
    margin-top: 20px;

    .decline {
        margin-left: auto
    }

    .remove {
        margin-right: auto
    }
}

.modal-btn {
    display: block;
    height: 40px;
    padding: 0 30px;
    color: #fff;
    font: 400 16px/40px "Roboto", sans-serif;
    background: #1867C0;
    border: none;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
        background: lighten($admin-blue, 10%)
    }
}
</style>