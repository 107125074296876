<template>
    <div class="users-wrap">
        <UserFilters @download-user="downloadUserData" @update-filter="updateFilter" />
        <UserTable :filters="filters" :selected="selected" @select-user="selectUser" />
        <UserForm :popupState="popupState" :selected="selected" @toggle-popup="togglePopup" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import UserFilters from './components/UserFilters.vue';
import UserTable from './components/UserTable.vue'
import UserForm from './components/UserForm.vue'

export default {
    components: {
        UserFilters,
        UserTable,
        UserForm
    },
    data() {
        return {
            filters: {
                name: '',
                phone: '',
                status: '',
            },

            popupState: {
                userBlockPopup: false,
                popupIsActive: false,
            },

            selected: null,
        }
    },

    computed: {
        ...mapState({
            users: state => state.users.users,
            userToken: state => state.authAdmin.userToken,
            usersDataToDownload: state => state.users.usersDataToDownload,
        })
    },

    watch: {
        userToken() {
            if (this.userToken) {
                localStorage.setItem('jwt', this.userToken)
                let routeData = this.$router.resolve({ path: '/' })
                window.open(routeData.href, '_blank')
            }
        },

        // получение файла выгрузки поставщика
        usersDataToDownload: {
            handler(val) {
                if (val) {
                    this.createLink(
                        val,
                        'application/vnd.ms-excel',
                        `Пользователи ${(new Date()).toISOString()}.xlsx`
                    )
                }
            },
            deep: true
        }
    },

    methods: {
        togglePopup(value) {
            this.popupState.popupIsActive = value;
            this.popupState.userBlockPopup = value;
        },

        updateFilter(key, value) {
            this.filters[key] = value;
        },

        getUserInfo(id) {
            this.$store.dispatch('getUserById', id)
        },

        selectUser(user) {
            this.getUserInfo(user.id)
            this.selected = user.id
        },

        downloadUserData() {
            this.$store.dispatch('getUserData');
        },

        createLink(blob, type, name) {
            const fileURL = URL.createObjectURL(new Blob([blob], { type: type }))
            let fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', name)
            document.body.appendChild(fileLink)
            fileLink.click()
            document.body.removeChild(fileLink);
        }
    },

    created() {
        this.$store.dispatch('searchUsers', {
            reset: true,
        })

        // Получение параметров запроса. В случае если запрос включает user_id открываем карточку пользователя
        let queryParams = new URLSearchParams(window.location.search);
        let params = Object.fromEntries(queryParams.entries());

        if (params.user_id !== undefined) { // Открытие карточки пользователя
            this.getUserInfo(params.user_id);
            this.selected = params.user_id;
        }

    }
}
</script>

<style lang="scss" scoped>
.users-wrap {
    background: #fff
}
</style>
