 export function statusFormatterFn(status) {
            const statusToRuMapping = {
                DATA_UNCONFIRMED: "Не подтверждено",
                DATA_CONFIRMED: "Подтверждено",
                BLOCKED: "Заблокирован",
            };

            const formatted = status
                ? statusToRuMapping[status]
                : "-";

            return formatted;
        }

