import Vue from 'vue'
import router from '../../../services/router/index' 

export default {
  state: {
    adminData: null,
    userToken: null,
    loginError: null,
  },

  mutations: { 
    SET_ADMIN_DATA(state, payload) {
      state.adminData = payload
    },
    SET_USER_TOKEN(state, payload) {
      state.userToken = payload
    },
    LOGIN_ERROR(state, payload) {
      state.loginError = payload
    },
  },

  actions: {
    loginAdmin({ commit },{ login, password }) {
      commit('LOGIN_ERROR', null)
      Vue.http_admin.post('/api/admin/login/', {
        "login": login,
        "password": password
      })
      .then(response => {
        if(response.status === 200) {
          localStorage.setItem('jwt-admin', response.data.token.jwt)
          router.push({name: "adminUsers"})
          commit('SET_ADMIN_DATA', response)
        }
      })
      .catch(error => {
        commit('LOGIN_ERROR', error)

        if (error?.response && error?.response?.status === 500) {
          this.$toast("Ой, что-то пошло не так", {
            timeout: 5000,
            type: "error",
          })
        }
      })
    },

    loginAdminAsUser({commit}, id) {
      Vue.http_admin.get(`/api/user/${id}/token`

      )
      .then(response => {
        commit('SET_USER_TOKEN', response.data.token.jwt)
      })
      .catch(error => {
        if (error?.response && error?.response?.status === 500) {
          this.$toast("Ой, что-то пошло не так", {
            timeout: 5000,
            type: "error",
          })
        }
      });
    }
  }
}