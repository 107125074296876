<template>
    <div>
        <h1 class="heading">История изменений</h1>
        <ChangesHistoryFilters :filters="filters" @update-filter="updateFilter"  />
        <ChangesHistoryTable :filters="filters" :selectedRow="selectedRow" @select-item="selectItem" />
        <ChangesHistoryItem  :selectedRow="selectedRow" />
    </div>
</template>

<script>
import ChangesHistoryFilters from './components/ChangesHistoryFilters.vue';
import ChangesHistoryTable from './components/ChangesHistoryTable.vue';
import ChangesHistoryItem from './components/ChangesHistoryItem.vue';
import moment from'moment';

export default {
    components: {
        ChangesHistoryFilters,
        ChangesHistoryTable,
        ChangesHistoryItem,
    },

    data() {
        return {
            selectedRow: null,
            filters: {
                startDate: moment().subtract(1, "months").toDate(),
                endDate: moment().toDate(),
                fullName: '',
            },
        }
    },

    methods: {
        selectItem(item) {
            this.selectedRow = item;
        },
        updateFilter(key, value) {
            this.filters[key] = value;
        },
    }
}
</script>


<style lang="scss" scoped>
.heading {
    margin-top: 30px;
    margin-bottom: 10px;
    font: 700 32px/1.5 $roboto;
    color: $dark-font;
}
</style>
