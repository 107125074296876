<template>
  <div class="filters">
    <div class="filter-wrapper">
       <div class="input-wrap">
      <div class="search-container">
        <label>
          <input type="text" placeholder=" " v-model="brand" @keyup="debouncedSearchByBrand"
            @keyup.enter="searchByBrand">
          <span>Название бренда</span>
        </label>
      </div>
    </div>
  </div>

  </div>
</template>


<script>
import { debounce } from "../../../../utils";

export default {
  data() {
    return {
      brand: null,
      debouncedSearchByBrand: null,
    };
  },

  props: {
    filters: Object,
  },

  created() {
    this.debouncedSearchByBrand = debounce(this.searchByBrand, 500);
  },

  methods: {
    searchByBrand() {
      this.$emit('update-filter', 'brand', this.brand);
    },
  }
};
</script>


<style lang="scss" scoped>
.toggle-sort {
    margin-top: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font: 400 15px/30px $roboto;
    padding: 5px;
}
.filters {
    margin-top: 5px;
    margin-bottom: 40px;
}
.filter-wrapper {
  display: inline-flex;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  margin-top: 20px;
}

.input-wrap {
  input {
    height: 30px;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    color: #9e9e9e;
    font: 400 16px/30px $roboto;
    padding: 0 5px;
    color: $dark-font;

    &:placeholder {
      color: #9e9e9e;
    }

    &:focus {
      border-bottom: 1px solid blue;
    }
  }

  label {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  span {
    padding: 5px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.5;
    color: $dark-font;
    font: 400 16px $roboto;
  }

  input:focus+span,
  input:not(:placeholder-shown)+span {
    opacity: 1;
    transform: translateY(-100%) translateX(-10px);
    font-size: 13px
  }

  input:focus+span,
  input:not(:-ms-input-placeholder)+span {
    opacity: 1;
    transform: translateY(-100%) translateX(-10px);
    font-size: 13px;
  }
}

</style>