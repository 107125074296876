<template>
    <div class="orders-list-wrap">
        <div v-if="messageIsActive" class="orders-list-wrap__message"
            :class="{ ' wrong': messageOrder === 'Данные не сохранились' }">
            {{ messageOrder }}
        </div>

        <div class="orders-list-head">
            <div class="col" v-for="(field, id) in tableFields" :key="id" :style="field.style">
                {{ field.name }}
            </div>
        </div>

        <div class="orders-result-wrap">
            <div class="list-wrap" v-if="orders">
                <order v-for="(order) in orders" :order="order" :status="status" :key="order.id"
                    :class="{ active: order.id == selected }" @savedString="messageShow($event)"
                    @get-order="getOrderData" />

                <infinite-loading @infinite="loadMoreData">
                    <div slot="no-more" class="no-res"> </div>
                    <div slot="no-results" class="no-res"> </div>
                </infinite-loading>
            </div>

        </div>
    </div>
</template>

<script>
import Order from "./Order.vue";
import { mapState, mapActions } from "vuex";
import { fields } from "../utils";
import { voidDateTime } from "../../../../utils";

export default {
    components: {
        Order,
    },

    props: {
        status: Object,
        messageIsActive: Boolean,
        filters: Object,
        currentOrder: Object,
    },

    data() {
        return {
            selected: undefined,
            tableFields: fields,
            messageOrder: "",
            userId: null,
        }
    },

    computed: {
        ...mapState({
            orders: (state) => state.orders.orders,
        }),
    },

    watch: {
        filters: {
            handler() {
                this.reloadDataOnFilterChange();
            },
            deep: true,
        },
    },

    async created() {
        this.getOrders({
            reset: true, startDate: voidDateTime(this.filters.startDate),
            completeDate: voidDateTime(this.filters.endDate),
        });
    },

    methods: {
        getOrderData(payload) {
            this.getOrderId(payload.id, payload.user.id);
            this.$emit("save-order", payload);
            this.selected = payload.id;
        },

        reloadDataOnFilterChange() {
            this.getOrders({
                reset: true,
                startDate: voidDateTime(this.filters.startDate),
                completeDate: voidDateTime(this.filters.endDate),
                recipient: this.filters.recipient,
                status: this.filters.status,
                shipper: this.filters.shipper
            });
        },

        ...mapActions(['getOrders']),

        async loadMoreData($state) {
            try {
                const dataLoaded = await this.getOrders({
                    startDate: voidDateTime(this.filters.startDate),
                    completeDate: voidDateTime(this.filters.endDate),
                    recipient: this.filters.recipient,
                    status: this.filters.status,
                    shipper: this.filters.shipper
                });
                if (dataLoaded.length === 0) {
                    $state.complete();
                } else {
                    $state.loaded();
                }
            } catch (error) {
                $state.complete();
                console.error("Ошибка загрузки данных:", error);
            }
        },

        getOrderId(orderId, userId) {
            this.orderId = orderId;
            this.userId = userId;
            this.$store.dispatch("getOrder", orderId);
            this.$store.dispatch("getOrderAct", orderId);
            this.$store.dispatch("getUserById", userId);
        },

        messageShow(message) {
            this.messageOrder = "Данные сохранены";
            if (!message) {
                this.messageOrder = "Данные не сохранились";
            }
            this.messageIsActive = true;
            setTimeout(() => {
                this.messageIsActive = false;
            }, 800);
        },
    }
}
</script>

<style lang="scss" scoped>
.no-res {
    margin-top: 20px;
    opacity: 0.5;
    font: 300 10px/1.5 $roboto;
}

.orders-list-wrap {
    overflow-x: scroll;
    width: fit-content;

    &__message {
        position: absolute;
        left: 45%;
        margin-top: -25px;
        color: $green;
        font-size: larger;

        &.wrong {
            color: $admin-red-darker;
        }
    }

    .col {
        flex-shrink: 0;
    }
}

.orders-list-head {
    max-height: 65px;
    display: flex;
    border-bottom: 1px solid $gray-blue;
    width: fit-content;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 10;

    .col {
        width: 20%;
        font: 400 14px/23px $roboto;
        color: $gray-mid-dark;

        &--15 {
            width: 15%;
        }

        &--25 {
            width: 25%;
        }

        &--50 {
            width: 25%;
        }
    }
}

.orders-result-wrap {
    position: relative;
    max-height: 350px;
}
</style>