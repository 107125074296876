import Vue from "vue";

export default {
  state: {
    adminMessages: [],
    offset: 0,
    limit: 10,
  },

  mutations: {
    SET_ADMIN_MESSAGES(state, { messages, reset }) {
      if (reset) {
        state.adminMessages = messages;
      } else {
        state.adminMessages = state.adminMessages.concat(messages);
      }
    },
    SET_OFFSET(state, offset) {
      state.offset = offset;
    },
  },

  actions: {
    getAllAdminMessages({ commit, state }, { reset = false } = {}) {
      const offset = reset ? 0 : state.offset;
      Vue.http_admin
        .get(`api/admin/notifications?limit=${state.limit}&offset=${offset}`)
        .then((response) => {
          commit("SET_ADMIN_MESSAGES", { messages: response.data, reset });
          commit("SET_OFFSET", offset + 1);
        })
        .catch((error) => {
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            });
          }
          console.log(error);
        });
    },
    postAdminMessage({ dispatch }, { payload, onSuccess, onError }) {
      // отправить новое сообщение
      Vue.http_admin
        .post(`api/admin/notifications/send`, payload).then(onSuccess).catch(
          (error) => {
            onError && onError();
            if (error?.response && error?.response?.status === 500) {
              this.$toast("Ой, что-то пошло не так", {
                timeout: 5000,
                type: "error",
              });
            }
          }
        )
      dispatch("getAllAdminMessages", { reset: true });
    },
    saveAdminMessageAsDraft({ dispatch }, { payload, onSuccess, onError }) {
      // сохранить как черновик
      Vue.http_admin.post(`api/admin/notifications`, payload).then(onSuccess).catch((error) => {
        onError && onError()
        if (error?.response && error?.response?.status === 500) {
          this.$toast("Ой, что-то пошло не так", {
            timeout: 5000,
            type: "error",
          });
        }
      })
      dispatch("getAllAdminMessages", { reset: true });
    },
    saveAdminMessageDraft({ dispatch }, { payload, id, onSuccess, onError }) {
      // сохранить черновик
      Vue.http_admin
        .patch(`api/admin/notifications/${id}`, payload)
        .then(onSuccess)
        .catch((error) => {
          onError && onError()
          if (error?.response && error?.response?.status === 500) {
            this.$toast("Ой, что-то пошло не так", {
              timeout: 5000,
              type: "error",
            })
          }
        })
      dispatch("getAllAdminMessages", { reset: true });
    },
    deleteAdminMessage({ dispatch }, { id }) {
      // удалить сообщение
      Vue.http_admin.delete(`api/admin/notifications/${id}`).catch((error) => {
        if (error?.response && error?.response?.status === 500) {
          this.$toast("Ой, что-то пошло не так", {
            timeout: 5000,
            type: "error",
          })
        }
      });
      dispatch("getAllAdminMessages", { reset: true });
    },
  },
};
