<template>
  <div class="orders admin-catalog">
    <div class="modal" v-if="popupIsActive">
      <div class="category-popup" v-if="categoryPopup">
        <div class="modal__title">Вы действительно хотите удалить категорию «{{ categoryName }}»?</div>
        <div class="modal__text">Это действие приведет к удалению всех товаров, относящихся к данной категории.</div>
        <div class="modal-btns">
          <button class="modal-btn delete" @click="deleteCategory">Удалить</button>
          <button class="modal-btn decline" @click="
            popupIsActive = false;
          categoryPopup = false;
          addCategoryIsActive = false;
          selected = undefined">
            Отмена
          </button>
        </div>
      </div>

      <div class="category-popup" v-if="productDeletePopup">
        <div class="modal__title">Вы действительно хотите удалить товар «{{ productName }}»?</div>
        <div class="modal__text">Это действие приведет к удалению информации о товаре.</div>
        <div class="modal-btns">
          <button class="modal-btn delete" @click="deleteProduct(); popupIsActive = false; productDeletePopup = false;">
            Удалить
          </button>
          <button class="modal-btn decline" @click="
            popupIsActive = false;
          productDeletePopup = false;
          ">
            Отмена
          </button>
        </div>
      </div>

      <div class="product-popup" v-if="productPopup">
        <textarea v-model="productDesc"></textarea>
        <div class="modal-btns">
          <button class="modal-btn delete" @click="popupIsActive = false; productPopup = false;">
            Сохранить
          </button>
          <button class="modal-btn decline" @click="
            popupIsActive = false;
          productPopup = false;
          ">
            Отмена
          </button>
        </div>
      </div>
    </div>

    <h1 class="catalog-heading">Категории</h1>
    <div class="categories-wrap">
      <div class="categories-left">
        <div class="categories-head">
          <div class="col categories-head__name">Категория</div>
        </div>

        <div class="categories-list-wrap">
          <div class="list-wrap" v-if="customCategories">
            <div class="categories-list" v-for="(category, idx) in customCategories.data" :key="idx"
              :class="{ active: category.id == selected }"
              @click="selectCategory(category.id, category.name); selected = category.id">
              <div class="col categories-list__name">{{ category.name }}</div>
            </div>

            <div class="categories-list active" v-if="addCategoryIsActive">
              <div class="col categories-list__name">Новая категория</div>
            </div>
          </div>
        </div>
        <div class="categories-list-btns">
          <button class="categories-list-btn add" @click="addCategory">Добавить</button>
          <button class="categories-list-btn add" @click="loadEd2">Загрузить</button>
          <button v-if="selected != undefined || addCategoryIsActive == true" class="categories-list-btn remove"
            @click="openCategoryPopup">Удалить</button>
        </div>
      </div>
      <div class="categories-right" v-if="selected != undefined || addCategoryIsActive == true">
        <div class="update-category">
          <div class="input-wrap">
            <label>
              <input class="user-edit__input" type="text" placeholder=" " v-model="categoryName">
              <span>Название категории</span>
            </label>
          </div>
        </div>

        <div class="categories-list-btns">
          <button @click="changeCategoryName" class="categories-list-btn save">Сохранить</button>
        </div>
      </div>
    </div>


    <div class="catalog-bottom-wrap" v-if="selected != undefined">
      <div class="search">
        <div class="input-wrap">
          <div class="search-container">
            <label>
              <input class="search__input by-descr" type="text" placeholder=" " v-model="name" @keyup="debouncedSearchByName"
                @keyup.enter="searchByName">
              <span>Наименование товара</span>
            </label>
          </div>
        </div>

      </div>

      <h2 class="catalog-heading product-heading">Товары</h2>
      <div class="categories-head">
        <div class="col categories-head__name">Название товара</div>
      </div>
      <div class="categories-list-wrap products-list">
        <div class="list-wrap" v-if="goods">
          <div class="categories-list" v-for="(item, idx) in goods" :key="idx"
            :class="{ active: item.id == selectedProduct }" @click="getGoodById(item.id); selectedProduct = item.id">
            <div class="col categories-list__name">
              <pre>{{ item.name }}</pre>
            </div>
          </div>
        </div>
      </div>

      <div class="categories-list-btns">
        <button class="categories-list-btn add" @click="addProduct">Добавить</button>
        <button class="categories-list-btn remove" @click="popupIsActive = true; productDeletePopup = true"
          v-if="selectedProduct != undefined">Удалить
        </button>
      </div>

      <div class="product-detailed-wrap" v-if="selectedProduct != undefined">
        <div class="categories-wrap product-detailed">
          <div class="categories-left">
            <div class="input-wrap">
              <label>
                <input class="user-edit__input" type="text" placeholder=" " v-model="productName">
                <span>Название товара</span>
              </label>
            </div>

            <div class="input-wrap">
              <label>
                <input class="user-edit__input" type="text" placeholder=" " v-model="productShortDesc">
                <span>Краткое описание</span>
              </label>
            </div>

            <div class="input-wrap">
              <label>
                <input class="user-edit__input" type="text" placeholder=" " v-model="productWeight">
                <span>Вес товара для службы доставки (в граммах)</span>
              </label>
            </div>
          </div>
          <div class="categories-right products-right">
            <label class="product-hide">Скрыть товар
              <input type="checkbox" v-model="productHided">
              <span class="checkmark"></span>
            </label>

            <div class="input-wrap">
              <label>
                <input class="user-edit__input" type="text" placeholder=" " v-model="productPrice">
                <span>Цена</span>
              </label>
            </div>
            <div>
              <button class="categories-list-btn add" @click="popupIsActive = true; productPopup = true">
                Редактировать полное описание
              </button>
            </div>
          </div>
        </div>

        <h2 class="catalog-heading product-heading">Фотографии товара</h2>
        <div class="product-foto">
          <div class="product-foto__item" v-for="(item, idx) in picturesLinks.length" :key="item">

            <div class="preview visible">
              <img class="product-foto__item__preview" :src="picturesLinks[idx]">
            </div>
          </div>

          <div class="product-foto__item" v-for="(item, idx) in pictures.length + 1" :key="idx" @click="getIndex(idx)">
            <picture-input ref="pictureInput" accept="image/jpeg,image/png" size="10" button-class="btn"
              :alertOnError="false" :removable="true" :custom-strings="{
                drag: 'Вставить изображение',
                change: 'Изменить',
                remove: 'Удалить',
              }" @change="onChange" @remove="removeImage()">
            </picture-input>
          </div>
        </div>

        <button class="save-product" @click="updateProduct">
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import PictureInput from 'vue-picture-input'
import { debounce } from "../../utils/index";

export default {
  data() {
    return {
      name: '',

      selected: undefined,
      categoryName: null,
      categoryId: null,
      addCategoryIsActive: false,
      categoryPopup: false,
      productPopup: false,
      productDeletePopup: false,
      popupIsActive: false,

      selectedProduct: undefined,
      productId: null,
      pictures: [],
      picturesLinks: [],
      imageIndex: 0,
      productName: null,
      productShortDesc: null,
      productDesc: null,
      productWeight: null,
      productHided: false,
      productPrice: null,
    }
  },

  computed: {
    ...mapState({
      customCategories: state => state.catalog.customCategories,
      goods: state => state.catalog.goods,
      good: state => state.catalog.good
    }),
  },

  methods: {
    selectCategory(categoryId, categoryName) {
      this.categoryId = categoryId
      this.categoryName = categoryName
      this.addCategoryIsActive = false
      this.selectedProduct = undefined
      this.$store.dispatch('getGoods', { id: this.categoryId })
    },

   searchByName() {
      this.$store.dispatch('getGoods', { id: this.categoryId, name: this.name })
    },

    changeCategoryName() {
      this.$store.dispatch('upsetCustomCategory', { categoryId: this.categoryId, categoryName: this.categoryName })
    },

    loadEd2() {
      this.$store.dispatch('loadE2')
    },

    addCategory() {
      this.selected = undefined
      this.addCategoryIsActive = true
      this.categoryName = 'Новая категория'
      this.categoryId = ''
      if (this.categoryName != "Новая категория") {
        this.$store.dispatch('upsetCustomCategory', { categoryName: this.categoryName })
      }
    },

    deleteCategory() {
      this.categoryPopup = false
      this.popupIsActive = false
      Vue.http_admin.delete(
        `api/customCategory/${this.categoryId}`
      )

        .then(() => {
          this.$store.dispatch('getCustomCategories')
          this.popupIsActive = false
          this.addCategoryIsActive = false;
          this.selected = undefined
        })

        .catch(error => {
          console.log(error);
        });
    },

    openCategoryPopup() {
      this.categoryPopup = true
      if (this.addCategoryIsActive === true) {
        this.addCategoryIsActive = false
      }
      else {
        this.popupIsActive = true
      }
    },

    getIndex(idx) {
      this.imageIndex = idx
    },

    onChange(image) {
      if (image) {
        setTimeout(() => {
          if (this.pictures.indexOf(this.$refs.pictureInput[this.imageIndex].file !== -1)) {
            this.pictures.splice(this.imageIndex, 1, { file: this.$refs.pictureInput[this.imageIndex].file, name: this.$refs.pictureInput[this.imageIndex].fileName })
          }
          else {
            this.pictures.splice(this.imageIndex, 0, { file: this.$refs.pictureInput[this.imageIndex].file, name: this.$refs.pictureInput[this.imageIndex].fileName })
          }
        }, 1000)
      }
    },

    removeImage() {
      setTimeout(() => {
        if (this.pictures.length === 1) {
          this.pictures = []
        }
        else {
          this.pictures.splice(this.imageIndex, 1)
        }
      }, 1000)
    },

    getGoodById(id) {
      this.$store.dispatch('getGoodsById', id)
      this.productId = id
    },

    addProduct() {
      this.$store.dispatch('createGood', this.categoryId)
    },

    updateProduct() {
      const formData = new FormData()
      formData.append("id", this.productId)
      formData.append("customCategoryId", this.categoryId)
      formData.append("name", this.productName)
      formData.append("short_description", this.productShortDesc)
      formData.append("description", this.productDesc)
      formData.append("weight", this.productWeight)
      formData.append("price", this.productPrice)
      formData.append("hided", this.productHided)
      this.pictures.forEach((item) => {
        formData.append("pictures", item.file, item.name)
      })

      this.$store.dispatch('updateGoodsById', { productData: formData })
    },


    deleteProduct() {
      Vue.http_admin.delete(
        `api/customGoods/${this.productId}`
      )

        .then(() => {
          this.$store.dispatch('getGoods', { id: this.categoryId })
        })

        .catch(error => {
          console.log(error);
        });
    },
  },

  watch: {
     name() {
      this.debouncedSearchByName();
    },
    good() {
      if (this.good) {
        this.productName = this.good.name
        this.productShortDesc = this.good.short_description
        this.productDesc = this.good.description
        this.productWeight = this.good.weight
        this.productHided = this.good.hided
        this.productPrice = this.good.price
        this.picturesLinks = this.good.picture ? this.good.picture : []
      }
    },
  },

  created() {
    this.$store.dispatch('getCustomCategories')

    this.debouncedSearchByName = debounce(this.searchByName, 300);
  },

  components: {
    PictureInput
  },
}
</script>

<style lang="scss" scoped>

.search {
  margin-top: 50px;
  width: 30%;
}
.admin-catalog {
  padding-bottom: 80px;
}

.orders-top-row {
  align-items: center;
}

.catalog-heading {
  margin-top: 30px;
  margin-bottom: 10px;
  font: 500 30px/1.5 $roboto;
  color: $dark-font;
}

.modal {
  background: #fff;
  box-shadow: 0 4px 8px 1px rgba(0, 0, 0, .2);
  padding: 15px 50px 25px;
  position: fixed;
  display: block;
  width: 750px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  &__title {
    font: 500 20px/1.5 $roboto;
    color: $dark-font;
    text-align: center;
    margin-bottom: 20px;
  }

  &__text {
    font: 400 16px/1.5 $roboto;
    color: $dark-font;
    text-align: center;
  }
}

.modal-btns {
  display: flex;
  margin-top: 20px;

  .decline {
    margin-left: auto
  }

  .remove {
    margin-right: auto
  }
}

.modal-btn {
  display: block;
  height: 40px;
  padding: 0 30px;
  color: #fff;
  font: 400 16px/40px "Roboto", sans-serif;
  background: #1867C0;
  border: none;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background: lighten($admin-blue, 10%)
  }
}

.orders {
  position: relative;

  textarea {
    margin-top: 20px;
    width: 100%;
    min-height: 200px;
    border: 1px solid #9e9e9e;
    color: #9e9e9e;
    font: 400 16px/30px $roboto;
    padding: 0 5px;
    color: $dark-font;

    &:focus {
      border: 1px solid blue;
    }
  }

  .input-wrap {
    input {
      width: 100%;
      height: 30px;
      border: none;
      border-bottom: 1px solid #9e9e9e;
      color: #9e9e9e;
      font: 400 16px/30px $roboto;
      padding: 0 5px;
      color: $dark-font;

      &:placeholder {
        color: #9e9e9e;
      }

      &:focus {
        border-bottom: 1px solid blue;
      }
    }

    label {
      position: relative;
      display: inline-block;
      width: 100%;
    }

    span {
      padding: 5px;
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.2s;
      transition-timing-function: ease;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      opacity: 0.5;
      color: $dark-font;
      font: 400 16px $roboto;
    }

    input:focus+span,
    input:not(:placeholder-shown)+span {
      opacity: 1;
      transform: translateY(-100%) translateX(-10px);
      font-size: 13px
    }

    input:focus+span,
    input:not(:-ms-input-placeholder)+span {
      opacity: 1;
      transform: translateY(-100%) translateX(-10px);
      font-size: 13px;
    }
  }
}

.add-price {
  width: 300px;
}

.categories-wrap {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.categories-left {
  width: 580px;
  margin-right: 100px;
}

.categories-right {
  flex-grow: 1
}

.categories-head {
  height: 30px;
  display: flex;
  border-bottom: 1px solid $dark-font;

  .col {
    width: 100%;
    font: 400 14px/8px $roboto;
    color: #9e9e9e;
  }
}

.categories-list-wrap {
  max-height: 280px;
  overflow-y: auto;
}

.products-list {
  border-bottom: 1px solid #9e9e9e;
}

.categories-list {
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  cursor: pointer;

  &:hover {
    background: lighten(#eeeeee, 4%);
  }


  .col {
    width: 100%;
    font: 400 16px/40px $roboto;
    color: $dark-font;
  }
}

.categories-list.active {
  background: #eeeeee;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
    background: $admin-blue;
  }
}

.categories-list-btns {
  display: flex;
  margin-top: 20px;

  .add {
    margin-right: auto
  }

  .remove,
  .save {
    margin-left: auto
  }
}

.categories-list-btn {
  display: block;
  height: 30px;
  padding: 0 15px;
  color: #fff;
  font: 400 14px/8px "Roboto", sans-serif;
  background: #1867C0;
  border: none;
  cursor: pointer;

  &:hover {
    background: lighten($admin-blue, 10%)
  }
}

.product-heading {
  margin-top: 40px;
}

.product-detailed .input-wrap {
  margin-bottom: 40px;
}

.products-right {
  width: 330px;
}

.product-hide {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font: 400 16px/25px $roboto;
  color: $dark-font;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 40px;
}

.product-hide input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.product-hide:hover input~.checkmark {
  background-color: #ccc;
}

.product-hide input:checked~.checkmark {
  background-color: $admin-blue;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.product-hide input:checked~.checkmark:after {
  display: block;
}

.product-hide .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.product-foto {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #9e9e9e;
  border-bottom: 1px solid #9e9e9e;

  &__item {
    width: 200px;
    height: 200px;
    border: 1px solid #9e9e9e;
    margin: 20px;
    cursor: pointer;
    position: relative;

    &__preview {
      max-width: 198px;
      max-height: 198px;
    }
  }
}

.save-product {
  margin-top: 30px;
  display: block;
  height: 40px;
  padding: 0 20px;
  color: #fff;
  font: 400 16px/8px "Roboto", sans-serif;
  background: #1867C0;
  border: none;
  cursor: pointer;

  &:hover {
    background: lighten($admin-blue, 10%)
  }
}

.products-list {
  min-height: 40px;
}

.preview {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  display: none;

  &:hover .image-btn {
    display: block;
  }

  &.visible {
    display: flex;
  }
}

.image-btn {
  border: none;
  font: 400 12px "Roboto", sans-serif;
  color: #fff;
  border-radius: 3px;
  display: none;
  position: absolute;
  bottom: 10px;
  z-index: 1000000;
  width: 75px;
  height: 20px;
  text-align: center;
  margin: 0;
  cursor: pointer;

  &--replace {
    background: $admin-blue;
    left: 15px;
  }
}
</style>
