<template>
  <div class="pwd-wrap">
    <div class="pwd-top">
      <h1 class="pwd-heading">Смена пароля администратора</h1>
      <div class="pwd-change">
        <div class="input-wrap">
          <label>
            <input 
              class="input"
              type="password" 
              placeholder=" "
              v-model="oldPwd" 
            >
            <span>Старый пароль</span>
          </label>
        </div>

        <div class="input-wrap">
          <label>
            <input 
              class="input"
              type="password" 
              placeholder=" "
              v-model="newPwd" 
            >
            <span>Новый пароль</span>
          </label>
        </div>

        <div class="input-wrap">
          <label>
            <input 
              class="input"
              type="password" 
              placeholder=" "
              v-model="repeatPwd" 
            >
            <span>Повторите пароль</span>
          </label>
        </div>

        <button class="btn">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {  
  data() {
    return {
      oldPwd: '',
      newPwd: '',
      repeatPwd: '',
    }
  }
}

</script>

<style lang="scss" scoped>
  .pwd-wrap {
    background: #fff
  }

  .pwd-top {
    display: flex;
    margin-top: 40px;
    flex-direction: column;
  }

  .pwd-heading {
    font: 400 36px/1.5 $roboto;
    color: $dark-font;
    margin-bottom: 20px;
  }

  .pwd-change {
    width: 300px;

    .input {
      width: 100%;
      height: 30px;
      border: none;
      border-bottom: 1px solid #9e9e9e;
      color: #9e9e9e;
      font: 400 16px/30px $roboto;
      padding: 0 5px;
      color: $dark-font;

      &:placeholder {
        color: #9e9e9e;
      }

      &:focus {
        border-bottom: 1px solid blue;
      }
    }

    label {
      position:relative;
      display:inline-block;
      width: 100%;
    }
      
    span {
      padding:5px;
      pointer-events: none;
      position:absolute;
      left:0;
      top:0;
      transition: 0.2s;
      transition-timing-function: ease;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      opacity:0.5;
      color: $dark-font;
      font: 400 16px $roboto;
    }

    input:focus + span, input:not(:placeholder-shown) + span {
      opacity:1;
      transform: translateY(-100%) translateX(-10px);
      font-size: 13px;
    }

    input:focus + span, input:not(:-ms-input-placeholder) + span {
      opacity:1;
      transform:  translateY(-100%) translateX(-10px);
      font-size: 13px;
    }
  }

  .input-wrap {
    margin-bottom: 20px;
  }

  .btn {
    display: block;
    background: #1867C0;
    font: 400 16px/40px "Roboto", sans-serif;
    color: #fff;
    padding: 0 15px;
    border: none;
    cursor: pointer;
    &:hover {
      background: darken($admin-blue, 10%)
    }
  }

</style>