<template>
  <div class="orders-bot" v-if="userData">
    <div class="orders-head">
      <h2 class="orders-head__title">Заказ {{ order.id }}</h2>

      <div class="orders-head__btns">
        <button class="button button--save" @click="accordingActionFn">
          Сохранить заказ
        </button>

        <button @click="downloadPdfFn" class="button">Выгрузить акт</button>
      </div>
    </div>

    <div class="status">
      <div class="status-select">
        <v-select @input="statusChangeFn" class="admin-select" :options="options" label="name" v-model="localStatus">
          <template v-slot:selection="option">
            <img class="option__desc" :src="option.image" />
            {{ option.name }}
          </template>

          <template v-slot:option="option">
            <img class="option__desc" :src="option.image" />
            {{ option.name }}
          </template>
          <template v-slot:selected-option="option">
            <img class="option__desc" :src="option.image" />
            {{ option.name }}
          </template>
          <template v-slot:no-options>
            <span>Выберете из списка</span>
          </template>
        </v-select>
      </div>
    </div>

    <user-order :userData="userData" v-if="userData" />
    <div class="order-result-wrap">
      <div class="order-list-head">
        <div class="col order-list-head__prod">Товар</div>
        <div class="col order-list-head__price">Цена</div>
        <div class="col order-list-head__num">Количество</div>
        <div class="col order-list-head__resprice">Стоимость</div>
      </div>

      <div class="order-result-wrap" v-if="order && order.purchases">
        <div class="order-list" v-for="item in order.purchases.goods ? order.purchases.goods : []" :key="item.id">
          <div class="col order-list__prod">{{ item.name }}</div>
          <div class="col order-list__price">{{ item.price }}</div>
          <div class="col order-list__num">{{ item.count }}</div>
          <div class="col order-list__resprice">
            {{ item.count * item.price }}
          </div>
        </div>
        <div class="order-list" v-for="item in order.purchases && order.purchases.customGoods
                    ? order.purchases.customGoods
                    : []" :key="item.id">
          <div class="col order-list__prod">{{ item.name }}</div>
          <div class="col order-list__price">{{ item.price }}</div>
          <div class="col order-list__num">{{ item.count }}</div>
          <div class="col order-list__resprice">
            {{ item.count * item.price }}
          </div>
        </div>
      </div>
      <div class="order-total" v-if="order">
        <div class="order-total__title">ИТОГО</div>
        <div class="order-total__val">{{ order.purchases.totalPrice }}</div>
      </div>
      <div class="order-comment" v-if="order">
        <div class="order-total__title">Комментарий к заказу</div>
        <div class="order-total__comment">{{ order.comment }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { options } from "../utils";
import UserOrder from "./UserOrder.vue";

export default {
  components: {
    UserOrder
  },
  data() {
    return {
      options,
      localStatus: this.status
    }
  },
  props: {
    userData: Object,
    order: Object,
    status: Object
  },
  watch: {
    status(newVal) {
      this.localStatus = newVal;
    },
  },
    methods: {
        accordingActionFn() {
            this.$emit('according-action');
        },
        downloadPdfFn() {
            this.$emit('download-pdf');
        },

        statusChangeFn(value) {
          this.localStatus = value;
          this.$emit('change-status', value);
    },
        
    }
};
</script>

<style lang="scss" scoped>
.order-list {
  display: flex;
  border-bottom: 1px solid $gray-light;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: lighten($gray-light, 4%);
    width: 100%;
  }

  &__prod {
    width: 40%;
  }

  &__price {
    width: 20%;
  }

  &__num {
    width: 20%;
  }

  &__resprice {
    width: 20%;
  }

  .col {
    font: 400 16px/1 $roboto;
    color: $dark-font;
  }
}

.order-list.active {
  background-color: $gray-light;
  position: relative;
  width: 100%;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
    background-color: $admin-blue;
  }
}

.order-total {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid $dark-font;

  &__title {
    font: 600 14px/40px $roboto;
    color: $dark-font;
    margin-right: auto;
    padding: 0 15px;
  }

  &__val {
    font: 700 16px/40px $roboto;
    color: $dark-font;
    margin-left: auto;
    width: 20%;
    text-align: left;
    padding-left: 30px;
    padding: 0 15px;
  }

  &__comment {
    font: 400 16px/1 $roboto;
    width: 100%;
    text-align: left;
    padding-left: 30px;
    padding: 0 15px;
  }
}

.orders-bot {
  margin-top: 60px;
  padding-bottom: 100px;
}

.orders-head {
  display: flex;
  flex-direction: row;
  gap: 100px;
  margin-bottom: 20px;

  &__title {
    font: 400 24px/32px $roboto;
  }

  &__btns {
    display: flex;
    gap: 10px;
  }
}

.button {
  background-color: $admin-blue;
  font: 400 16px/40px $roboto;
  color: $white;
  padding: 0 15px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: darken($admin-blue, 10%);
  }

  &--save {
    margin-top: 0;
  }
}

.status {
  display: flex;
  position: relative;
  width: 31%;
  justify-content: space-between;

  .message {
    position: absolute;
    width: 200px;
    color: $toxic-green;
    left: calc(100% + 10px);
  }
}

.status-select {
  width: 200px;

  &__label {
    font: 400 13px/1 "Roboto";
    color: $gray-mid-dark;
  }
}

.option__desc {
  display: inline-flex;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 10px;

  &_selected {
    display: inline-flex;
    align-items: center;
  }
}

.order-result-wrap {
  width: 790px;
}

.order-list-head {
  height: 30px;
  display: flex;
  border-bottom: 1px solid $dark-font;
  align-items: center;

  .col {
    font: 400 14px/30px $roboto;
    color: $gray-mid-dark;
  }

  &__prod {
    width: 40%;
  }

  &__price {
    width: 20%;
  }

  &__num {
    width: 20%;
  }

  &__resprice {
    width: 20%;
  }
}


.orders-list {
  min-height: 40px;
  display: flex;
  border-bottom: 1px solid $gray-light;
  cursor: pointer;
  width: fit-content;

  &:hover {
    background-color: lighten($gray-light, 4%);
  }

  .col {
    width: 20%;
    font: 400 14px/40px $roboto;
    color: $dark-font;

    &--5 {
      width: 5%;
    }

    &--10 {
      width: 10%;
    }

    &--15 {
      width: 15%;
    }

    &--25 {
      width: 25%;
    }

    &--50 {
      width: 50%;
    }
  }
}


.orders-list.active {
  background-color: $gray-light;
  position: relative;
  width: fit-content;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
    background-color: $admin-blue;
  }
}
</style>