export const statusData =  [
                { id: null, name: "Все" },
                { id: 0, name: "Не заполнен" },
                { id: 1, name: "Проверен" },
                { id: 2, name: "Отклонен" },
                { id: 3, name: "На проверке" }
            ]

export const masks = {
                maskName: "X".repeat(60),
                passportSeriesMask: "####",
                passportNumMask: "######",
                passportKzNumMask: "#########",
                passportDateMask: "##-##-####",
                innDateMask: "####-######-##",
                birthDateMask: "##-##-####",
                snilsMask: "###-###-### ##",
                passportCodeMask: "###-###",
            }