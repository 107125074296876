import Vue from 'vue'

export default {
  state: {
    customCategories: null,
    goods: null,
    good: null
  },

  mutations: {
    SET_CUSTOM_CATEGORIES(state, payload) {
      state.customCategories = payload
    },

    SET_GOODS(state, payload) {
      state.goods = payload
    },

    SET_GOOD_BY_ID(state, payload) {
      state.good = payload
    },
  },

  actions: {
    loadE2({ commit}) {
      Vue.http_admin.get(
          "/e2e4"
      ).then(response => {
        console.log(commit)
        console.log(response)
      })
    },

    getCustomCategories({ commit}) {
      Vue.http_admin.get(
        "api/customCategories"
      )

      .then(response => {
        commit('SET_CUSTOM_CATEGORIES', response)
      })

      .catch(error => {
         if (error?.response && error?.response?.status === 500) {
          this.$toast("Ой, что-то пошло не так", {
            timeout: 5000,
            type: "error",
          })
        }
      });
    },

    upsetCustomCategory({dispatch}, {categoryId, categoryName}) {
      const bodyParameters = {
        "id": categoryId,
        "name": categoryName
      }

      Vue.http_admin.post(
        "api/customCategory",
        bodyParameters
      )

      .then(() => {
        dispatch('getCustomCategories')
      })

      .catch(error => {
         if (error?.response && error?.response?.status === 500) {
          this.$toast("Ой, что-то пошло не так", {
            timeout: 5000,
            type: "error",
          })
        }
      });
    },

    getGoods({ commit }, { id, name='' }) {
      Vue.http_admin.get(
        `api/customGoods?name=${name}`
      )
      .then(response => {
        let result =  response.data.filter(item => {
          return item.customCategoryId == id
        })
        commit('SET_GOODS', result)
      })
      .catch(error => {
         if (error?.response && error?.response?.status === 500) {
          this.$toast("Ой, что-то пошло не так", {
            timeout: 5000,
            type: "error",
          })
        }
      })
    },

    getGoodsById({ commit }, id) {
      Vue.http_admin.get(
        `api/customGoods/${id}`
      )

      .then(response => {
        commit('SET_GOOD_BY_ID', response.data)
      })

      .catch(error => {
         if (error?.response && error?.response?.status === 500) {
          this.$toast("Ой, что-то пошло не так", {
            timeout: 5000,
            type: "error",
          })
        }
      })
    },

    createGood({ dispatch }, categoryId) {
      Vue.http_admin.post(
        "api/customGoods",
        {
          "name": "Новый товар",
          "weight": 150,
          "price": 0,
          "customCategoryId": categoryId
        }
      )
      .then(() => {
        dispatch('getGoods', {
          id: categoryId
        })
      })
      .catch(error => {
         if (error?.response && error?.response?.status === 500) {
          this.$toast("Ой, что-то пошло не так", {
            timeout: 5000,
            type: "error",
          })
        }
      });
    },

    updateGoodsById({commit}, {productData}) {

      Vue.http_admin.post(
        `api/customGoods`,
        productData
      )

      .then(() => {
        commit("SET_GOOD_BY_ID")
      })

      .catch(error => {
         if (error?.response && error?.response?.status === 500) {
          this.$toast("Ой, что-то пошло не так", {
            timeout: 5000,
            type: "error",
          })
        }
      });
    }
  }

}
