<template>
    <div class="user-search-result-wrap">
        <div class="user-search-result-head">
            <div class="col user-search-result-head__name">Имя</div>
            <div class="col user-search-result-head__phone">Номер телефона</div>
            <div class="col user-search-result-head__balance">Баланс</div>
            <div class="col">Статус профиля</div>
        </div>
        <div class="search-result-wrap">
            <div class="list-wrap">
                <div class="user-search-result" v-for="(user, idx) in users" :key="idx"
                    :class="{ active: user.id == selected, blocked: user.blocked }" @click="selectUserFn(user)">
                    <div class="col user-search-result__name">{{ truncateStringFn(user.full_name, 20) }}</div>
                    <div class="col user-search-result__phone">{{ convert(user.phone) }}</div>
                    <div class="col user-search-result__balance">{{ user.account ? user.account.balance : 0 }}</div>
                    <div class="col user-search-result__balance">{{ getUserStatus(user) }}</div>
                </div>

                <infinite-loading @infinite="loadMoreData">
                    <div slot="no-more" class="no-res"> </div>
                    <div slot="no-results" class="no-res"> </div>
                </infinite-loading>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState, mapActions } from 'vuex'
import convertPhone from '../../../../utils/convertPhone';
import { truncateString } from '../../../../utils';

export default {
    props: {
        selected: {
            type: Number,
            requred: true,
        },
        filters: {
            type: Object,
            required: true,
        }
    },
    computed: {
        ...mapState({
            users: state => state.users.users,
        })
    },
    watch: {
        filters: {
            handler() {
                this.reloadDataOnFilterChange();
            },
            deep: true,
        },
    },

    methods: {
        ...mapActions(['searchUsers']),
        async loadMoreData($state) {
            try {
                const dataLoaded = await this.searchUsers({
                    name: this.filters.name,
                    phone: parseInt(this.filters.phone.replace(/\+7|[^0-9]/g, ''), 10),
                    status: this.filters.status,
                });
                if (dataLoaded.length === 0) {
                    $state.complete();
                } else {
                    $state.loaded();
                }
            } catch (error) {
                $state.complete();
                console.error("Ошибка загрузки данных:", error);
            }
        },

        truncateStringFn(value, length) {
            return truncateString(value, length)
        },

        reloadDataOnFilterChange() {
            this.searchUsers({
                reset: true,
                name: this.filters.name,
                phone: parseInt(this.filters.phone.replace(/\+7|[^0-9]/g, ''), 10) ?? '',
                status: this.filters.status,
            });
        },

        selectUserFn(user) {
            this.$emit('select-user', user)
        },
        convert(num) {
            return convertPhone(num)
        },
        getUserStatus(user) {
            switch (user.verified) {
                case 1:
                    return "Проверен";
                case 2:
                    return "Отклонен";
                case 3:
                    return 'На проверке';
                default:
                    return 'Не заполнен';
            }
        },
    },
    created() {
        this.searchUsers({ reset: true })
    }
}

</script>

<style lang="scss" scoped>
.user-search-result-wrap {
    margin-top: 40px;
}

.user-search-result {
    height: 58px;
    display: flex;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    align-items: center;

    &:hover {
        background: lighten(#eeeeee, 4%);
    }

    &.active {
        background: #eeeeee;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 10px;
            background: $admin-blue;
        }
    }

    &.blocked {
        background-color: rgba(181, 47, 31, 0.80)
    }


    .col {
        width: 33.33%;
        font: 400 16px/40px $roboto;
        color: $dark-font;
    }
}


.search-result-wrap {
    max-height: 280px;
    overflow-y: auto;
}


.user-search-result-head {
    height: 30px;
    display: flex;
    border-bottom: 1px solid $dark-font;

    .col {
        width: 33.33%;
        font: 400 14px/8px $roboto;
        color: #9e9e9e;
    }
}
</style>