<template>
    <div class="admin-messages-top">
        <div class="messages-result-wrap">
            <div class="messages-result-head">
                <div class="col messages-result-head__fullName">ФИО</div>
                <div class="col messages-result-head__status">Статус</div>
                <div class="col messages-result-head__comment">Комментарий</div>
                <div class="col messages-result-head__startDate">Дата создания</div>
                <div class="col messages-result-head__updDate">Дата обновления</div>
            </div>

            <div class="messages-result-list-wrap">
                <div class="list-wrap">
                    <div class="messages-result" v-for="(statusItem, idx) in statusHistory" :key="idx"
                        :class="{ active: selectedRow && (statusItem.id === selectedRow.id) }"
                        @click="selectRow(statusItem)">
                        <div class="col messages-result__fullName">
                            {{ statusItem.user.full_name || '-' }}
                        </div>
                        <div class="col messages-result__status">
                            {{ statusFormatter(statusItem.status) }}
                        </div>
                        <div class="col messages-result__comment">
                            {{ tuncLongString(statusItem.comment, 25) || '-' }}
                        </div>
                        <div class="col messages-result__startDate">
                            {{ dateFormatter(statusItem.createdAt) || '-' }}
                        </div>
                        <div class="col messages-result__updDate">
                            {{ dateFormatter(statusItem.updatedAt) || '-' }}
                        </div>
                    </div>

                    <infinite-loading @infinite="loadMoreData">
                        <div slot="no-more"> </div>
                        <div slot="no-results"> </div>
                    </infinite-loading>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { truncateString, voidDateTime } from '../../../../utils';
import moment from 'moment';
import { statusFormatterFn } from '../utils';


export default {
    props: {
        selectedRow: {
            type: Object,
            default: null,
        },
        filters: {
            type: Object,
        }
    },
    methods: {
        ...mapActions(['getStatusHistory']),
        async loadMoreData($state) {
            try {
                const dataLoaded = await this.getStatusHistory({
                    reload: true,
                    startDate: voidDateTime(this.filters.startDate),
                    completeDate: voidDateTime(this.filters.endDate),
                    fullName: this.filters.fullName,
                });
                if (dataLoaded.length === 0) {
                    $state.complete();
                } else {
                    $state.loaded();
                }
            } catch (error) {
                $state.complete();
                console.error("Ошибка загрузки данных:", error);
            }
        },
        selectRow(row) {
            this.selected = row;
            this.$emit('select-item', row)
        },
        tuncLongString(str, lenght) {
            return truncateString(str, lenght);
        },
        dateFormatter(date) {
            return moment(date).format("DD.MM.YYYY");
        },
        statusFormatter(status) {
            return statusFormatterFn(status);
        },
        async reloadDataOnFilterChange() {
            await this.getStatusHistory({
                reset: true,
                startDate: voidDateTime(this.filters.startDate),
                endDate: moment(voidDateTime(this.filters.endDate)).add(1, 'day').toISOString(),
                fullName: this.filters.fullName
            });
        },
    },
    computed: {
        ...mapState({
            statusHistory: (state) => state.statusHistory.statusHistory,
        }),
    },
    watch: {
        filters: {
            handler() {
                this.reloadDataOnFilterChange();
            },
            deep: true,
        },
    },

    created() {
        this.$store.dispatch('getStatusHistory', {
            reset: true, startDate: voidDateTime(this.filters.startDate),
            endDate: moment(voidDateTime(this.filters.endDate)).add(1, 'day').toISOString()
        });
    }
};
</script>

<style lang="scss" scoped>
.messages-result-list-wrap {
    max-height: 280px;
    overflow-y: auto;
}

.messages-result-head {
    height: 30px;
    display: flex;
    border-bottom: 1px solid $gray-mid;

    .col {
        font: 400 14px/8px $roboto;
        color: $gray-dark;
        opacity: 0.6;
    }

    &__fullName {
        width: 30%;
    }

    &__status {
        width: 25%;
    }

    &__comment {
        width: 30%;
    }

    &__startDate {
        width: 24%;
    }

    &__updDate {
        width: 24%;
    }
}

.messages-result {
    min-height: 69px;
    display: flex;
    border-bottom: 1px solid $gray-light;
    cursor: pointer;

    &.active {
        background: $gray-lightest;
        position: relative;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 10px;
        }
    }

    &:hover {
        background: lighten($gray-light, 4%);
    }

    &__fullName {
        width: 30%;
    }

    &__status {
        width: 25%;
    }

    &__comment {
        width: 30%;
    }

    &__startDate {
        width: 24%;
    }

    &__updDate {
        width: 24%;
    }

    .col {
        font: 400 14px/25px $roboto;
        color: $dark-font;
    }
}

.admin-messages {
    position: relative;

    textarea {
        padding: 10px;
        margin-top: 28px;
        margin-bottom: 30px;
        width: 100%;
        min-height: 200px;
        border: 1px solid $gray-mid-dark;
        color: $gray-mid-dark;
        font: 400 16px/20px $roboto;
        color: $dark-font;

        &:focus {
            border: 1px solid $blue;
        }
    }

    .input-wrap {
        input {
            width: 100%;
            height: 30px;
            border: none;
            border-bottom: 1px solid $gray-semi-dark;
            color: $gray-mid-dark;
            font: 400 16px/30px $roboto;
            padding: 0 5px;
            color: $dark-font;

            &:placeholder {
                color: $gray-mid-dark;
            }

            &:focus {
                border-bottom: 1px solid $blue;
            }
        }

        label {
            position: relative;
            display: inline-block;
            width: 100%;
        }

        span {
            padding: 5px;
            pointer-events: none;
            position: absolute;
            left: 0;
            top: 0;
            transition: 0.2s;
            transition-timing-function: ease;
            transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
            opacity: 0.5;
            color: $dark-font;
            font: 400 16px $roboto;
        }

        input:focus+span,
        input:not(:placeholder-shown)+span {
            opacity: 1;
            transform: translateY(-100%) translateX(-10px);
            font-size: 13px;
        }

        input:focus+span,
        input:not(:-ms-input-placeholder)+span {
            opacity: 1;
            transform: translateY(-100%) translateX(-10px);
            font-size: 13px;
        }

        textarea:focus+span,
        textarea:not(:placeholder-shown)+span {
            opacity: 1;
            transform: translateY(-100%) translateX(-10px);
            font-size: 13px;
        }

        textarea:focus+span,
        textarea:not(:-ms-input-placeholder)+span {
            opacity: 1;
            transform: translateY(-100%) translateX(-10px);
            font-size: 13px;
        }
    }
}

.messages-list-btns {
    display: flex;
    margin-top: 20px;

    .add {
        margin-right: auto;
    }

    .remove,
    .save {
        margin-left: auto;
    }

    .messages-list-btn {
        display: block;
        height: 40px;
        padding: 0 15px;
        color: $white;
        font: 400 14px/8px $roboto, sans-serif;
        background: $admin-blue;
        border: none;
        cursor: pointer;

        &:hover {
            background: lighten($admin-blue, 10%);
        }
    }
}

.messages-form-list-btns {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    .add {
        margin-right: auto;
    }

    .remove,
    .save {
        margin-left: auto;
    }

    .messages-form-list-btn {
        display: block;
        height: 40px;
        padding: 0 15px;
        color: $white;
        font: 400 14px/8px $roboto, sans-serif;
        background: $admin-blue;
        border: none;
        cursor: pointer;

        &:hover {
            background: lighten($admin-blue, 10%);
        }
    }

    .messages-form-list-dbtn {
        display: block;
        height: 40px;
        padding: 0 15px;
        color: $white;
        font: 400 14px/8px $roboto, sans-serif;
        background: $admin-red;
        border: none;
        cursor: pointer;

        &:hover {
            background: lighten($admin-red, 10%);
        }
    }
}
</style>
