<template>
    <div>
        <div class="users-top">
            <div class="users-search">
                <h1 class="users-heading">Пользователи</h1>
                <form action="" class="user-search">
                    <div class="input-wrap">
                        <label>
                            <input class="user-search__input by-name" type="text" placeholder=" " v-model="name"
                                @keyup="searchByUserFn" @keyup.enter="searchByUserFn">
                            <span>ФИО</span>
                        </label>
                    </div>

                    <div class="input-wrap">
                        <label>
                            <input class="user-search__input by-phone" placeholder=" " v-model="phone"
                                @keyup="searchByPhoneFn" @keyup.enter="searchByPhoneFn">
                            <span>Номер телефона</span>
                        </label>
                    </div>

                    <div class="input-wrap">
                        <label>
                            <span>Поиск по статусу</span>
                        </label>
                        <div class="users-select">
                            <v-select placeholder="Поиск по статусу" class="admin-select" :options="statusData"
                                label="name" @input="searchByStatusFn" v-model="status">
                            </v-select>

                        </div>
                    </div>
                </form>
            </div>
            <UsersInfo />
        </div>

        <div class="user-btn-download row">
            <div class="col">
                <button @click="downloadUserDataFn" class="button">Выгрузить данные пользователей</button>
            </div>
        </div>
    </div>
</template>


<script>
import { statusData } from '../utils';
import UsersInfo from './UsersInfo.vue';

export default {
    components: {
        UsersInfo
    },
    data() {
        return {
            name: '',
            phone: '',
            status: '',
            statusData
        }
    },
    methods: {
        downloadUserDataFn() {
            this.$emit('download-user');
        },

        searchByUserFn() {
            this.$emit('update-filter', 'name', this.name)
        },

        searchByPhoneFn() {
             this.$emit('update-filter', 'phone', this.phone)
        },

        searchByStatusFn() {
           this.$emit('update-filter', 'status', this.status?.id)
        },
    }
}

</script>


<style scoped lang="scss">
.user-btn-download,
.user-btn-edit {
    margin-top: 30px;

    button {
        display: block;
        height: 30px;
        padding: 0 15px;
        color: #fff;
        font: 400 14px/30px $roboto;
        background: $admin-blue;
        border: none;
        cursor: pointer;
        margin: 30px 0;

        &:hover {
            background: lighten($admin-blue, 10%)
        }
    }
}

.users-select {
    width: 300px;
}

.users-select {
    label {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    span {
        padding: 5px;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: -42px;
        transition: 0.2s;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity: 0.5;
        color: $dark-font;
        font: 400 16px $roboto;
    }
}

.users-select .vs--open+label span,
.users-select .active+label span,
input:not(:placeholder-shown)+span {
    opacity: 1;
    transform: translateY(-100%) translateX(-10px);
    font-size: 13px
}

.users-select .vs--open+label span,
.users-select .active+label span,
input:not(:-ms-input-placeholder)+span {
    opacity: 1;
    transform: translateY(-100%) translateX(-10px);
    font-size: 13px;
}


.input-wrap {
    margin-bottom: 20px;
}

.users-top {
    display: flex;
    margin-top: 40px;
}

.users-search {
    width: 36.7%;
}

.users-heading {
    font: 400 36px/1.5 $roboto;
    color: $dark-font;
    margin-bottom: 20px;
}

.user-search {
    width: 300px;

    &__input {
        width: 100%;
        height: 30px;
        border: none;
        border-bottom: 1px solid #9e9e9e;
        color: #9e9e9e;
        font: 400 16px/30px $roboto;
        padding: 0 5px;
        color: $dark-font;

        &:placeholder {
            color: #9e9e9e;
        }

        &:focus {
            border-bottom: 1px solid blue;
        }
    }

    label {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    span {
        padding: 5px;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.2s;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity: 0.5;
        color: $dark-font;
        font: 400 16px $roboto;
    }

    input:focus+span,
    input:not(:placeholder-shown)+span {
        opacity: 1;
        transform: translateY(-100%) translateX(-10px);
        font-size: 13px;
    }

    input:focus+span,
    input:not(:-ms-input-placeholder)+span {
        opacity: 1;
        transform: translateY(-100%) translateX(-10px);
        font-size: 13px;
    }
}
</style>