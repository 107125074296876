<template>
    <div class="catalog">
        <div class="catalog-main">
            <div class="container">
                <div
                    class="warn_text"
                    v-if="citizenship === 'ru'"
                >
                    <div>
                        <p :class="{hide_warnText:!show_warnText}" v-html="warnText"/>
                        <button class="show_moreBtn" @click="toggle">{{
                                show_warnText ? "Свернуть" : "Развернуть"
                            }}
                        </button>
                    </div>

                </div>

                <div class="row">

                    <div class="catalog-nav">
                        <side-nav-btn/>
                        <ul class="breadcrumbs">
                            <li v-if="category"><span>{{ category.name }}</span></li>
                        </ul>
                    </div>
                </div>
                <div class="row catalog-row">
                    <template v-if="goods">
                        <div v-for="(product, idx) in goods" :key="idx" class="col">
                            <div class="card">
                                <div class="card-top">
                                    <div class="card-top-img">
                                        <img :src="product.picture ? product.picture[0] : ''" :alt="product.name">
                                    </div>
                                    <div class="card-top-right">
                                        <div class="card-price">
                                            {{ parseInt(product.priceWithDelivery) }}
                                            <span class="card-price__ico"></span>
                                        </div>
                                        <div class="card-links">
                                            <div class="card-links__item">
                                                <router-link :to="`${$route.params.id}/${product.id}`"
                                                             class="product-detailed-link">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </router-link>
                                            </div>
                                            <add-to-basket :productId="product.id" :userId="userId"
                                                           :custom="customCatalog"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-bot">
                                    <h3 class="prod-title">
                                        {{
                                            product.name + (!customCatalog ? (product.params.color ? ' ' + product.params.color : '') : '')
                                        }}</h3>
                                    <div class="prod-desc"
                                         v-html="customCatalog ? product.short_description : product.description"></div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="showLoadingPlaceholder">
                        <div class="loading-placeholder">
                            <div>
                                Загрузка товаров...
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddToBasket from '@/components/AddToBasket'
import SideNavBtn from '@/components/SideNavBtn'
import {mapState, mapGetters} from 'vuex'
import Vue from 'vue'

export default {

    data() {
        return {
            userId: '',
            goods: null,
            categoriesId: [],
            warnText: `
                        Уважаемый участник Мегабонус! Принимая участие в программе лояльности Вы :
                        <ol>
                            <li>Подтверждаете свое согласие на получение от ООО " ФИТ Автосервис" поощрения в натуральной форме ( в форме товаров) и в денежной форме (в размере 14.94 % от стоимости поощрения в натуральной форме ).</li>
                            <li>Подтверждаете свое согласие на получение от ООО " ФИТ Автосервис" поощрения в натуральной форме в размере более 20 % от всей суммы поощрения.</li>
                            <li>Подтверждаете свое согласие на удержание из суммы поощрения НДФЛ в размере 13 %.</li>
                            <li>Подтверждаете факт ознакомления с тем, что ООО " ФИТ Автосервис" является налоговым агентом и производит удержание , оплату НДФЛ и подачу деклараций с сумм начисленного поощрения в органы Федеральной Налоговой службы за участника программы лояльности .</li>
                            <li>Подтверждаете факт ознакомления со всей информацией, касающейся выплат поощрения за участие в программе лояльности Мегабонус и порядка удержания и уплаты НДФЛ, а также с условиями программы .</li>
                            <li>Подтверждаете факт ознакомления с тем, что при получении участником программы лояльности поощрения в натуральной форме (в форме товаров) необходимо осуществить проверку его целостности и работоспособности. В случае, если участник не осуществил проверку целостности и работоспособности поощрения в натуральной форме (в форме товаров) при его получении и в последствии обнаружил признаки его ненадлежащего качества, поощрения в натуральной форме (в форме товаров) замене не подлежат.</li>
                            <li>Подтверждаете факт ознакомления с условиями замены полученного участником программы лояльности поощрения в натуральной форме (в форме товаров) при обнаружении его ненадлежащего качества в соответствии с законодательством РФ:
                                <ul>
                                    <li>•  При возврате полученного участником программы лояльности поощрения в натуральной форме (в форме товаров) ненадлежащего качества участник программы лояльности вправе заменить товар на аналогичный надлежащего качества либо выбрать любой другой товар, указанный на официальном сайте программы Мегабонус, но в пределах суммы бонусов, списанных со счета участника за ранее полученный и возвращенный им товар (стоимость выбранного товара для замены должна быть либо равна, либо быть меньше, стоимости ранее полученного участником и возвращенного товара), при этом ранее списанные бонусы обратно на счет участника не возвращаются и с вновь накопленными бонусами не суммируются.</li>
                                    <li>•  В случае если участник для замены выбрал товар меньшей стоимостью бонусов, чем стоимость ранее полученного и возвращенного им товара, то оставшаяся сумма бонусов сгорает (за исключением вновь накопленной суммы бонусов).</li>
                                    <li>•  Обязательными условиями замены полученного участником программы лояльности поощрения в натуральной форме (в форме товаров) является возврат товара по месту нахождения ООО «ФИТ Автосервис» и подтверждение его ненадлежащего качества.</li>
                                </ul>
                            </li>
                        </ol>
                      `,
            show_warnText: false,
            citizenship: null,
            showLoadingPlaceholder: true
        }
    },

    props: {
        customCatalog: Boolean
    },

    computed: {
        ...mapGetters(['getAmountOfProducts']),
        ...mapState({
            category: state => state.products.categoryById,
            userData: state => state.profile.user,
        })
    },

    methods: {
        getCategories() {

            let catUrl = '/api/categories'
            let goodsUrl = '/api/category/'
            if (this.customCatalog) {
                catUrl = '/api/customCategories'
                goodsUrl = '/api/customCategory/'
            }

            Vue.http.get(
                catUrl
            )
                .then(response => {

                    let categoriesId = [];

                    let getChildCategories = (id) => {
                        return response.data.filter(item => {
                            return item.parentId === id;
                        });
                    }
                    let cat_from_id = (id) => {
                        let local_cat_list = getChildCategories(id);
                        if (local_cat_list.length !== 0) {
                            local_cat_list.forEach((e) => {
                                cat_from_id(e.id);
                            })
                        } else {
                            categoriesId.push(id);
                        }
                    }
                    cat_from_id(this.$route.params.id);
                    /*let categories = response.data.filter( item => {
                      return item.parentId == this.$route.params.id
                    })
                    let categoriesId = [this.$route.params.id]
                    categories.forEach((item) => {
                      categoriesId.push(item.id)
                    })*/
                    //this.categoriesId = categoriesId

                    this.categoriesId = categoriesId;
                    let goods = [];
                    let promises = [];
                    for (var i = 0; i < categoriesId.length; i++) {
                        let catId = categoriesId[i]
                        promises.push(
                            Vue.http.get(`${goodsUrl}${catId}`).then(response => {
                                if (response.data.goods.length != 0) {
                                    this.showLoadingPlaceholder = false;
                                    response.data.goods.forEach(item => {
                                        if (goods.length < 800) {
                                            goods.push(item)
                                        }
                                    })
                                }
                            })
                        )
                    }

                    Promise.all(promises).then(() => {
                        this.goods = goods
                    });

                })
                .catch(error => {
                    console.log(error);
                });
        },
        toggle: function (e) {
            e.preventDefault();
            return this.show_warnText = !this.show_warnText
        },
    },


    watch: {
        '$route'(to) {
            if (to.name === "products") {
                this.$store.dispatch('fetchCategoryById', this.$route.params.id)
            }
        },

        userData() {
            if (this.userData) {
                this.userId = this.userData.id;
                this.$store.dispatch('fetchBasket');
                this.citizenship = this.userData.country.code;
            }
        }
    },

    created() {
        this.$store.dispatch('fetchUser')
        if (!this.customCatalog) {
            this.$store.dispatch('fetchCategoryById', this.$route.params.id)
        } else {
            this.$store.dispatch('fetchCustomCategoryById', this.$route.params.id)
        }

        this.getCategories()
    },

    components: {
        AddToBasket,
        SideNavBtn
    }
}
</script>

<style lang="scss">
.catalog-main {
    background: #1A1C1C;
    position: relative;
    z-index: 1;
    padding-bottom: 100px;
    min-height: calc(100vh - 184px);
}

.catalog-row {
    padding: 5px 5px 0px 5px;
    @include display-less(desktop) {
        padding: 0 15px;
    }
    @include display-less(phablet) {
        padding: 0 5px;
    }

    .loading-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        font-size: 36px;
        font-family: sans-serif;
        color: #cccccc;
        width: 100%;

        @include display-less(desktop) {
            padding: 15px;
            font-size: 24px;
        }
        @include display-less(phablet) {
            padding: 5px;
            font-size: 18px;
        }
    }
}

.warn_text {
    //margin: 1em 0 6em;
    padding: 1em 0;
    color: #cccccc;
    font-size: 14px;
    font-family: sans-serif;
    line-height: 25px;
    position: relative;

    p {
        height: 700px;
        overflow: hidden;
        transition: all 1s;
        @include display-less(950px) {
            height: 750px;
        }
        @include display-less(900px) {
            height: 800px;
        }
        @include display-less(800px) {
            height: 900px;
        }
        @include display-less(745px) {
            height: 980px;
        }
        @include display-less(675px) {
            height: 1050px;
        }
        @include display-less(640px) {
            height: 1250px;
        }
        @include display-less(565px) {
            height: 1400px;
        }
        @include display-less(500px) {
            height: fit-content;
        }

        ol {
            list-style: decimal;
            padding: revert;
        }
    }

    .hide_warnText {
        height: 3.6em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .show_moreBtn {
        cursor: pointer;
        background: transparent;
        border: 1px solid #FF9C43;
        border-radius: 5px;
        color: #fff;
        padding: 2px 5px;

        &:hover {
            background-color: #FF9C43;
            color: #1A1C1C;
        }
    }
}

.col {
    width: 33.33%;
    padding: 15px 10px;
    @include display-less(desktop) {
        width: 50%;
    }
    @include display-less(phablet) {
        width: 100%;
    }
}

.card {
    height: 222px;
    background: #FFFEFA;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 15px;
}

.card-top {
    display: flex;
    justify-content: space-between;

    &-img {
        width: 51.1%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            display: inline-block;
            max-height: 100px;
            max-width: 100%;
        }
    }
}

.card-top-right {
    display: flex;
    flex-direction: column;
    height: 100px;
}

.card-price {
    font: 700 32px/37px $roboto;
    color: #FF9C43;
    display: flex;
    margin-top: 5px;

    &__ico {
        width: 20px;
        height: 18px;
        display: block;
        background-image: url('../assets/images/icons/coins.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        align-self: flex-end;
        margin: 0 0 7px 10px;
    }
}

.card-links {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: auto;

    &__item {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #232525;

        a {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.product-detailed-link {
    div {
        width: 6px;
        height: 6px;
        background: #FF9C43;
        border-radius: 50%;
        margin: 0 1px;
    }
}

.card-bot {
    margin-top: 18px;
}

.prod-title {
    font: 400 14px/16px $roboto;
    color: #1E1E1E;
    height: 32px;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.prod-desc {
    font: 400 14px/16px $roboto;
    color: #8B8B8B;
    height: 32px;
    margin-top: 10px;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.catalog-nav {
    margin-left: 10px;
    margin-top: 20px;
    display: flex;
}

.breadcrumbs {
    display: flex;

    li {
        a, span {
            display: block;
            height: 30px;
            background: #232525;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 50px;
            color: #fff;
            font: 300 16px/30px $roboto;
            padding: 0 10px;
            margin-left: 10px;
        }
    }
}
</style>
