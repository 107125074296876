<template>
  <div :class="{shift: citizenship !== 'ru'}">
    <div class="warn-section-style">
      <div class="notification-style" v-html="show_more_less ? notification : notification.slice(0, 60) + '...' " />
    </div>
    <div class="input-text-style">
      <input type="checkbox" @change="changeAccept" class="custom-checkbox"/>
      <span>Ознакомлен (-а)</span>
      <button class="button-message-tuner" @click="showHide">
        {{ show_more_less ? "Свернуть" : "Развернуть" }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accepted: false,
      show_more_less: false,
    };
  },
  props: {
    notification: String,
    citizenship: String,
  },
  methods: {
    changeAccept() {
      this.accepted = !this.accepted;
      this.$emit("accepted", {
        accepted: this.accepted,
      });
    },
    showHide() {
      this.$emit("showText", (this.show_more_less = !this.show_more_less));
    },
  },
};
</script>

<style lang="scss">
.shift {
  display: none;
}

.warn-section-style {
  max-height: 350px;
  overflow-x: auto;
  border-radius: 5px;
  border: none;
  box-sizing: content-box;
  width: 300px;
  text-align: justify;
}

.notification-style {
  color: white;
  text-align: left;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 14px;
  font-size: 14px;

   @include display-less(phablet) {
    max-height: 200px;
  }

  ol {
    list-style: decimal;
    padding: revert;
  }
}

.input-text-style {
  display: flex;
  align-items: center;
  color: white;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  margin: 15px 0;
  font-size: 14px;
}

.custom-checkbox {
  position: absolute;
  margin-left: 7px;
  margin-top: 2px;
  transform: scale(1.5);
  opacity: 0;
  cursor: pointer;
}

.custom-checkbox + span {
  display: inline-flex;
  align-items: center;
}

.custom-checkbox + span::before {
  content: "";
  flex-shrink: 0;
  flex-grow: 0;
  display: inline-block;
  width: 1.3em;
  height: 1.3em;
  border: 1px solid orange;
  border-radius: 0.25em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  margin-right: 0.5em;
  margin-left: 4px;
}

.custom-checkbox:checked + span::before {
  background-color: orange;
  background-image: url(../../../../src/assets/images/icons/accepted-green.svg);
}


.input-text-style > span {
  margin-left: 0;
}

@media screen and (max-width: 762px) {
  .shift {
    display: none;
  }
}

.button-message-tuner {
  width: 136px;
  border: 1px solid orange;
  background-color: transparent;
  color: white;
  margin-left: 10px;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  cursor: pointer;
}

.button-message-tuner:hover {
  background-color: orange;
  color: black;
}
</style>
