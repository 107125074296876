/**
 * @description Обрезает строку до определенного количества символов
 * @param text - строка
 * @param length - длина строки
 */
export const truncateString = (
  text,
  length,
) =>
   text !== null && text.length > length
    ? `${text.substring(0, length)}...`
    : text;


export const voidDateTime = (date) => {
      const voidDate = new Date(date);
       voidDate.setUTCHours(0, 0, 0, 0)
      return voidDate.toISOString()
    }


export const debounce = (func, wait) => {
    let timeout;
    return function(...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}