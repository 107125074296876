<template>
  <div class="filters">
    <article class="orders-top-row">
      <div class="datepicker">
        <div class="datepicker__info">c:</div>
        <div class="datepicker__calendar">
          <datepicker :language="ru" :format="customFormatter" input-class="dateInput" wrapper-class="dateInputWrap"
            v-model="filters.startDate" @selected="dateStartChangeFn" />
        </div>
      </div>

      <div class="datepicker">
        <div class="datepicker__info">по:</div>
        <div class="datepicker__calendar">
          <datepicker :language="ru" :format="customFormatter" input-class="dateInput" wrapper-class="dateInputWrap"
            v-model="filters.endDate" @selected="dateEndChangeFn" />
        </div>
      </div>
    </article>

    <div class="filter-wrapper">
       <div class="input-wrap">
      <div class="search-container">
        <label>
          <input type="text" placeholder=" " v-model="fullName" @keyup="debouncedSearchFullName"
            @keyup.enter="searchByFullName">
          <span>Имя пользователя</span>
        </label>
      </div>
    </div>
  </div>
  </div>
</template>


<script>
import Datepicker from "vuejs-datepicker";
import { ru } from "vuejs-datepicker/dist/locale";
import { debounce } from "../../../../utils";
import moment from "moment";

export default {
  components: {
    Datepicker
  },

  data() {
    return {
      ru: ru,
      fullName: null,
      debouncedSearchFullName: null,
    };
  },

  props: {
    filters: Object,
  },

  created() {
    this.debouncedSearchFullName = debounce(this.searchByFullName, 500);
  },

  methods: {
    dateStartChangeFn(date) {
      this.$emit('update-filter', 'startDate', date);
    },

    customFormatter(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    dateEndChangeFn(date) {
      this.$emit('update-filter', 'endDate', date);
    },

    searchByFullName() {
      this.$emit('update-filter', 'fullName', this.fullName);
    },
  }
};
</script>


<style lang="scss" scoped>

.filters {
    margin-top: 40px;
    margin-bottom: 40px;
}
.filter-wrapper {
  display: inline-flex;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  margin-top: 20px;
}
.status-label {
  opacity: 0.5;
  margin-left: 8px;
  font: 400 16px/10px $roboto;
}

.search-container {
  margin-top: 25px;
}

.input-wrap {
  input {
    height: 30px;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    color: #9e9e9e;
    font: 400 16px/30px $roboto;
    padding: 0 5px;
    color: $dark-font;

    &:placeholder {
      color: #9e9e9e;
    }

    &:focus {
      border-bottom: 1px solid blue;
    }
  }

  label {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  span {
    padding: 5px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.5;
    color: $dark-font;
    font: 400 16px $roboto;
  }

  input:focus+span,
  input:not(:placeholder-shown)+span {
    opacity: 1;
    transform: translateY(-100%) translateX(-10px);
    font-size: 13px
  }

  input:focus+span,
  input:not(:-ms-input-placeholder)+span {
    opacity: 1;
    transform: translateY(-100%) translateX(-10px);
    font-size: 13px;
  }
}

.orders-registry {
  display: block;
  background: $admin-blue;
  font: 400 16px/40px $roboto;
  color: $white;
  padding: 0 15px;
  border: none;
  cursor: pointer;

  &:hover {
    background: darken($admin-blue, 10%);
  }
}

.datepicker {
  display: flex;
  align-items: center;

  &__info {
    font: 400 16px $roboto;
    color: $dark-font;
    margin-right: 10px;
  }

  &__calendar {
    border-bottom: 1px solid $dark-font;
    width: 150px;
  }
}

.orders-top-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 32px;
}
</style>