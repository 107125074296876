<template>
  <div class="product-basket-card">
    <div class="product-basket-card__name">{{ order.name }}</div>
    <div class="product-basket-block">
      <div class="product-basket-price">
        {{ parseInt(order.price) }}
        <span class="product-basket-price__ico"></span>
      </div>
      <div class="product-basket-block-right">
        <div class="product-basket-counter">
          <div class="product-basket-minus" @click="decrement"></div>
          <div class="product-basket-amount">{{ productCounter }}</div>
          <div class="product-basket-plus" @click="increment"></div>
        </div>
        <div class="product-basket-delete" @click="deleteProduct"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      order: {
        required: true,
        type: Object
      },
      productId: {
        required: true,
        type: Number
      },
      userId: {
        required: true,
        type: Number
      },
      custom: {
        required: true,
        type: Boolean
      }
    },

    data() {
      return {
        productCounter: this.order.count
      }
    },

    methods: {
      deleteProduct() {
        let params = {
          order: {
            [this.productId]: null
          },
          custom: this.custom
        }
        this.$store.dispatch('upsertBasket', params)
      },

      increment() {
        this.productCounter++
        let params = {
          order: {
            [this.productId]: this.productCounter
          },
          custom: this.custom
        }
        this.$store.dispatch('upsertBasket', params)
      },
      decrement() {
        this.productCounter--
        if(this.productCounter < 1) {
          this.productCounter = null
        }
        let params = {
          order: {
            [this.productId]: this.productCounter
          },
          custom: this.custom
        }
        this.$store.dispatch('upsertBasket', params)
      }
    }
  }
</script>


<style lang="scss" scoped>
  .product-basket-card {
    margin-top: 20px;
    &__name {
      font: 400 14px/16px $roboto;
      color: #838383;
      margin-bottom: 20px;
    }
  }

  .product-basket-block {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #5A5A5A;
  }

  .product-basket-card:last-child {
    .product-basket-block {
      border-bottom: none;
    }
  }


  .product-basket-block-right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .product-basket-counter {
    display: flex;
    align-items: center;
    width: 91px;
    height: 30px;
    border: 1px solid #FF9C43;
    box-sizing: border-box;
    border-radius: 50px;
  }

  .product-basket-minus {
    width: 24px;
    height: 24px;
    background-image: url('../../../assets/images/icons/minus.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: auto;
    cursor: pointer;
  }

  .product-basket-plus {
    width: 24px;
    height: 24px;
    background-image: url('../../../assets/images/icons/plus.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: auto;
    cursor: pointer;
  }

  .product-basket-delete {
    margin-left: 50px;
    width: 15px;
    height: 20px;
    background-image: url('../../../assets/images/icons/icon-basket-delete.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .product-basket-price {
    font: 400 16px/19px $roboto;
    color: #FFFEFA;
    display: flex;
    margin: 5px auto 0 0;

    &__ico {
      width: 15px;
      height: 14px;
      display: block;
      background-image: url('../../../assets/images/icons/coins.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      align-self: flex-end;
      margin: 0 0 2px 5px;
    }
  }

  .product-basket-amount {
    font: 400 16px/30px $roboto;
    color: #FFFEFA;
    margin: 0 auto;
  }

</style>