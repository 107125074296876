import Vue from 'vue'

export default {
    state: {
        user: null,
        cities: [],
        stations: [],
        countries: [],
        suggestedRegion: '',
        suggestedIndex: '',
        city: ''
    },

    mutations: {
        SET_USER(state, payload) {
            state.user = payload
        },

        UPDATE_USER(state, payload) {
            state.user = payload
        },

        SET_CITIES(state, payload) {
            state.cities = payload
        },

        SET_STATIONS(state, payload) {
            state.stations = payload
        },

        SET_COUNTRIES(state, payload) {
            state.countries = payload
        },

        setSuggestedRegion(state, payload) {
            state.suggestedRegion = payload;
        },

        setSuggestedIndex(state, payload) {
            state.suggestedIndex = payload;
        }
    },

    actions: {
        fetchUser({ commit }) {

            Vue.http.get(
                `/api/me`
            )
                .then(response => {
                    commit('SET_USER', response.data)
                })
                .catch(error => {
                    console.log(error);
                });
        },

        updateUser({ dispatch }, { id, profileData }) {

            return Vue.http.put(
                `/api/user/${id}`,
                profileData
            )
                .then(() => {
                    dispatch('fetchUser')
                })
                .catch(error => {
                    console.log(error);
                    if (error?.response && error?.response?.status === 500) {
                        this.$toast("Ой, что-то пошло не так", {
                            timeout: 5000,
                            type: "error",
                        })
                    }
                });
        },

        updateUserAdmin({ dispatch }, { id, profileData, onSuccess, onError }) {
            const bodyParameters = {
                ...profileData
            }

            return Vue.http_admin.put(
                `/api/user/${id}`,
                bodyParameters
            )
                .then(() => {
                    onSuccess && onSuccess();
                    dispatch('getUserById', id)
                })
                .catch(error => {
                    onError && onError();
                    if (error?.response && error?.response?.status === 500) {
                        this.$toast("Ой, что-то пошло не так", {
                            timeout: 5000,
                            type: "error",
                        })
                    }
                    console.log(error);
                });
        },

        fetchCities({ commit }) {
            Vue.http.get(
                `/api/cities`
            )
                .then(response => {
                    commit('SET_CITIES', response.data)
                })
                .catch(error => {
                    if (error?.response && error?.response?.status === 500) {
                        this.$toast("Ой, что-то пошло не так", {
                            timeout: 5000,
                            type: "error",
                        })
                    }
                    console.log(error);
                });
        },

        fetchStations({ commit }) {
            Vue.http.get(
                `/api/stations`
            )
                .then(response => {
                    commit('SET_STATIONS', response.data)
                })
                .catch(error => {
                    if (error?.response && error?.response?.status === 500) {
                        this.$toast("Ой, что-то пошло не так", {
                            timeout: 5000,
                            type: "error",
                        })
                    }
                    console.log(error);
                });
        },

        fetchCountries({ commit }) {
            Vue.http.get(
                `/api/countries`
            )
                .then(response => {
                    commit('SET_COUNTRIES', response.data)
                })
                .catch(error => {
                    if (error?.response && error?.response?.status === 500) {
                        this.$toast("Ой, что-то пошло не так", {
                            timeout: 5000,
                            type: "error",
                        })
                    }
                    console.log(error);
                });
        },


        fetchCitiesAdmin({ commit }) {
            Vue.http_admin.get(
                `/api/cities`
            )
                .then(response => {
                    commit('SET_CITIES', response.data)
                })
                .catch(error => {
                    if (error?.response && error?.response?.status === 500) {
                        this.$toast("Ой, что-то пошло не так", {
                            timeout: 5000,
                            type: "error",
                        })
                    }
                    console.log(error);
                });
        },

        fetchStationsAdmin({ commit }) {
            Vue.http_admin.get(
                `/api/stations`
            )
                .then(response => {
                    commit('SET_STATIONS', response.data)
                })
                .catch(error => {
                    if (error?.response && error?.response?.status === 500) {
                        this.$toast("Ой, что-то пошло не так", {
                            timeout: 5000,
                            type: "error",
                        })
                    }
                    console.log(error);
                });
        },

        fetchCountriesAdmin({ commit }) {
            Vue.http_admin.get(
                `/api/countries`
            )
                .then(response => {
                    commit('SET_COUNTRIES', response.data)
                })
                .catch(error => {
                    if (error?.response && error?.response?.status === 500) {
                        this.$toast("Ой, что-то пошло не так", {
                            timeout: 5000,
                            type: "error",
                        })
                    }
                    console.log(error);
                });
        },


    }
}
