<template>
  <div class="instructions-wrap">
    <div class="instructions-main">
      <span @click="back()" class="back"></span>
      <div class="container">
        <h1 class="instructions-heading">Инструктаж</h1>
        <div class="instructions-slider">
          <slick class="instructions-slider-for" :options="slickOptionsFor">
            <div class="i-slide-for">
              <iframe width="100%" height="100%" 
                src="https://www.youtube.com/embed/3wJDiw-Sg-Y" 
                frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
              </iframe>
            </div>
            <div class="i-slide-for" v-for="(item, idx) in tasks.data" :key="idx">
              <iframe width="100%" height="100%" 
                :src="item.video_link" 
                frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
              </iframe>
            </div>
          </slick>
          <slick class="instructions-slider-nav" :options="slickOptionsNav">
            <div class="i-slide-nav">
              <img src="https://img.youtube.com/vi/3wJDiw-Sg-Y/mqdefault.jpg">
            </div>
            <div class="i-slide-nav" v-for="(item, idx) in tasks.data" :key="idx">
              <img :src="`https://img.youtube.com/vi/${ getSrc(item.video_link) }/mqdefault.jpg`">
            </div>
          </slick>
        </div>
        <div class="instruction-top-btns">
          <div class="row">
            <div class="col">
              <router-link class="instruction-top-btn" to="/catalog/4279">Обменять бонусы</router-link>
            </div>
            <div class="col">
              <router-link class="instruction-top-btn to-task-btn" to="/tasks">Перейти к заданиям</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="instructions-content">
        <div class="in-container">
          <h2>Инструкции</h2>
          <div class="row in-one in-block left">
            <div class="line line-one"></div>
            <div class="col col-left">
              <div class="left-block">
                <h3>Выполняй задания и копи баллы</h3>
                <p>Чтобы просмотреть доступные задания, <span class="in-one-break"></span> перейди в раздел <em>“Задания”</em> в шапке сайта.</p>
              </div>
            </div>
            <div class="col col-right">
              <div class="bg-img"></div>
            </div>
          </div>

          <div class="row in-two in-block right">
            <div class="line line-two"></div>
            <div class="col col-left">
              <div class="left-block">
                <p>В разделе представлены задания <span class="in-two-break"></span> от стратегических партнеров и управляющей компании FIT Service.</p>
                <p>Задания есть активные и завершенные.</p>
              </div>
            </div>
            <div class="col col-right">
              <div class="bg-img"><img src="../assets/images/instructions/cards.svg" alt="img"></div>
            </div>
          </div>

          <div class="row in-three in-block left">
            <div class="line line-three"></div>
            <div class="col col-left">
              <div class="left-block">
                <p>К каждому заданию есть подобная информация: <em>описание, приказ, видео, условия, по которым начисляются баллы  и размер вознаграждения</em></p>
                <p>Например, в задании от febi bilstein за каждые потраченные на детали подвески бренда febi клиентом 2000 рублей будет начислено 100 баллов.</p>
                <p>Сейчас баллы начисляются один раз в неделю, но в скором времени баланс будет пополняться сразу после закрытия заказ-наряда.</p>
              </div>
            </div>
            <div class="col col-right">
              <div class="bg-img"><img src="../assets/images/instructions/cards-open.svg" alt="img"></div>
            </div>
          </div>

          <div class="row in-four in-block right">
            <div class="line line-four"></div>
            <div class="col col-left">
              <div class="left-block">
                <p>Выполняя актуальные задания, ты зарабатываешь баллы, которые  можешь обменять на товары.</p>
              </div>
            </div>
            <div class="col col-right">
              <div class="bg-img">
                <div class="four-img-group">
                  <img src="../assets/images/instructions/step4t.png" alt="img">
                </div>
              </div>
            </div>
          </div>

          <div class="row in-five in-block left">
            <div class="line line-five"></div>
            <div class="col col-left">
              <div class="left-block">
                <h3>Обменивай баллы на товары</h3>
                <p>Заходи в каталог товаров.</p>
              </div>
            </div>
            <div class="col col-right">
              <div class="bg-img"><img src="../assets/images/instructions/laptop.svg" alt="img"></div>
            </div>
          </div>

          <div class="row in-six in-block right">
            <div class="line line-six"></div>
            <div class="col col-left">
              <div class="left-block">
                <p>Текущий баланс отображается в шапке сайта, в правом верхнем углу.</p>
              </div>
            </div>
            <div class="col col-right">
              <div class="bg-img">
                <img src="../assets/images/instructions/six-bg.svg" alt="img">
              </div>
            </div>
          </div>

          <div class="row in-seven in-block left">
            <div class="line line-seven"></div>
            <div class="col col-left">
              <div class="left-block">
                <p>Чтобы просмотреть доступные для приобретения товары, перейди в раздел “Каталог”. 
                В каталоге представлены различные товары: бытовая и цифровая техника, аксессуары, сувенирная продукция, товары для детей.
                </p>
                <p>Все товары имеют подробное описание, фотографии и стоимость в баллах.</p>
              </div>
            </div>
            <div class="col col-right">
              <div class="bg-img"><img src="../assets/images/instructions/mobile-product-card.svg" alt="img"></div>
            </div>
          </div>

          <div class="row in-eight in-block right">
            <div class="line line-eight"></div>
            <div class="col col-left">
              <div class="left-block">
                <p>Выбери понравившийся товар и нажми значок корзины. Товар окажется в твоей корзине, 
                в которую можно попасть через специальную кнопку справа вверху.</p>
                <p>Чтобы приобрести товар, нужно нажать на кнопку <em>“Оформить заказ”</em>.</p>
              </div>
            </div>
            <div class="col col-right">
              <div class="bg-img">
                <img src="../assets/images/instructions/basket.svg" alt="img">
              </div>
            </div>
          </div>

          <div class="row in-nine in-block left">
            <div class="line line-nine"></div>
            <div class="col col-left">
              <div class="left-block">
                <p>Заполни профиль на сайте, указав все необходимые регистрационные данные
                Это необходимо для доставки заказа.</p>
              </div>
            </div>
            <div class="col col-right">
              <div class="bg-img"><img src="../assets/images/instructions/forms.svg" alt="img"></div>
            </div>
          </div>

          <div class="row in-ten in-block right">
            <div class="col col-left">
              <div class="left-block">
                <p>После оформления, заказ будет доставлен на станцию. Доставка производится бесплатно.</p>
              </div>
            </div>
            <div class="col col-right">
              <div class="bg-img">
                <img src="../assets/images/instructions/delivery.svg" alt="img">
              </div>
            </div>
          </div>
        </div>
        <div class="instructions-bot">
          <p class="instructions-bot__info">Если во время оформления заказа что-то пошло не так, или у тебя есть дополнительные вопросы, то свяжись с менеджером проекта. <br> <a href="tel:+79059465715">+7 905 946 57 15</a></p>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
  import Slick from 'vue-slick';
  import 'slick-carousel/slick/slick.css';
  import Tasks from '../shared/mockData/tasks'
  
  export default {
    data() {
      return {
        tasks: Tasks
      }
    },

    computed: {
      slickOptionsFor() {
        return {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          adaptiveHeight: true,
          autoplay: false,
          asNavFor: '.instructions-slider-nav',
        }
      },

      slickOptionsNav() {
        return {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          autoplay: false,
          speed: 200,
          infinite: true,
          cssEase: "linear",
          focusOnSelect: true,
          asNavFor: '.instructions-slider-for',
          adaptiveHeight: true,
          responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: false
            }
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true
            }
          }
        ]}
      }
    },

    methods: {
      back() {
        this.$router.go(-1)
      },
      getSrc(src) {
        return src.split('/').pop()
      }
    },

    components: {
      Slick
    }
  }
</script>

<style lang="scss" scoped>
  a {
    color: #FF9C43;
  }
  .instructions-main {
    position: relative;
    background: #1A1C1C;
    z-index: 1;
    min-height: calc(100vh - 184px);
  }

  .instructions-heading {
    font: 200 22px/26px $roboto;
    color: #FFFEFA;
    padding-top: 38px;
    text-align: center;
  }

  .instructions-slider {
    max-width: 780px;
    margin: 40px auto 0;
    @include display-less(smart) { 
      margin: 20px auto 0;
    }
  }

  .instructions-slider-nav {
    margin-top: 40px;
    img {
      display: block;
      width: 100%;
      min-height: 123px;
    }
  }

  .i-slide-for {
    height: 386px;
    @include display-less(smart) { 
      height: 286px;
    }
  }

  .i-slide-nav {
    height: 123px;
    overflow: hidden;
    @include display-less(smart) { 
      height: 90px;
    }
  }

  .instruction-top-btns {
    max-width: 690px;
    margin: 0 auto;
    align-items: center;

    .col {
      width: 50%;
      @include display-less(smart) { 
        width: 100%;
      }
    }
  }

  .instruction-top-btn {
    margin: 0 15px;
    display: block;
    width: 100%;
    max-width: 320px;
    height: 50px;
    background: transparent;
    border: 1px solid #FF9C43;
    border-radius: 4px;
    font: 700 18px/48px $roboto;
    color: #f9f9f9;
    text-align: center;
    margin: 80px auto 0;
    cursor: pointer;
    padding: 0;

    &:hover {
      background: #FF9C43;
      color: #1A1C1C;
    }
  }

  .to-task-btn {
     @include display-less(smart) { 
      margin-top: 20px;
    }
  }

  .instructions-content {
    overflow: hidden;
    padding-bottom: 70px;
  }

  .slider-container{
    width: 100%;
    max-width:1060px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @include display-less(phablet) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include display-less(smart) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .in-container {
    max-width: 1270px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @include display-less(phablet) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include display-less(smart) {
      padding-left: 15px;
      padding-right: 15px;
    }

    h2 {
      color: #FF9C43;
      font: 500 48px/56px $roboto;
      padding-top: 100px;
      @include display-less(desktop) {
        font: 500 42px/49px $roboto;
      }
    }

    h3 {
      font: 500 32px/37px $roboto;
      color: #FF9C43;
      @include display-less(desktop) {
        font: 500 24px/31px $roboto;
      }
    }

    p {
      font: 400 22px/32px $roboto;
      color: #9C9C9C;
      margin-top: 18px;
      @include display-less(desktop) {
        font: 500 18px/28px $roboto;
      }

      em {
        color: #FF9C43;
      }
    }
  }

  .in-block {
    position: relative;
  }

  .col-left, .col-right {
    position: relative;
  }

  .left {
    padding-top: 110px;
    @include display-less(phablet) {
      flex-direction: column;
    }

    .col-left {
      width: 60%;
      z-index: 10;
      height: 258px;
      @include display-less(phablet) {
        width: 100%;
        height: auto;
      }
    }

    .col-right {
      width: 40%;
      z-index: 5;
      @include display-less(phablet) {
        width: 100%;
        margin-top: 50px;
      }

    }
  }

  .right {
    @include display-less(phablet) {
      flex-direction: column;
    }

    .col-left {
      width: 60%;
      z-index: 10;
      @include display-less(phablet) {
        width: 100%;
      }
    }

    .col-right {
      width: 40%;
      z-index: 5;
      @include display-less(phablet) {
        width: 100%;
        margin-top: 50px;
      }
    }
  }

  .left {
    .col-left {
      order: 1;
    }

    .col-right {
      order: 2;
    }
  }

  .right {
    
    .col-left {
      order: 2;
      @include display-less(phablet) {
       order: 1;
      }
    }

    .col-right {
      @include display-less(phablet) {
       order: 2;
      }
    }
  }

  .left .col-left {
    .left-block {
      padding-left: 200px;
      position: relative;
      @include display-less(desktop) {
        padding-left: 60px;
      }
    }
  }

  .right .col-left {
    .left-block {
      padding-right: 200px;
      position: relative;
      @include display-less(desktop) {
        padding-right: 60px;
      }
    }
  }

  .in-four .col-left p{
    padding-left: 100px;
    padding-right: 50px;
    @include display-less(desktop) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .in-six .col-left p{
    padding-right: 80px;
    @include display-less(desktop) {
      padding-right: 0
    }
  }

  .in-ten .col-left p{
    padding-top: 60px;
  }

  .in-one-break {
    display: block;
  }
  .in-two-break {
    display: block;
  }

  .in-one .col-left .in-number {
    top: -80px;
  }

  .in-one .col-right .bg-img {
    display: block;
    position: absolute;
    width: 653px;
    height: 68px;
    background-image: url('../assets/images/instructions/one-bg.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    top: 135px;
    left: -150px;
    @include display-less(phablet) {
      top: 0;
      left: auto;
    }
  }

  .in-two .col-right .bg-img {
    img {
      display: block;
      left: 100px;
      position: relative;
      top: -100px;
      @include display-less(phablet) {
        left: auto;
        top: 0;
      }
    }
  }

  .in-three .col-right .bg-img {
    img {
      display: block;
      margin-right: 100px;
      position: relative;
      top: -80px;
      @include display-less(desktop) {
       margin-right: 0px;
       right: 50px;
      }
      @include display-less(phablet) {
        left: auto;
        top: -40px;
        right: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .in-four .col-right .bg-img {
    img {
      display: block;
      left: 100px;
      position: relative;
      top: 40px;
      @include display-less(desktop) {
        left: 0;
        top: 25px;
        @include display-less(phablet) {
          top: 0;
        }
      }
    }
  }

  .in-five .col-right .bg-img {
    img {
      position: relative;
      left: -80px;
      top: -80px;
      @include display-less(desktop) {
        width: 375px;
        height: auto;
        left: auto;
        right: 27px;
        top: -57px;
      }
      @include display-less(phablet) {
        right: auto;
        top: 0;
      }
    }
  }

  .in-six .col-right .bg-img {
    img {
      position: relative;
      left: 80px;
      @include display-less(desktop) {
        left: 0;
      }
    }
  }

  .in-seven .col-right .bg-img {
    img {
      position: relative;
      top: -10px;
      @include display-less(desktop) {
        top: -50px;
        right: 50px;
      }
      @include display-less(phablet) {
        right: auto;
      }
    }
  }

  .in-eight .col-right .bg-img {
    img {
      position: relative;
      left: 40px;
      top: -20px;
      @include display-less(desktop) {
        width: 170px;
        height: 170px;
        top: 8px;
      }
      @include display-less(phablet) {
        right: auto;
        left: auto;
      }
    }
  }

  .in-nine .col-right .bg-img {
    img {
      position: relative;
      left: 40px;
      top: -40px;
      @include display-less(desktop) {
        left: -40px;
      }
      @include display-less(phablet) {
        right: auto;
        left: auto;
        top: 20px;
      }
    }
  }

  .in-ten .col-right .bg-img {
    img {
      position: relative;
      left: 40px;
      top: -20px;
      @include display-less(desktop) {
        width: 60%;
        top: 20px;
      }
      @include display-less(phablet) {
        width: 250px;
        height: 150px;
        right: auto;
        left: auto;
        top: 60px;
      }
    }
  }

  .bg-img {
    @include display-less(phablet) {
      display: flex;
      justify-content: center;
    }
  }

  .in-one {
    @include display-less(desktop) {
      margin-top: 60px;
    }
  }

  .in-two {
    margin-top: 320px;
    @include display-less(phablet) {
      margin-top: 280px;
    }
  }

  .in-three {
    margin-top: 30px;
  }

  .in-four {
    margin-top: 150px;
  }

  .in-five {
    margin-top: 250px;
    @include display-less(phablet) {
      margin-top: 140px;
    }
  }

  .in-six {
    margin-top: 150px;
  }

  .in-seven {
    margin-top: 220px;
    @include display-less(phablet) {
      margin-top: 180px;
    }
  }

  .in-eight {
    margin-top: 160px;
  }

  .in-nine {
    margin-top: 120px;
  }

  .in-ten {
    margin-top: 150px;
    @include display-less(phablet) {
      margin-top: 200px;
    }
  }

  .in-one.left .left-block {
    z-index: 10;
    &:after {
      content: '1';
      font: 900 400px/1 $roboto;
      color: #312920;
      display: block;
      position: absolute;
      left: 0;
      top: -100px;
      z-index: -1
    }
    @include display-less(desktop) {
      &:after {
        font: 900 288px/1 $roboto;
        top: -123px;
      }
    }
  }

  .in-two.right .left-block {
     z-index: 10;
    &:after {
      content: '2';
      font: 900 400px/1 $roboto;
      color: #312920;
      display: block;
      position: absolute;
      right: 173px;
      top: -136px;
      z-index: -1;
    }
    @include display-less(desktop) {
      &:after {
        font: 900 288px/1 $roboto;
        right: 0;
        top: -94px;
      }
    }
  }

  .in-three.left .left-block {
    position: relative;
    z-index: 10;
    padding-right: 40px;
    &:after {
      content: '3';
      font: 900 400px/1 $roboto;
      color: #312920;
      display: block;
      position: absolute;
      left: 0;
      top: -40px;
      z-index: -1
    }
    @include display-less(desktop) {
      &:after {
        font: 900 288px/1 $roboto;
        top: -123px;
      }
    }
  }

  .in-four.right .left-block {
     z-index: 10;
     padding-right: 170px;
    &:after {
      content: '4';
      font: 900 400px/1 $roboto;
      color: #312920;
      display: block;
      position: absolute;
      right: 173px;
      top: -136px;
      z-index: -1;
    }
    @include display-less(desktop) {
      &:after {
        font: 900 288px/1 $roboto;
        top: -90px;
        right: 0;
      }
    }
  }

  .in-five.left .left-block {
    z-index: 10;
    position: relative;
    &:after {
      content: '5';
      font: 900 400px/1 $roboto;
      color: #312920;
      display: block;
      position: absolute;
      left: 40px;
      top: -113px;
      z-index: -1
    }
    @include display-less(desktop) {
      &:after {
        font: 900 288px/1 $roboto;
        top: -90px;
        right: 0;
      }
    }
  }

  .in-six.right .left-block {
     z-index: 10;
     padding-right: 170px;
    &:after {
      content: '6';
      font: 900 400px/1 $roboto;
      color: #312920;
      display: block;
      position: absolute;
      right: 173px;
      top: -165px;
      z-index: -1;
    }
    @include display-less(desktop) {
      &:after {
        font: 900 288px/1 $roboto;
        top: -123px;
        right: 0;
      }
    }
  }

  .in-seven.left .left-block {
    z-index: 10;
    position: relative;
    &:after {
      content: '7';
      font: 900 400px/1 $roboto;
      color: #312920;
      display: block;
      position: absolute;
      left: 40px;
      top: -78px;
      z-index: -1
    }
    @include display-less(desktop) {
      &:after {
        font: 900 288px/1 $roboto;
        top: -90px;
        right: 0;
      }
    }
  }

  .in-eight.right .left-block {
     z-index: 10;
     padding-right: 230px;
    &:after {
      content: '8';
      font: 900 400px/1 $roboto;
      color: #312920;
      display: block;
      position: absolute;
      right: 173px;
      top: -113px;
      z-index: -1;
    }
    @include display-less(desktop) {
      padding-right: 0px;
      &:after {
        font: 900 288px/1 $roboto;
        top: -90px;
        right: 0;
      }
    }
  }

  .in-nine.left .left-block {
    z-index: 10;
    position: relative;
    &:after {
      content: '9';
      font: 900 400px/1 $roboto;
      color: #312920;
      display: block;
      position: absolute;
      left: 40px;
      top: -135px;
      z-index: -1
    }
    @include display-less(desktop) {
      padding-right: 0px;
      &:after {
        font: 900 288px/1 $roboto;
        top: -110px;
        right: 0;
      }
    }
  }

  .in-ten.right .left-block {
     z-index: 10;
     padding-right: 230px;
    &:after {
      content: '10';
      font: 900 400px/1 $roboto;
      color: #312920;
      display: block;
      position: absolute;
      right: 50px;
      top: -113px;
      z-index: -1;
    }
    @include display-less(desktop) {
      padding-right: 0px;
      &:after {
        font: 900 288px/1 $roboto;
        top: -70px;
        right: 0;
      }
    }
  }

  .four-img-group {
    position: relative;
    @include display-less(desktop) {
    }
  }

  .instructions-bot {
    max-width: 1270px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    @include display-less(phablet) {
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 150px;
    }
    @include display-less(smart) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &__info {
      font: 400 16px/25px $roboto;
      color: #C4C4C4;
      max-width: 640px;
    }
  }

  .instructions-bot-contacts {
    margin-top: 30px;
    display: flex;
    align-items: center;

    &__img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      margin-right: 20px;
      img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 50%;
      }
    }
  }

  .instructions-bot-name {
    font: 500 20px/32px $roboto;
    color: #fff;
  }

  .instructions-bot-tel {
    font: 300 16px/20px $roboto;
    color: #C4C4C4;
    margin-top: 10px;
  }

  .line {
    display: block;
    position: absolute;
    z-index: 10;
    background-position: center;
    background-repeat: no-repeat;
  }

  .line-one {
    width: 574px;
    height: 413px;
    bottom: -83%;
    left: 19%;
    background-image: url('../assets/images/instructions/line1-lg.svg');
    background-size: contain;
    @include display-less(big) {
      background-size: 60%
    }
    @include display-less(phablet) {
      background-size: 30%;
      left: -40px;
      top: 83%;
    }
  }

  .line-two {
    width: 378px;
    height: 298px;
    bottom: -34%;
    left: 25%;
    background-image: url('../assets/images/instructions/line2-lg.svg');
    background-size: contain;
    @include display-less(big) {
      background-size: 60%
    }
    @include display-less(phablet) {
      background-size: 40%;
      left: 10%;
    }
  }

  .line-three {
    width: 360px;
    height: 294px;
    bottom: -20%;
    left: 31%;
    background-image: url('../assets/images/instructions/line3-lg.svg');
    background-size: contain;
    @include display-less(big) {
      background-size: 60%
    }
    @include display-less(phablet) {
      background-size: 40%;
      bottom: -14%;
      left: 11%;
    }
  }

  .line-four {
    width: 426px;
    height: 318px;
    bottom: -260%;
    left: 28%;
    background-image: url('../assets/images/instructions/line4-lg.svg');
    background-size: contain;
    @include display-less(big) {
      background-size: 60%
    }
    @include display-less(phablet) {
      background-size: 40%;
      bottom: -100%;
      left: 0%;
    }
  }

  .line-five {
    width: 384px;
    height: 454px;
    bottom: -33%;
    left: 26%;
    background-image: url('../assets/images/instructions/line5-lg.svg');
    background-size: 500px 500px;
    @include display-less(big) {
      background-size: 60%
    }
    @include display-less(phablet) {
      background-size: 40%;
      bottom: -40%;
      left: 0%;
    }
  }

  .line-six {
    width: 300px;
    height: 308px;
    bottom: -335%;
    left: 28%;
    background-image: url('../assets/images/instructions/line6-lg.svg');
    background-size: contain;
    @include display-less(big) {
      background-size: 60%
    }
    @include display-less(phablet) {
      background-size: 50%;
      bottom: -116%;
      left: 12%
    }
  }

  .line-seven {
    width: 364px;
    height: 294px;
    bottom: -47%;
    left: 28%;
    background-image: url('../assets/images/instructions/line7-lg.svg');
    background-size: contain;
    @include display-less(big) {
      background-size: 60%
    }
    @include display-less(phablet) {
      background-size: 50%;
      bottom: -21%;
      left: 12%
    }
  }

  .line-eight {
    width: 378px;
    height: 264px;
    bottom: -79%;
    left: 26%;
    background-image: url('../assets/images/instructions/line8-lg.svg');
    background-size: contain;
    @include display-less(big) {
      background-size: 60%
    }
    @include display-less(desktop) {
     bottom: -130%;
    }
    @include display-less(phablet) {
      background-size: 40%;
      bottom: -47%;
      left: -2%
    }
  }

  .line-nine {
    width: 270px;
    height: 364px;
    bottom: -62%;
    left: 25%;
    background-image: url('../assets/images/instructions/line9-lg.svg');
    background-size: contain;
    @include display-less(big) {
      background-size: 60%
    }
    @include display-less(phablet) {
      background-size: 40%;
      bottom: -57%;
      left: 18%
    }
  }

  .back {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    right: 15px;
    top: 15px;
    background-image: url('../assets/images/icons/close-menu.svg');
    cursor: pointer;
  }

  .slick-initialized {
    overflow: hidden;
    padding: 0 50px;
    @include display-less(tablet) {
      padding: 0;
    }
  }
</style>
