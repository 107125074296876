<template>
  <div class="wrapper">
    <article class="orders-top-row">
      <div class="datepicker">
        <div class="datepicker__info">c:</div>
        <div class="datepicker__calendar">
          <datepicker :language="ru" :format="customFormatter" input-class="dateInput" wrapper-class="dateInputWrap"
            v-model="filters.startDate" @selected="dateStartChangeFn" />
        </div>
      </div>

      <div class="datepicker">
        <div class="datepicker__info">по:</div>
        <div class="datepicker__calendar">
          <datepicker :language="ru" :format="customFormatter" input-class="dateInput" wrapper-class="dateInputWrap"
            v-model="filters.endDate" @selected="dateEndChangeFn" />
        </div>
      </div>

      <button @click="downloadRegistry" class="orders-registry">
        Выгрузить реестр для поставщика
      </button>

      <button @click="downloadAccounting" class="orders-registry">
        Выгрузить реестр для бухгалтерии
      </button>
    </article>

    <div class="filter-wrapper">
       <div class="input-wrap">
      <div class="search-container">
        <label>
          <input type="text" placeholder=" " v-model="recipient" @keyup="debouncedSearchRecipient"
            @keyup.enter="searchByRecipient">
          <span>Имя получателя</span>
        </label>
      </div>
    </div>

    <div class="input-wrap">
      <div class="search-container">
        <label>
          <input type="text" placeholder=" " v-model="shipper" @keyup="debouncedSearchShipper"
            @keyup.enter="searchByShipper">
          <span>Поставщик</span>
        </label>
      </div>
    </div>

    <div class="search-container" style="width: 100%;">
      <label>
        <span class="status-label">Статус</span>
        <v-select  @input="statusChangeFn"  style="width: 20%; margin-top: 10px;" class="admin-select" :options="options"
          label="name" v-model="status" />
      </label>
    </div>
    </div>
   

  </div>
</template>


<script>
import Datepicker from "vuejs-datepicker";
import { ru } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import { filterOptions } from "../utils";
import { debounce } from "../../../../utils";

export default {
  components: {
    Datepicker
  },

  data() {
    return {
      ru: ru,
      recipient: null,
      status: filterOptions[0],
      shipper: null,
      options: filterOptions,

      debouncedSearchRecipient: null,
      debouncedSearchShipper: null,
    };
  },

  props: {
    filters: Object,
    customFormatter: {
      type: Function,
      required: true
    },
  },

  created() {
    this.debouncedSearchRecipient = debounce(this.searchByRecipient, 500);
    this.debouncedSearchShipper = debounce(this.searchByShipper, 500);
  },

  methods: {
    dateStartChangeFn(date) {
      this.$emit('update-filter', 'startDate', date);
    },

    dateEndChangeFn(date) {
      this.$emit('update-filter', 'endDate', date);
    },

    statusChangeFn(value) {
      this.$emit('update-filter', 'status', value.status);
    },

    searchByRecipient() {
      this.$emit('update-filter', 'recipient', this.recipient);
    },

    searchByShipper() {
      this.$emit('update-filter', 'shipper', this.shipper);
    },

    downloadAccounting() {
      this.$store.dispatch("getAccountingAct", {
        from: moment(this.filters.startDate, "DD.MM.YYYY HH:mm").format("x"),
        to: moment(this.filters.endDate, "DD.MM.YYYY HH:mm").format("x"),
      });
    },

    downloadRegistry() {
      let start = moment(this.filters.startDate).format("DD.MM.YYYY") + " 00:00";
      let end = moment(this.filters.endDate).format("DD.MM.YYYY") + " 23:59";

      this.$store.dispatch("getRegistry", {
        from: moment(start, "DD.MM.YYYY HH:mm").format("x"),
        to: moment(end, "DD.MM.YYYY HH:mm").format("x"),
      });
    },
  }
};
</script>


<style lang="scss" scoped>
.wrapper {
  margin-bottom: 50px;
}
.filter-wrapper {
  display: inline-flex;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}
.status-label {
  opacity: 0.5;
  margin-left: 8px;
  font: 400 16px/10px $roboto;
}

.search-container {
  margin-top: 25px;
}

.input-wrap {
  input {
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    color: #9e9e9e;
    font: 400 16px/30px $roboto;
    padding: 0 5px;
    color: $dark-font;

    &:placeholder {
      color: #9e9e9e;
    }

    &:focus {
      border-bottom: 1px solid blue;
    }
  }

  label {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  span {
    padding: 5px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.5;
    color: $dark-font;
    font: 400 16px $roboto;
  }

  input:focus+span,
  input:not(:placeholder-shown)+span {
    opacity: 1;
    transform: translateY(-100%) translateX(-10px);
    font-size: 13px
  }

  input:focus+span,
  input:not(:-ms-input-placeholder)+span {
    opacity: 1;
    transform: translateY(-100%) translateX(-10px);
    font-size: 13px;
  }
}

.orders-registry {
  display: block;
  background: $admin-blue;
  font: 400 16px/40px $roboto;
  color: $white;
  padding: 0 15px;
  border: none;
  cursor: pointer;

  &:hover {
    background: darken($admin-blue, 10%);
  }
}

.datepicker {
  display: flex;
  align-items: center;

  &__info {
    font: 400 16px $roboto;
    color: $dark-font;
    margin-right: 10px;
  }

  &__calendar {
    border-bottom: 1px solid $dark-font;
    width: 150px;
  }
}

.orders-top-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 32px;
}
</style>