<template>
    <div class="wrapper">
        <h1 class="heading">Просмотр</h1>

        <div class="col">
            <div class="row">Имя: <span class="item">{{ selectedRow.user.full_name }}</span></div>
            <div class="row">Статус: <span class="item">{{ statusFormatter(selectedRow.status) }}</span></div>
            <div class="row">Комментарий: <span class="item comment">{{ selectedRow.comment || '-' }}</span></div>
            <div class="row">Создано: <span class="item">{{ dateFormatter(selectedRow.createdAt) }}</span></div>
            <div class="row">Обновлено: <span class="item">{{ dateFormatter(selectedRow.updatedAt) }}</span></div>
        </div>

    </div>
</template>

<script>
import { statusFormatterFn } from '../utils';
import moment from 'moment';

export default {
    props: {
        selectedRow: {
            type: Object,
            default: null,
            required: true
        }
    },
    methods: {
        statusFormatter(status) {
            return statusFormatterFn(status);
        },
        dateFormatter(date) {
            return moment(date).format("DD.MM.YYYY");
        },
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    margin-top: 70px;
}

.heading {
    margin-top: 30px;
    margin-bottom: 10px;
    font: 500 30px/1.5 $roboto;
    color: $dark-font;
}

.item {
    margin-bottom: 20px;
    font: 400 18px/1.5 $roboto;
}

.row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    font: 400 15px/1.5 $roboto;
}

.col {
    margin-top: 10px;
}

.comment {
    max-width: 250px;
}
</style>